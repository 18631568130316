// src/ThemeContext.js
import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      try {
        const parsedTheme = JSON.parse(savedTheme); // Parse the saved theme (should be "true" or "false")
        return parsedTheme === true; // Ensure it’s a boolean true for dark mode
      } catch (error) {
        console.error('Error parsing theme from localStorage:', error);
        // Handle legacy values
        if (savedTheme === 'light') return false;
        if (savedTheme === 'dark') return true;
        return true; // Default to dark mode if parsing fails or legacy value is invalid
      }
    }
    return true; // Default to dark mode if no saved theme
  });

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(isDarkMode)); // Store as "true" or "false"
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};