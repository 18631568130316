import React, { useState, useEffect } from 'react';
import '../styles/PrivateSaleDRG.css';
import { web, TransactionType } from '@klever/sdk-web';

function PreSale() {
  const presaleData = {
    id: 1,
    name: 'DRAGON',
    ticker: 'DRG-17KE',
    logo: 'https://i.ibb.co/1ZWb4kX/dragon.png',
    targetAmount: 60000000, // 600,000 DRG
    currentAmount: 0,
    price: 0.05,
    address: 'klv1scc9g9zkaqpcqyd36t9x79epdyeqwerqhhf6qsykeza96y99uwcsq303w6',
    precision: 6,
    startTime: '2024-10-27T19:00:00Z',
    endTime: '2025-05-31T12:00:00Z'
  };

  const totalPresaleTokens = presaleData.targetAmount;
  const presaleGoalKLV = totalPresaleTokens * presaleData.price;

  const [balance, setBalance] = useState('0');
  const [ktcBalance, setKtcBalance] = useState(totalPresaleTokens.toString());
  const [amount, setAmount] = useState('');
  const [tokensToReceive, setTokensToReceive] = useState('0');
  const [walletAddress, setWalletAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionStatusMessage, setTransactionStatusMessage] = useState('');
  const isValidAmount = parseFloat(amount) >= 100 && parseFloat(amount) <= 250000;
  const numericKtcBalance = parseFloat(ktcBalance);
  const numericTokensToReceive = parseFloat(tokensToReceive);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [transactionResultMessage, setTransactionResultMessage] = useState('');
  const [countdownTime, setCountdownTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [isCountdownFinished, setIsCountdownFinished] = useState(false);
  const [isPresaleEnded, setIsPresaleEnded] = useState(false);
  const [contributors, setContributors] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [showDescription, setShowDescription] = useState(false);
  const [showContributors, setShowContributors] = useState(true); // Default to true on desktop

  const fetchKtcBalance = async () => {
    const url = `https://api.mainnet.klever.finance/v1.0/address/${presaleData.address}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const ktcData = data.data.account.assets['DRG-17KE'];
      if (ktcData) {
        const rawBalance = ktcData.balance;
        const ktcBalance = (rawBalance / Math.pow(10, presaleData.precision)).toFixed(0);
        setKtcBalance(ktcBalance);
      } else {
        setKtcBalance(totalPresaleTokens.toString());
      }
    } catch (error) {
      console.error('Error fetching DRG-17KE balance:', error);
      setKtcBalance(totalPresaleTokens.toString());
    }
  };

  const fetchKlvBalance = async () => {
    if (!walletAddress) return;
    const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const klvData = data.data.account.assets['KLV'];
      if (klvData) setBalance(adjustBalance(klvData.balance, klvData.precision));
    } catch (error) {
      console.error('Error fetching KLV balance:', error);
    }
  };

  const fetchContributors = async () => {
    let allTransactions = [];
    let currentPage = 1;
    let totalPages = 1;
    try {
      do {
        const url = `https://api.mainnet.klever.finance/v1.0/transaction/list?limit=100&page=${currentPage}&status=success&toAddress=${presaleData.address}`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.data && data.data.transactions) allTransactions = allTransactions.concat(data.data.transactions);
        totalPages = data.pagination.totalPages;
        currentPage++;
      } while (currentPage <= totalPages);
      setContributors(allTransactions);
      const raised = allTransactions.reduce((acc, tx) => {
        const receipt = tx.receipts.find((r) => r.assetId === "KLV");
        return receipt ? acc + Number(receipt.value) : acc;
      }, 0);
      const raisedKLVNum = raised / Math.pow(10, 6);
      const tokensSold = raisedKLVNum / presaleData.price;
      const percentage = Math.max(0, Math.min(100, (tokensSold / totalPresaleTokens) * 100));
      setProgressPercentage(percentage);
    } catch (error) {
      console.error('Error fetching contributors:', error);
      setProgressPercentage(0);
    }
  };

  useEffect(() => {
    fetchKtcBalance();
    if (walletAddress) fetchKlvBalance();
    const interval = setInterval(() => {
      fetchKtcBalance();
      if (walletAddress) fetchKlvBalance();
    }, 2000);
    return () => clearInterval(interval);
  }, [walletAddress]);

  useEffect(() => {
    fetchContributors();
    const interval = setInterval(fetchContributors, 10000);
    return () => clearInterval(interval);
  }, []);

  const raisedAmount = contributors.reduce((acc, tx) => {
    const receipt = tx.receipts.find((r) => r.assetId === "KLV");
    return receipt ? acc + Number(receipt.value) : acc;
  }, 0);
  const raisedKLV = (raisedAmount / Math.pow(10, 6)).toLocaleString();

  const adjustBalance = (balance, precision) => (balance / Math.pow(10, precision || 8)).toFixed(precision || 8);
  const shortenAddress = (address) => address ? `${address.substring(0, 6)}...${address.substring(address.length - 6)}` : '';

  const handlePercentageClick = (percentage) => {
    let calculatedAmount = percentage === '100' ? (balance * percentage / 100) - 5 : (balance * percentage / 100);
    calculatedAmount = Math.max(calculatedAmount, 0).toFixed(6);
    setAmount(calculatedAmount);
    calculateTokensToReceive(calculatedAmount);
    setShowInstallButton(false);
  };

  const formatNumber = (value, options = {}) => new Intl.NumberFormat(navigator.language, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, ...options }).format(value);

  const calculateTokensToReceive = (inputAmount) => {
    const amt = parseFloat(inputAmount) || 0;
    const tokens = amt / presaleData.price;
    setTokensToReceive(isNaN(tokens) ? '0' : tokens.toFixed(6));
  };

  const handleAmountChange = (e) => {
    const inputAmount = parseFloat(e.target.value);
    const transactionFee = 5;
    const actualBalance = parseFloat(balance);
    if (isNaN(inputAmount)) {
      setAmount('');
      calculateTokensToReceive('0');
    } else {
      setAmount(inputAmount + transactionFee >= actualBalance ? Math.max(actualBalance - transactionFee, 0).toString() : inputAmount.toString());
      calculateTokensToReceive(inputAmount.toString());
    }
    setShowInstallButton(false);
  };

  const enforceConstraints = () => {
    let newAmount = parseFloat(amount);
    newAmount = isNaN(newAmount) ? '' : newAmount.toString();
    setAmount(newAmount);
    calculateTokensToReceive(newAmount);
  };

  const connectWallet = async () => {
    try {
      if (!window.kleverWeb) throw new Error("Klever Extension not found");
      await web.initialize();
      const address = await web.getWalletAddress();
      setWalletAddress(address);
      setIsConnected(true);
      setTransactionStatusMessage('');
    } catch (error) {
      console.error('Error connecting wallet:', error);
      setTransactionStatusMessage(error.message === "Klever Extension not found" ? 'Klever Extension not found.' : 'Error connecting wallet.');
      setShowInstallButton(error.message === "Klever Extension not found");
    }
  };

  useEffect(() => {
    const startDate = new Date(presaleData.startTime).getTime();
    const endDate = new Date(presaleData.endTime).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const startDistance = startDate - now;
      const endDistance = endDate - now;

      if (endDistance < 0) {
        clearInterval(interval);
        setIsPresaleEnded(true);
        setIsCountdownFinished(true);
      } else if (startDistance < 0) {
        setIsCountdownFinished(true);
        setCountdownTime({
          days: Math.floor(endDistance / (1000 * 60 * 60 * 24)),
          hours: Math.floor((endDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((endDistance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((endDistance % (1000 * 60)) / 1000),
        });
      } else {
        setCountdownTime({
          days: Math.floor(startDistance / (1000 * 60 * 60 * 24)),
          hours: Math.floor((startDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((startDistance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((startDistance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTransactionStatusMessage(
      transactionResultMessage ? transactionResultMessage :
      isLoading ? 'Processing...' :
      !isConnected ? 'Please connect your wallet.' :
      isPresaleEnded ? 'Presale has ended.' :
      !isCountdownFinished ? 'Presale starts soon.' :
      amount === '' ? 'Enter amount of KLV.' :
      !isValidAmount ? 'Min: 100 KLV, Max: 250k KLV' :
      numericTokensToReceive > numericKtcBalance ? 'Not enough DRG for sale.' :
      parseFloat(amount) > parseFloat(balance) ? 'Insufficient KLV balance.' :
      'Ready to buy'
    );
  }, [isLoading, isConnected, amount, isValidAmount, numericTokensToReceive, numericKtcBalance, balance, transactionResultMessage, isCountdownFinished, isPresaleEnded]);

  const sendTransaction = async () => {
    setIsLoading(true);
    const actualAmountToSend = Math.round(parseFloat(amount) * Math.pow(10, 6));
    const payload = { amount: actualAmountToSend, receiver: presaleData.address, kda: 'KLV' };
    try {
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }]);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setTransactionResultMessage('Transaction successful! Balance will update soon.');
      setTimeout(() => setTransactionResultMessage(''), 7000);
    } catch (error) {
      console.error('Transaction error:', error);
      setTransactionResultMessage('Transaction failed. Please try again.');
      setTimeout(() => setTransactionResultMessage(''), 7000);
    } finally {
      setIsLoading(false);
      setAmount('');
      setTokensToReceive('0');
    }
  };

  return (
    <div className="pre-sale-page">
      <div className="pre-sale-container">
        <button className="back-btn" onClick={() => window.location.href = 'https://rarecanvas.art/launchpad'}>←</button>
        <div className="pre-sale-header">
          <img src={presaleData.logo} alt="Dragon Token" className="pre-sale-logo" />
          <h1>{presaleData.name} Presale</h1>
          <p className="description">
            <span className="short-text">DRG: Fueling Klever ecosystem projects.</span>
            {showDescription && (
              <span className="full-text">DRG (Dragon Token) is a new token on the Klever Chain designed to support Kong and other Klever projects to enhance its ecosystem. Our mission is simple but powerful: to create value for the community while reducing circulating supply and supporting future projects.</span>
            )}
            <button className="toggle-description" onClick={() => setShowDescription(!showDescription)}>
              {showDescription ? 'Less' : 'More'}
            </button>
          </p>
          <div className="progress-bar">
            <div className="progress-fill" style={{ width: `${progressPercentage}%` }}></div>
            <span>{progressPercentage.toFixed(1)}% ({raisedKLV}/{formatNumber(presaleGoalKLV)} KLV)</span>
          </div>
          <div className="countdown">
            {isPresaleEnded ? 'ENDED' : isCountdownFinished ? `Ends: ${countdownTime.days}d ${countdownTime.hours}h ${countdownTime.minutes}m ${countdownTime.seconds}s` : `Starts: ${countdownTime.days}d ${countdownTime.hours}h ${countdownTime.minutes}m ${countdownTime.seconds}s`}
          </div>
          <div className="info-grid">
            <span>Price: {presaleData.price} KLV/DRG</span>
            <span>Raised: {raisedKLV} KLV</span>
          </div>
          <div className="social-links">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://t.me/DragonTokenDRG" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a>
            <a href="http://drgtoken.com" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a>
          </div>
        </div>

        <div className="pre-sale-form">
          <div className="input-group">
            <span className="balance-label">Balance: {formatNumber(balance)} KLV</span>
            <input
              type="number"
              placeholder="100 - 250k KLV"
              value={amount}
              onChange={handleAmountChange}
              onBlur={enforceConstraints}
              disabled={!isConnected || isPresaleEnded}
            />
            <span className="input-label">{formatNumber(tokensToReceive)} DRG</span>
          </div>
          <div className="percentage-buttons">
            {['10', '25', '50', '75', '100'].map((percent) => (
              <button key={percent} onClick={() => isConnected && handlePercentageClick(percent)} disabled={!isConnected || isPresaleEnded}>
                {percent}%
              </button>
            ))}
          </div>
          {!isConnected ? (
            <button className="connect-btn" onClick={connectWallet}>Connect Wallet</button>
          ) : (
            <button className="connect-btn connected">{shortenAddress(walletAddress)}</button>
          )}
          <button
            className="action-btn"
            onClick={sendTransaction}
            disabled={isLoading || !isConnected || !isValidAmount || numericTokensToReceive > numericKtcBalance || parseFloat(amount) > parseFloat(balance) || !isCountdownFinished || isPresaleEnded}
          >
            {isLoading ? 'Processing...' : 'Buy Now'}
          </button>
          <div className="status-message">
            {transactionResultMessage || transactionStatusMessage}
            {showInstallButton && (
              <button className="install-btn" onClick={() => window.open('https://chromewebstore.google.com/detail/klever-wallet/ifclboecfhkjbpmhgehodcjpciihhmif', '_blank')}>
                Install Extension
              </button>
            )}
          </div>
        </div>

        <div className="contributors-section">
          <button className="toggle-contributors" onClick={() => setShowContributors(!showContributors)}>
            Contributors ({contributors.length}) {showContributors ? 'Hide ▲' : 'Show ▼'}
          </button>
          {showContributors && (
            <div className="contributors-list">
              {contributors.length === 0 ? (
                <p>No contributions yet</p>
              ) : (
                contributors.slice(0, 5).map((tx) => {
                  const receipt = tx.receipts.find((r) => r.assetId === "KLV");
                  if (!receipt) return null;
                  const amountKLV = (receipt.value / Math.pow(10, 6)).toLocaleString();
                  return (
                    <div key={tx.hash} className="contributor-item">
                      <span>{shortenAddress(tx.sender)}</span>
                      <span>{amountKLV} KLV</span>
                    </div>
                  );
                })
              )}
              {contributors.length > 5 && <p>...and {contributors.length - 5} more</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreSale;