import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Added useNavigate
import { useWallet } from '../components/WalletContext';
import { web, TransactionType } from '@klever/sdk-web';
import Spinner from '../components/Spinner';
import '../styles/TokenDetailsPage.css';

const TokenDetailsPage = () => {
  const { assetId } = useParams();
  const { walletAddress, useKdaFee, selectedKda } = useWallet();
  const [tokenDetails, setTokenDetails] = useState(null);
  const [balance, setBalance] = useState('0');
  const [stakedAmount, setStakedAmount] = useState('0');
  const [rewards, setRewards] = useState([]); // Array for all rewards
  const [unfreezingAmount, setUnfreezingAmount] = useState('0');
  const [stakedEpoch, setStakedEpoch] = useState(null);
  const [currentEpoch, setCurrentEpoch] = useState(null);
  const [availableEpoch, setAvailableEpoch] = useState('100000000');
  const [minEpochsToClaim, setMinEpochsToClaim] = useState(0);
  const [minEpochsToUnstake, setMinEpochsToUnstake] = useState(0);
  const [minEpochsToWithdraw, setMinEpochsToWithdraw] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);
  const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false);
  const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [sendAmount, setSendAmount] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [stakeAmount, setStakeAmount] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  const adjustValue = (value, precision) => {
    const number = value / Math.pow(10, precision);
    return number.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 });
  };

  const getLatestAPR = (aprEntries) => {
    if (!aprEntries || aprEntries.length === 0) return null;
    const latest = aprEntries.reduce((prev, current) => (prev.timestamp > current.timestamp ? prev : current));
    return (latest.value / 100).toFixed(2);
  };

  const fetchTokenPrecision = async (assetId) => {
    try {
      for (let page = 1; page <= 2; page++) {
        const url = `https://api.mainnet.klever.finance/v1.0/assets/list?page=${page}&limit=100`;
        const response = await fetch(url);
        const data = await response.json();
        const token = data.data.assets.find(asset => asset.assetId === assetId);
        if (token) return token.precision;
      }
      return 6; // Default precision
    } catch (error) {
      console.error(`Error fetching precision for ${assetId}:`, error);
      return 6;
    }
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let token = null;
      for (let page = 1; page <= 2; page++) {
        const url = `https://api.mainnet.klever.finance/v1.0/assets/list?page=${page}&limit=100`;
        const response = await fetch(url);
        const data = await response.json();
        token = data.data.assets.find(asset => asset.assetId === assetId);
        if (token) break;
      }
      if (!token) throw new Error('Token not found');
      setTokenDetails(token);
      setMinEpochsToClaim(token.staking.minEpochsToClaim || 0);
      setMinEpochsToUnstake(token.staking.minEpochsToUnstake || 0);
      setMinEpochsToWithdraw(token.staking.minEpochsToWithdraw || 0);

      const balanceResponse = await fetch(`https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`);
      const balanceData = await balanceResponse.json();
      const assetData = balanceData.data.account.assets[assetId] || {};
      setBalance(adjustValue(assetData.balance || 0, token.precision));
      setStakedAmount(adjustValue(assetData.frozenBalance || 0, token.precision));
      if (assetData.buckets && assetData.buckets.length > 0) {
        setStakedEpoch(assetData.buckets[0].stakedEpoch);
      }

      const rewardsResponse = await fetch(`https://node.mainnet.klever.finance/address/${walletAddress}/allowance?asset=${assetId}`);
      const rewardsData = await rewardsResponse.json();
      const allRewards = rewardsData.data.allStakingRewards || {};
      const formattedRewards = await Promise.all(
        Object.entries(allRewards).map(async ([rewardAssetId, rewardAmount]) => {
          const precision = await fetchTokenPrecision(rewardAssetId);
          const logoResponse = await fetch(`https://api.mainnet.klever.finance/v1.0/assets/${rewardAssetId}`);
          const logoData = await logoResponse.json();
          return {
            assetId: rewardAssetId,
            amount: adjustValue(rewardAmount, precision),
            rawAmount: rewardAmount,
            precision,
            logo: logoData.data.asset.logo || null,
          };
        })
      );
      setRewards(formattedRewards);

      const epochResponse = await fetch('https://api.mainnet.klever.finance/v1.0/block/list');
      const epochData = await epochResponse.json();
      setCurrentEpoch(epochData.data.blocks[0].epoch);

      const unfreezeResponse = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?type=5&asset=${assetId}&fromAddress=${walletAddress}`);
      const unfreezeData = await unfreezeResponse.json();
      const latestUnfreezeTx = unfreezeData.data.transactions.find(tx => tx.status === "success");
      if (latestUnfreezeTx) {
        const unfreezeReceipt = latestUnfreezeTx.receipts.find(receipt => receipt.typeString === "Unfreeze");
        if (unfreezeReceipt) {
          setAvailableEpoch(unfreezeReceipt.availableEpoch);
          const unfreezeValue = adjustValue(unfreezeReceipt.value, token.precision);
          const withdrawResponse = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?type=8&asset=${assetId}&fromAddress=${walletAddress}`);
          const withdrawData = await withdrawResponse.json();
          const latestWithdrawTx = withdrawData.data.transactions.find(tx => tx.status === "success");
          const unfreezeTs = latestUnfreezeTx.timestamp;
          const withdrawTs = latestWithdrawTx ? latestWithdrawTx.timestamp : 0;
          setUnfreezingAmount(unfreezeTs > withdrawTs ? unfreezeValue : '0');
        }
      }
    } catch (err) {
      console.error('Error fetching token data:', err);
      setError('Failed to load token details. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAddress && assetId) {
      fetchAllData();
      const intervalId = setInterval(fetchAllData, 30000); // 30 seconds
      return () => clearInterval(intervalId);
    }
  }, [walletAddress, assetId]);

  const handleSendConfirm = async () => {
    if (!sendAmount || parseFloat(sendAmount) <= 0 || !recipientAddress) {
      setTransactionStatus('Invalid amount or recipient address.');
      return;
    }

    setIsLoading(true);
    const payload = {
      receiver: recipientAddress,
      kda: assetId,
      amount: parseFloat(sendAmount.replace(/,/g, '')) * Math.pow(10, tokenDetails.precision),
    };
    const txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;

    try {
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setTransactionStatus('Transaction sent successfully!');
      setTimeout(() => {
        setIsSendModalOpen(false);
        setTransactionStatus('');
        setSendAmount('');
        setRecipientAddress('');
        fetchAllData();
      }, 2000);
    } catch (error) {
      console.error('Send error:', error);
      setTransactionStatus('Transaction failed!');
      setTimeout(() => setTransactionStatus(''), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStakeConfirm = async () => {
    if (!stakeAmount || parseFloat(stakeAmount) <= 0) {
      setTransactionStatus('Invalid stake amount.');
      return;
    }

    setIsLoading(true);
    const payload = {
      kda: assetId,
      amount: parseFloat(stakeAmount.replace(/,/g, '')) * Math.pow(10, tokenDetails.precision),
    };
    const txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;

    try {
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Freeze }], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setTransactionStatus('Stake transaction successful!');
      setTimeout(() => {
        setIsStakeModalOpen(false);
        setTransactionStatus('');
        setStakeAmount('');
        fetchAllData();
      }, 2000);
    } catch (error) {
      console.error('Stake error:', error);
      setTransactionStatus('Stake transaction failed!');
      setTimeout(() => setTransactionStatus(''), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnstakeConfirm = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?type=4&asset=${assetId}&fromAddress=${walletAddress}`);
      const data = await response.json();
      const bucketId = data.data.transactions[0]?.receipts.find(receipt => receipt.typeString === "Freeze")?.bucketId;
      if (!bucketId) throw new Error('No staking bucket found');

      const payload = { kda: assetId, bucketId };
      const txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Unfreeze }], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setTransactionStatus('Unstake transaction successful!');
      setTimeout(() => {
        setIsUnstakeModalOpen(false);
        setTransactionStatus('');
        fetchAllData();
      }, 2000);
    } catch (error) {
      console.error('Unstake error:', error);
      setTransactionStatus('Unstake transaction failed!');
      setTimeout(() => setTransactionStatus(''), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClaimConfirm = async () => {
    setIsLoading(true);
    const payload = { claimType: 0, id: assetId }; // Claim for the current token
    const txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;

    try {
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Claim }], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setTransactionStatus('Rewards claimed successfully!');
      setTimeout(() => {
        setIsClaimModalOpen(false);
        setTransactionStatus('');
        fetchAllData();
      }, 2000);
    } catch (error) {
      console.error('Claim error:', error);
      setTransactionStatus('Claim transaction failed!');
      setTimeout(() => setTransactionStatus(''), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleWithdrawConfirm = async () => {
    setIsLoading(true);
    const payload = { kda: assetId, withdrawType: 0 };
    const txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;

    try {
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Withdraw }], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setTransactionStatus('Withdraw transaction successful!');
      setTimeout(() => {
        setIsWithdrawModalOpen(false);
        setTransactionStatus('');
        fetchAllData();
      }, 2000);
    } catch (error) {
      console.error('Withdraw error:', error);
      setTransactionStatus('Withdraw transaction failed!');
      setTimeout(() => setTransactionStatus(''), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const hasRewards = useMemo(() => rewards.some(reward => parseFloat(reward.rawAmount) > 0), [rewards]);

  // Navigate to Rewards page if assetId is "KLV"
  useEffect(() => {
    if (assetId === "KLV") {
      navigate('/rewards');
    }
  }, [assetId, navigate]);

  return (
    <div className="token-details-container">
      {isLoading && !tokenDetails ? (
        <div className="token-details-loading"><Spinner /></div>
      ) : error || !tokenDetails ? (
        <p className="token-details-error">{error || 'Token not found.'}</p>
      ) : (
        <>
          <div className="token-details-header">
            <img src={tokenDetails.logo} alt={`${tokenDetails.name} Logo`} className="token-details-main-token-logo" />
            <h1 className="token-details-page-title">{tokenDetails.name} ({tokenDetails.ticker})</h1>
          </div>
          <div className="token-details-sections">
            <div className="token-details-user-info">
              <h2 className="token-details-user-info-title">Your Holdings</h2>
              <div className="token-details-user-details-grid">
                <div className="token-details-user-detail">
                  <span className="token-details-detail-label">Balance:</span>
                  <span className="token-details-detail-value">{balance}</span>
                  <div className="token-details-user-detail-buttons">
                    <button onClick={() => setIsSendModalOpen(true)}>Send</button>
                    <button onClick={() => setIsStakeModalOpen(true)}>Freeze</button>
                  </div>
                </div>
                <div className="token-details-user-detail">
                  <span className="token-details-detail-label">Frozen:</span>
                  <span className="token-details-detail-value">{stakedAmount}</span>
                  <div className="token-details-user-detail-buttons">
                    <button
                      onClick={() => setIsUnstakeModalOpen(true)}
                      disabled={stakedAmount === '0' || (stakedEpoch + minEpochsToUnstake > currentEpoch)}
                    >
                      Unfreeze
                    </button>
                  </div>
                </div>
                <div className="token-details-user-detail">
                  <span className="token-details-detail-label">Unfreezing:</span>
                  <span className="token-details-detail-value">{unfreezingAmount}</span>
                  <div className="token-details-user-detail-buttons">
                    <button
                      onClick={() => setIsWithdrawModalOpen(true)}
                      disabled={availableEpoch === "100000000" || parseInt(availableEpoch) > currentEpoch || unfreezingAmount === '0'}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
                <div className="token-details-user-detail">
                  <span className="token-details-detail-label">Rewards:</span>
                  <div className="token-details-rewards-list">
                    {rewards.length > 0 ? (
                      rewards.map((reward, index) => (
                        <div key={index} className="token-details-reward-item">
                          {reward.logo && <img src={reward.logo} alt={`${reward.assetId} logo`} className="token-details-reward-logo" />}
                          <span className="token-details-reward-value">{reward.amount} {reward.assetId}</span>
                        </div>
                      ))
                    ) : (
                      <span className="token-details-no-rewards">No rewards available</span>
                    )}
                    {rewards.length > 0 && (
                      <div className="token-details-user-detail-buttons token-details-rewards-buttons">
                        <button onClick={() => setIsClaimModalOpen(true)} disabled={!hasRewards}>Claim All</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="token-details-card">
              <div className="token-details-details-grid">
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Initial Supply:</span>
                  <span className="token-details-detail-value">{adjustValue(tokenDetails.initialSupply, tokenDetails.precision)}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Circulating Supply:</span>
                  <span className="token-details-detail-value">{adjustValue(tokenDetails.circulatingSupply, tokenDetails.precision)}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Max Supply:</span>
                  <span className="token-details-detail-value">{adjustValue(tokenDetails.maxSupply, tokenDetails.precision)}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Burned Value:</span>
                  <span className="token-details-detail-value">{adjustValue(tokenDetails.burnedValue, tokenDetails.precision)}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Total Staked:</span>
                  <span className="token-details-detail-value">{adjustValue(tokenDetails.staking.totalStaked, tokenDetails.precision)}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">APR:</span>
                  <span className="token-details-detail-value">{tokenDetails.staking?.interestType === "APRI" ? `${getLatestAPR(tokenDetails.staking.apr)}%` : 'N/A'}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Min Epochs to Claim:</span>
                  <span className="token-details-detail-value">{minEpochsToClaim}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Min Epochs to Unstake:</span>
                  <span className="token-details-detail-value">{minEpochsToUnstake}</span>
                </div>
                <div className="token-details-detail-item">
                  <span className="token-details-detail-label">Min Epochs to Withdraw:</span>
                  <span className="token-details-detail-value">{minEpochsToWithdraw}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Send Modal */}
          {isSendModalOpen && (
            <div className="token-details-action-modal">
              <div className="token-details-action-modal-content">
                <button className="token-details-modal-close" onClick={() => setIsSendModalOpen(false)}>×</button>
                <h2>Send {tokenDetails.ticker}</h2>
                <div className="token-details-modal-input-group">
                  <label>Amount</label>
                  <input
                    type="number"
                    placeholder="Enter amount"
                    value={sendAmount}
                    onChange={(e) => setSendAmount(e.target.value)}
                  />
                  <span className="token-details-modal-info-text">Available: {balance}</span>
                </div>
                <div className="token-details-modal-input-group">
                  <label>Recipient Address</label>
                  <input
                    type="text"
                    placeholder="Enter recipient address"
                    value={recipientAddress}
                    onChange={(e) => setRecipientAddress(e.target.value)}
                  />
                </div>
                <div className="token-details-action-modal-actions">
                  <button onClick={handleSendConfirm} disabled={isLoading}>Confirm</button>
                  <button onClick={() => setIsSendModalOpen(false)} disabled={isLoading}>Cancel</button>
                </div>
                {transactionStatus && (
                  <div className={`token-details-transaction-status ${transactionStatus.includes('failed') ? 'error' : 'success'}`}>
                    {transactionStatus}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Stake Modal */}
          {isStakeModalOpen && (
            <div className="token-details-action-modal">
              <div className="token-details-action-modal-content">
                <button className="token-details-modal-close" onClick={() => setIsStakeModalOpen(false)}>×</button>
                <h2>Freeze {tokenDetails.ticker}</h2>
                <div className="token-details-modal-input-group">
                  <label>Amount to Freeze</label>
                  <input
                    type="number"
                    placeholder="Enter amount"
                    value={stakeAmount}
                    onChange={(e) => setStakeAmount(e.target.value)}
                  />
                  <span className="token-details-modal-info-text">Available: {balance}</span>
                </div>
                <div className="token-details-action-modal-actions">
                  <button onClick={handleStakeConfirm} disabled={isLoading}>Confirm</button>
                  <button onClick={() => setIsStakeModalOpen(false)} disabled={isLoading}>Cancel</button>
                </div>
                {transactionStatus && (
                  <div className={`token-details-transaction-status ${transactionStatus.includes('failed') ? 'error' : 'success'}`}>
                    {transactionStatus}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Unstake Modal */}
          {isUnstakeModalOpen && (
            <div className="token-details-action-modal">
              <div className="token-details-action-modal-content">
                <button className="token-details-modal-close" onClick={() => setIsUnstakeModalOpen(false)}>×</button>
                <h2>Unfreeze {tokenDetails.ticker}</h2>
                <div className="token-details-confirmation-text">
                  <p>Unfreeze your entire staked balance:</p>
                  <p><strong>{stakedAmount}</strong></p>
                </div>
                <div className="token-details-action-modal-actions">
                  <button onClick={handleUnstakeConfirm} disabled={isLoading}>Confirm</button>
                  <button onClick={() => setIsUnstakeModalOpen(false)} disabled={isLoading}>Cancel</button>
                </div>
                {transactionStatus && (
                  <div className={`token-details-transaction-status ${transactionStatus.includes('failed') ? 'error' : 'success'}`}>
                    {transactionStatus}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Claim Rewards Modal */}
          {isClaimModalOpen && (
            <div className="token-details-action-modal">
              <div className="token-details-action-modal-content">
                <button className="token-details-modal-close" onClick={() => setIsClaimModalOpen(false)}>×</button>
                <h2>Claim Rewards for {tokenDetails.ticker}</h2>
                <div className="token-details-confirmation-text">
                  <p>Total rewards to claim:</p>
                  {rewards.map((reward, index) => (
                    <p key={index}>
                      <strong>{reward.amount} {reward.assetId}</strong>
                      {reward.logo && <img src={reward.logo} alt={`${reward.assetId} logo`} className="token-details-modal-reward-logo" />}
                    </p>
                  ))}
                </div>
                <div className="token-details-action-modal-actions">
                  <button onClick={handleClaimConfirm} disabled={isLoading}>Confirm</button>
                  <button onClick={() => setIsClaimModalOpen(false)} disabled={isLoading}>Cancel</button>
                </div>
                {transactionStatus && (
                  <div className={`token-details-transaction-status ${transactionStatus.includes('failed') ? 'error' : 'success'}`}>
                    {transactionStatus}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Withdraw Modal */}
          {isWithdrawModalOpen && (
            <div className="token-details-action-modal">
              <div className="token-details-action-modal-content">
                <button className="token-details-modal-close" onClick={() => setIsWithdrawModalOpen(false)}>×</button>
                <h2>Withdraw {tokenDetails.ticker}</h2>
                <div className="token-details-confirmation-text">
                  <p>Withdraw your available balance:</p>
                  <p><strong>{unfreezingAmount}</strong></p>
                </div>
                <div className="token-details-action-modal-actions">
                  <button onClick={handleWithdrawConfirm} disabled={isLoading}>Confirm</button>
                  <button onClick={() => setIsWithdrawModalOpen(false)} disabled={isLoading}>Cancel</button>
                </div>
                {transactionStatus && (
                  <div className={`token-details-transaction-status ${transactionStatus.includes('failed') ? 'error' : 'success'}`}>
                    {transactionStatus}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TokenDetailsPage;