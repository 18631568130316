import React, { useState, useEffect, useContext } from 'react';
import { useWallet } from '../components/WalletContext';
import { useNavigate } from 'react-router-dom';
import { web, TransactionType } from '@klever/sdk-web';
import Spinner from '../components/Spinner';
import { ThemeContext } from '../components/ThemeContext'; // Import ThemeContext
import '../styles/WalletTokensPage.css';

/**
 * Utility to fetch a token's precision, logo, ticker, and total supply from the Klever API.
 */
async function fetchAssetInfo(assetId) {
  try {
    const response = await fetch(`https://api.mainnet.klever.finance/v1.0/assets/${assetId}`);
    const data = await response.json();
    return {
      precision: data.data.asset.precision,
      logo: data.data.asset.logo || '',
      ticker: data.data.asset.assetTicker || assetId.split('-')[0],
      totalSupply: data.data.asset.totalSupply ? (data.data.asset.totalSupply / Math.pow(10, data.data.asset.precision)).toFixed(2) : 'N/A',
    };
  } catch (err) {
    console.error(`Error fetching asset info for ${assetId}`, err);
    return { precision: 6, logo: '', ticker: assetId.split('-')[0], totalSupply: 'N/A' };
  }
}

/**
 * Replaces mint URL with IPFS if needed.
 */
const replaceLogoUri = (assetId, logoUrl) => {
  if (assetId === 'WCNFT-25HY') return null;
  if (!logoUrl) return logoUrl;
  if (logoUrl.includes('https://klever-mint.mypinata.cloud')) {
    return logoUrl.replace('https://klever-mint.mypinata.cloud', 'https://ipfs.io');
  }
  return logoUrl;
};

const adjustBalance = (balance, precision) => (balance / Math.pow(10, precision)).toFixed(precision);

const WalletPageTokens = () => {
  const { walletAddress } = useWallet();
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('All');
  const navigate = useNavigate();
  const { isDarkMode } = useContext(ThemeContext); // Access theme context

  const fetchAssets = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch assets');
      const data = await response.json();

      const assetEntries = Object.entries(data.data.account.assets || {});
      const fungibleTokens = assetEntries.filter(([, assetData]) => assetData.assetType === 0);

      const tokens = await Promise.all(
        fungibleTokens.map(async ([assetId, assetData]) => {
          const info = await fetchAssetInfo(assetId);
          const logoUrl = replaceLogoUri(assetId, info.logo);
          return {
            id: assetId,
            name: assetData.assetName,
            ticker: info.ticker,
            balance: adjustBalance(assetData.balance, assetData.precision),
            frozenBalance: adjustBalance(assetData.frozenBalance || 0, assetData.precision),
            precision: assetData.precision,
            logoUrl,
            totalSupply: info.totalSupply,
          };
        })
      );

      setAssets(tokens);
    } catch (err) {
      console.error('Error fetching tokens:', err);
      setError('Could not load your tokens. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!walletAddress) return;
    fetchAssets();
  }, [walletAddress]);

  const handleDetailsClick = (assetId) => navigate(`/wallet/tokens/${assetId}`);

  const filteredAssets = assets.filter((asset) => {
    const matchesSearch = asset.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                         asset.ticker.toLowerCase().includes(searchTerm.toLowerCase());
    if (filter === 'Staked') return matchesSearch && parseFloat(asset.frozenBalance) > 0;
    if (filter === 'Non-Zero') return matchesSearch && parseFloat(asset.balance) > 0;
    return matchesSearch;
  });

  const totalTokens = assets.length;
  const totalValue = filteredAssets.reduce((sum, asset) => sum + parseFloat(asset.balance), 0).toFixed(2);

  return (
    <div className="wallet-tokens-container">
      <div className="wallet-tokens-header">
        <h1 className="wallet-tokens-title">Your Tokens</h1>
        <div className="wallet-stats">
          <span>Total Tokens: {totalTokens}</span>
          <span>Total Balance: {totalValue}</span>
        </div>
      </div>

      <div className="wallet-tokens-controls">
        <div className="wallet-tokens-search">
          <input
            type="text"
            placeholder="Search by name or ticker..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select className="wallet-tokens-filter" value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="All">All Tokens</option>
          <option value="Staked">Staked Only</option>
          <option value="Non-Zero">Non-Zero Balance</option>
        </select>
      </div>

      <ClaimAllRewardsSection assets={assets} refreshAssets={fetchAssets} />

      {isLoading ? (
        <div className="wallet-tokens-loading"><Spinner /></div>
      ) : error ? (
        <p className="wallet-tokens-error">{error}</p>
      ) : filteredAssets.length === 0 ? (
        <p className="wallet-tokens-empty">No tokens found matching your criteria.</p>
      ) : (
        <div className="wallet-tokens-assets-list">
          {filteredAssets.map((asset) => (
            <div key={asset.id} className="wallet-tokens-asset-item">
              {asset.logoUrl ? (
                <img src={asset.logoUrl} alt={`${asset.name} logo`} className="wallet-tokens-asset-logo" />
              ) : (
                <div className="wallet-tokens-asset-logo wallet-tokens-placeholder-logo" />
              )}
              <div className="wallet-tokens-asset-details">
                <div className="wallet-tokens-asset-name">{asset.name} ({asset.ticker})</div>
                <div className="wallet-tokens-asset-balance">
                  Balance: {asset.balance} | Staked: {asset.frozenBalance}
                </div>
              </div>
              <button className="wallet-tokens-action-button" onClick={() => handleDetailsClick(asset.id)}>
                Details
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ClaimAllRewardsSection = ({ assets, refreshAssets }) => {
  const { walletAddress, useKdaFee, selectedKda } = useWallet();
  const [stakedTokens, setStakedTokens] = useState([]);
  const [isLoadingRewards, setIsLoadingRewards] = useState(false);
  const [claimStatus, setClaimStatus] = useState('');
  const [expandedToken, setExpandedToken] = useState(null);
  const [isClaiming, setIsClaiming] = useState(false);

  const fetchAllRewards = async () => {
    setIsLoadingRewards(true);
    try {
      const newStakedTokens = [];
      const stakedAssets = assets.filter((asset) => parseFloat(asset.frozenBalance) > 0);
      for (const asset of stakedAssets) {
        const allowanceUrl = `https://node.mainnet.klever.finance/address/${walletAddress}/allowance?asset=${asset.id}`;
        const allowanceRes = await fetch(allowanceUrl);
        const allowanceData = await allowanceRes.json();

        const allRewards = allowanceData.data.allStakingRewards || {};
        const rewardAssets = [];
        for (const [rewardAssetId, rawAmount] of Object.entries(allRewards)) {
          const info = await fetchAssetInfo(rewardAssetId);
          const rewardLogo = replaceLogoUri(rewardAssetId, info.logo);
          const adjusted = parseFloat(rawAmount) / Math.pow(10, info.precision || 6);
          if (adjusted > 0) {
            rewardAssets.push({
              rewardAssetId,
              rewardAmount: adjusted,
              rewardLogo,
            });
          }
        }
        if (rewardAssets.length > 0) {
          newStakedTokens.push({
            tokenId: asset.id,
            tokenName: asset.name,
            tokenLogo: asset.logoUrl,
            rewardAssets,
          });
        }
      }
      setStakedTokens(newStakedTokens);
    } catch (err) {
      console.error('Error fetching all rewards:', err);
    } finally {
      setIsLoadingRewards(false);
    }
  };

  useEffect(() => {
    if (!walletAddress || assets.length === 0) return;
    fetchAllRewards();
  }, [walletAddress, assets]);

  const handleClaimAll = async () => {
    setClaimStatus('');
    if (stakedTokens.length === 0) {
      setClaimStatus('No rewards to claim.');
      clearStatusAfterDelay();
      return;
    }
    try {
      setIsClaiming(true);
      setClaimStatus('Claiming rewards...');

      const actions = stakedTokens.map((st) => ({
        payload: { claimType: 0, id: st.tokenId },
        type: TransactionType.Claim,
      }));
      const txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;
      const unsignedTx = await web.buildTransaction(actions, undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);

      if (!signedTx) throw new Error('Transaction cancelled by user.');
      await web.broadcastTransactions([signedTx]);

      setClaimStatus('Rewards claimed successfully!');
      
      // Refresh both rewards and full token list after a delay
      setTimeout(async () => {
        await Promise.all([
          fetchAllRewards(), // Refresh staking rewards
          refreshAssets()    // Refresh all token balances
        ]);
        setIsClaiming(false);
        clearStatusAfterDelay();
      }, 2000); // 2-second delay for blockchain processing
    } catch (error) {
      console.error('Claim All error:', error);
      setClaimStatus(error.message.toLowerCase().includes('cancel') 
        ? 'Transaction cancelled.' 
        : 'Claim All transaction failed.');
      setIsClaiming(false);
      clearStatusAfterDelay();
    }
  };

  const clearStatusAfterDelay = () => setTimeout(() => setClaimStatus(''), 4000);

  const toggleExpand = (tokenId) => setExpandedToken(expandedToken === tokenId ? null : tokenId);

  return (
    <div className="wallet-tokens-claim-all-rewards-section">
      <h2>Staking Rewards</h2>
      {isLoadingRewards ? (
        <Spinner />
      ) : stakedTokens.length > 0 ? (
        <div className="wallet-tokens-claim-all-content">
          <p>You have rewards from {stakedTokens.length} token(s):</p>
          <div className="rewards-details">
            {stakedTokens.map((st) => (
              <div key={st.tokenId} className="wallet-tokens-staked-token-rewards">
                <div className="wallet-tokens-staked-token-header" onClick={() => toggleExpand(st.tokenId)}>
                  {st.tokenLogo ? (
                    <img src={st.tokenLogo} alt={`${st.tokenName} logo`} className="wallet-tokens-staked-token-logo" />
                  ) : (
                    <div className="wallet-tokens-staked-token-logo wallet-tokens-placeholder-logo" />
                  )}
                  <span className="wallet-tokens-staked-token-name">{st.tokenName}</span>
                </div>
                {expandedToken === st.tokenId && (
                  <div className="wallet-tokens-reward-item-list">
                    {st.rewardAssets.map((ra) => (
                      <div key={ra.rewardAssetId} className="wallet-tokens-reward-item">
                        {ra.rewardLogo ? (
                          <img src={ra.rewardLogo} alt="Reward logo" className="wallet-tokens-reward-token-logo" />
                        ) : (
                          <div className="wallet-tokens-reward-token-logo wallet-tokens-placeholder-logo" />
                        )}
                        <span className="wallet-tokens-reward-token-id">{ra.rewardAssetId}</span>
                        <span className="wallet-tokens-reward-amount">{ra.rewardAmount.toFixed(4)}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <button onClick={handleClaimAll} disabled={isClaiming}>
            Claim All Rewards
          </button>
          {(claimStatus || isClaiming) && (
            <div className={`wallet-tokens-transaction-status ${claimStatus.includes('failed') || claimStatus.includes('cancelled') ? 'error' : 'success'}`}>
              {isClaiming && <Spinner />}
              {claimStatus}
            </div>
          )}
        </div>
      ) : (
        <p>No rewards available.</p>
      )}
    </div>
  );
};

export default WalletPageTokens;