import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useWallet } from '../components/WalletContext';
import Spinner from '../components/Spinner';
import '../styles/CollectionDetailPage.css';
import { getPrimaryColorFromImage } from '../utils/colorUtils';

// Import images
import skyGalleryImage from '../assets/skygallery.png';
import klevernftImage from '../assets/klever.png';
import minthreeImage from '../assets/minthree.png';
import xportImage from '../assets/xport.png';
import RareCanvasImage from '../assets/logoicon2.png';
import kDEXImage from '../assets/kdex.png';
import worldImage from '../assets/world.png';
import artGalleryImage from '../assets/artgallery.png';
import telegramLogo from '../assets/telegramlogo.png';
import websiteLogo from '../assets/websitelogo.png';
import discordLogo from '../assets/discordlogo.png';

const marketplaceNames = {
  '417b70c0eb7a33cb': 'Rare Canvas',
  'd4f2bab340c55fde': 'Klever NFT',
  'aa68ad853a09988c': 'Xport',
  'd2a04fe890a6edda': 'NFT Art Gallery',
  '2936933ee43db509': 'Minthree',
  '7c353c02770da029': 'Sky Gallery',
  '116056b257d9f6d5': 'kDEX',
  '81376f526cf47730': 'World Dex'
};

const marketplaceImages = {
  '7c353c02770da029': skyGalleryImage,
  'd4f2bab340c55fde': klevernftImage,
  '2936933ee43db509': minthreeImage,
  'aa68ad853a09988c': xportImage,
  '417b70c0eb7a33cb': RareCanvasImage,
  '116056b257d9f6d5': kDEXImage,
  '81376f526cf47730': worldImage,
  'd2a04fe890a6edda': artGalleryImage,
};

const CollectionDetailPage = () => {
  const { collectionId } = useParams();
  const { walletAddress } = useWallet();

  // Main states
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [activeTab, setActiveTab] = useState('items');
  const [activeSubTab, setActiveSubTab] = useState('sales');
  const [activity, setActivity] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionPage, setTransactionPage] = useState(1);
  const [totalTransactionPages, setTotalTransactionPages] = useState(0);
  const [nftPrices, setNftPrices] = useState({});
  const [logoUrl, setLogoUrl] = useState('');
  const [bannerUri, setBannerUri] = useState('');
  const [gradientBackground, setGradientBackground] = useState('');
  const [assetName, setAssetName] = useState('');
  const [totalVolume, setTotalVolume] = useState(0);
  const [floorPrice, setFloorPrice] = useState(0);
  const [items, setItems] = useState(0);
  const [owners, setOwners] = useState(0);
  const [selectedMarketplace, setSelectedMarketplace] = useState('417b70c0eb7a33cb');
  const [telegramUri, setTelegramUri] = useState('');
  const [websiteUri, setWebsiteUri] = useState('');
  const [discordUri, setDiscordUri] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [cache, setCache] = useState({});
  const [activityPage, setActivityPage] = useState(1);
  const [totalActivityPages, setTotalActivityPages] = useState(0);
  const [traits, setTraits] = useState({});
  const [selectedTraits, setSelectedTraits] = useState({});
  const [sortOrder, setSortOrder] = useState('default');
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [marketplaceDropdownVisible, setMarketplaceDropdownVisible] = useState(false);

  // Fetch data on load and when dependencies change
  useEffect(() => {
    fetchData(currentPage);
    fetchCollectionStats();
    fetchActivityData();
    fetchTransactions();
  }, [collectionId, currentPage, activityPage, transactionPage, selectedMarketplace, sortOrder]);

  useEffect(() => {
    // Reset pagination when switching tabs
    setCurrentPage(1);
    setActivityPage(1);
    setTransactionPage(1);
  }, [activeTab]);

  useEffect(() => {
    // Generate gradient background if no banner is provided
    if (logoUrl && !bannerUri) {
      generateGradientFromLogo(logoUrl);
    }
  }, [logoUrl, bannerUri]);

  // Asset and metadata functions
  const fetchAssetList = async () => {
    try {
      const baseUrl = 'https://api.mainnet.klever.finance/v1.0/assets/list?limit=100';
      const [page1, page2] = await Promise.all([
        axios.get(`${baseUrl}&page=1`),
        axios.get(`${baseUrl}&page=2`)
      ]);
      const combinedAssets = [...page1.data.data.assets, ...page2.data.data.assets];
      const matchingAsset = combinedAssets.find(asset => asset.assetId === collectionId);
      if (matchingAsset) {
        setAssetName(matchingAsset.name);
        setTelegramUri(extractUri(matchingAsset, 'telegram'));
        setWebsiteUri(extractUri(matchingAsset, 'website'));
        setDiscordUri(extractUri(matchingAsset, 'discord'));
        setBannerUri(extractUri(matchingAsset, 'banner'));
        setLogoUrl(modifyLogoUrl(matchingAsset.logo));
      }
      return matchingAsset;
    } catch (error) {
      console.error('Error fetching asset list:', error);
      return null;
    }
  };

  const extractUri = (asset, key) => {
    const found = asset.uris.find(uri => uri.key.toLowerCase() === key.toLowerCase());
    return found ? found.value : null;
  };

  const modifyLogoUrl = (logoUri) => {
    return logoUri && logoUri.includes("klever-mint.mypinata.cloud")
      ? logoUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io")
      : logoUri;
  };

  const generateGradientFromLogo = async (logoUrl) => {
    try {
      const colors = await getPrimaryColorFromImage(logoUrl);
      if (colors && colors.length > 0) {
        setGradientBackground(`linear-gradient(45deg, ${colors.join(', ')})`);
      }
    } catch (error) {
      console.error('Error generating gradient:', error);
    }
  };

  const fetchMetadata = async (asset, nonce) => {
    if (cache[nonce]) {
      setImageUrl(cache[nonce].image);
      return cache[nonce];
    }
    try {
      let metadataUri = getMetadataUri(asset, nonce);
      if (!metadataUri) return null;
      const response = await fetch(metadataUri);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const metadata = await response.json();
      metadata.image = modifyImageUrl(metadata.image);
      setImageUrl(metadata.image);
      const price = await fetchNftPrice(`${collectionId}/${nonce}`);
      setCache(prev => ({ ...prev, [nonce]: { ...metadata, price } }));

      // Extract and store traits
      if (metadata.attributes) {
        const extracted = {};
        metadata.attributes.forEach(attr => {
          extracted[attr.trait_type] = extracted[attr.trait_type]
            ? new Set([...extracted[attr.trait_type], attr.value])
            : new Set([attr.value]);
        });
        setTraits(prev => {
          const updated = { ...prev };
          Object.entries(extracted).forEach(([k, v]) => {
            updated[k] = prev[k] ? new Set([...prev[k], ...v]) : v;
          });
          return updated;
        });
      }
      return { ...metadata, price };
    } catch (error) {
      console.error(`Error fetching metadata for nonce ${nonce}:`, error);
      return null;
    }
  };

  const getMetadataUri = (asset, nonce) => {
    const metaObj = asset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
    let uri = metaObj ? metaObj.value : null;
    if (uri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") {
      uri = `https://ipfs.io/ipfs/${uri}/${nonce}.json`;
    } else if (uri) {
      if (uri.includes("klever-mint.mypinata.cloud")) {
        uri = uri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
      }
      uri = `${uri}/${nonce}`;
    }
    return uri;
  };

  const modifyImageUrl = (imageUri) => {
    if (imageUri && imageUri.includes("www.lovemonsternft.com")) {
      const imageName = imageUri.split('/').pop();
      return `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
    } else if (imageUri && imageUri.includes("klever-mint.mypinata.cloud")) {
      return imageUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
    }
    return imageUri;
  };

  const fetchNftPrice = async (uniqueNftId) => {
    try {
      const url = `https://api.mainnet.klever.finance/v1.0/marketplaces/nfts/activity?nftId=${uniqueNftId}&sort=desc&limit=10&page=1`;
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const rawPrice = data.data.history[0]?.contract[0]?.parameter?.price;
      if (rawPrice) {
        const price = rawPrice / 1000000;
        const formattedPrice = Number.isInteger(price)
          ? price.toLocaleString('en-US')
          : price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 6 });
        setNftPrices(prev => ({ ...prev, [uniqueNftId]: formattedPrice }));
        return formattedPrice;
      }
    } catch (error) {
      console.error('Error fetching NFT price:', error);
    }
  };

  const updateMetadataForNonce = (nonce, metadata) => {
    setNfts(prev =>
      prev.map(nft => (nft.nonce === nonce ? { ...nft, metadata, loadingMetadata: false } : nft))
    );
  };

  // Fetch NFT data for the current page
  const fetchData = async (page) => {
    setLoading(true);
    try {
      const asset = await fetchAssetList();
      if (!asset) {
        setLoading(false);
        return;
      }
      const response = await axios.get(
        `https://api.mainnet.klever.finance/marketplaces/${selectedMarketplace}?collection=${collectionId}&price=desc&limit=50&page=${page}`
      );
      const assetsData = response.data.data.assets.assets;
      const initialNfts = Object.keys(assetsData).map(key => {
        const assetDetails = assetsData[key];
        return { nonce: assetDetails.nftNonce, metadata: null, loadingMetadata: true, orderId: assetDetails.orderId };
      });
      setNfts(initialNfts);
      setTotalPages(response.data.pagination.totalPages);

      // Load metadata for each NFT
      initialNfts.forEach(async nft => {
        try {
          const metadata = await fetchMetadata(asset, nft.nonce);
          const transactionResponse = await axios.get(
            `https://api.mainnet.klever.finance/v1.0/transaction/list?orderid=${nft.orderId}`
          );
          const transactions = transactionResponse.data.data.transactions;
          const now = Math.floor(Date.now() / 1000);
          const isExpired = transactions.some(tx =>
            tx.contract.some(contract => contract.parameter.endTime <= now)
          );
          updateMetadataForNonce(nft.nonce, { ...metadata, expired: isExpired, orderId: nft.orderId });
        } catch (error) {
          console.error(`Error fetching data for nonce ${nft.nonce}:`, error);
          updateMetadataForNonce(nft.nonce, { metadata: null, expired: false, orderId: nft.orderId });
        }
      });
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    }
    setLoading(false);
  };

  // Fetch collection statistics from multiple marketplaces
  const fetchCollectionStats = async () => {
    try {
      const marketplaceIds = [
        'd4f2bab340c55fde', '7c353c02770da029', '2936933ee43db509',
        '417b70c0eb7a33cb', '116056b257d9f6d5', 'aa68ad853a09988c',
        '81376f526cf47730', 'd2a04fe890a6edda'
      ];
      const statsPromises = marketplaceIds.map(id =>
        axios.get(`https://api.mainnet.klever.finance/v1.0/marketplaces/${id}/stats`)
      );
      const statsResponses = await Promise.all(statsPromises);
      let totalVol = 0, lowestFloor = Infinity, totalIt = 0, totalOwn = 0;
      statsResponses.forEach(response => {
        const statsData = response.data.data.stats;
        const collectionStats = statsData.find(stat => stat.assetId === collectionId);
        if (collectionStats) {
          totalVol += collectionStats.volumeTotal || 0;
          lowestFloor = Math.min(lowestFloor, collectionStats.floor || Infinity);
          totalIt += collectionStats.items || 0;
          totalOwn += collectionStats.owners || 0;
        }
      });
      setTotalVolume(totalVol);
      setFloorPrice(lowestFloor === Infinity ? 0 : lowestFloor);
      setItems(totalIt);
      setOwners(totalOwn);
    } catch (error) {
      console.error('Error fetching collection stats:', error);
    }
  };

  // Fetch recent activity data
  const fetchActivityData = async () => {
    try {
      const response = await axios.get(
        `https://api.mainnet.klever.finance/v1.0/transaction/list?limit=10&type=18&status=success&asset=${collectionId}&page=${activityPage}`
      );
      const enriched = response.data.data.transactions.flatMap(tx => {
        const marketplaceReceipt = tx.receipts.find(r => r.marketplaceId);
        const marketplaceId = marketplaceReceipt ? marketplaceReceipt.marketplaceId : null;
        return tx.contract.map(contract => ({ ...tx, contract, marketplaceId }));
      });
      setActivity(enriched);
      setTotalActivityPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching activity data:', error);
    }
  };

  // Fetch transaction data (e.g., sales)
  const fetchTransactions = async () => {
    try {
      const limit = 100;
      const response = await axios.get(
        `https://api.mainnet.klever.finance/v1.0/transaction/list?limit=${limit}&type=17&status=success&asset=KLV&page=${transactionPage}`
      );
      const filtered = response.data.data.transactions.filter(tx => {
        const isMarketBuy = tx.contract.some(contract =>
          contract.type === 17 && contract.parameter.buyType === "MarketBuy"
        );
        const includesCollection = tx.receipts.some(r =>
          r.assetId && r.assetId.startsWith(`${collectionId}/`)
        );
        return isMarketBuy && includesCollection;
      }).map(tx => {
        const marketplaceReceipt = tx.receipts.find(r => r.marketplaceId);
        return { ...tx, marketplaceId: marketplaceReceipt ? marketplaceReceipt.marketplaceId : null };
      });
      setTransactions(filtered);
      setTotalTransactionPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  // Handlers for pagination, filtering, and marketplace dropdown
  const handlePrevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const handleNextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const handleActivityPrevPage = () => activityPage > 1 && setActivityPage(activityPage - 1);
  const handleActivityNextPage = () => activityPage < totalActivityPages && setActivityPage(activityPage + 1);
  const handleTransactionPrevPage = () => transactionPage > 1 && setTransactionPage(transactionPage - 1);
  const handleTransactionNextPage = () => transactionPage < totalTransactionPages && setTransactionPage(transactionPage + 1);
  const handleTraitChange = (trait, value) => {
    setSelectedTraits(prev => {
      const updated = { ...prev };
      value === '' ? delete updated[trait] : updated[trait] = value;
      return updated;
    });
  };
  const handleSortOrderChange = (order) => setSortOrder(order);
  const handleActiveFilterChange = () => setShowActiveOnly(!showActiveOnly);
  const toggleMarketplaceDropdown = () => setMarketplaceDropdownVisible(!marketplaceDropdownVisible);
  const handleMarketplaceSelect = (id) => {
    setSelectedMarketplace(id);
    setMarketplaceDropdownVisible(false);
  };

  // Sorting and filtering logic
  const sortedNfts = [...nfts].sort((a, b) => {
    if (sortOrder === 'default') return 0;
    if (!a.metadata || !b.metadata || !a.metadata.price || !b.metadata.price) return 0;
    const priceA = parseFloat(a.metadata.price.replace(/,/g, ''));
    const priceB = parseFloat(b.metadata.price.replace(/,/g, ''));
    return sortOrder === 'low-high' ? priceA - priceB : priceB - priceA;
  });

  const filteredNfts = sortedNfts.filter(nft => {
    if (!nft.metadata) return true;
    if (showActiveOnly && nft.metadata.expired) return false;
    return Object.entries(selectedTraits).every(([trait, value]) =>
      nft.metadata.attributes?.some(attr => attr.trait_type === trait && attr.value === value)
    );
  });

  return (
    <div className="collection-page">
      {/* Header Section */}
      <header
        className="collection-header"
        style={{ backgroundImage: bannerUri ? `url(${bannerUri})` : gradientBackground }}
      >
        <div className="header-overlay">
          <div className="collection-logo-title">
            {logoUrl && (
              <img src={logoUrl} alt="Collection Logo" className="collection-logo" />
            )}
            <div className="collection-title">
              <h1>{assetName || collectionId}</h1>
            </div>
          </div>
          <div className="collection-uris">
            {telegramUri && (
              <a href={telegramUri} target="_blank" rel="noopener noreferrer">
                <img src={telegramLogo} alt="Telegram" />
              </a>
            )}
            {websiteUri && (
              <a href={websiteUri} target="_blank" rel="noopener noreferrer">
                <img src={websiteLogo} alt="Website" />
              </a>
            )}
            {discordUri && (
              <a href={discordUri} target="_blank" rel="noopener noreferrer">
                <img src={discordLogo} alt="Discord" />
              </a>
            )}
          </div>
          <div className="collection-stats">
            <div className="stat-item">
              <span>Total Volume</span>
              <span>{totalVolume.toFixed(2)} KLV</span>
            </div>
            <div className="stat-item">
              <span>Floor Price</span>
              <span>{floorPrice.toFixed(2)} KLV</span>
            </div>
            <div className="stat-item">
              <span>Items</span>
              <span>{items}</span>
            </div>
            <div className="stat-item">
              <span>Owners</span>
              <span>{owners}</span>
            </div>
          </div>
        </div>
      </header>

      {/* Tab Navigation */}
      <nav className="tab-navigation">
        <button className={`tab-item ${activeTab === 'items' && 'active'}`} onClick={() => setActiveTab('items')}>
          <i className="fas fa-th-large"></i> Items
        </button>
        <button className={`tab-item ${activeTab === 'activity' && 'active'}`} onClick={() => setActiveTab('activity')}>
          <i className="fas fa-chart-line"></i> Activity
        </button>
        {activeTab === 'activity' && (
          <div className="sub-tab-navigation">
            <button className={`sub-tab-item ${activeSubTab === 'sales' && 'active'}`} onClick={() => setActiveSubTab('sales')}>
              <i className="fas fa-list"></i> Listings
            </button>
            <button className={`sub-tab-item ${activeSubTab === 'listings' && 'active'}`} onClick={() => setActiveSubTab('listings')}>
              <i className="fas fa-shopping-cart"></i> Sales
            </button>
          </div>
        )}
        {activeTab === 'items' && (
          <div className="tab-extras">
            <button className={`tab-item ${filtersVisible && 'active'}`} onClick={() => setFiltersVisible(!filtersVisible)}>
              <i className="fas fa-filter"></i> {filtersVisible ? 'Hide Filters' : 'Show Filters'}
            </button>
            <div className="marketplace-dropdown">
              <button className={`tab-item ${marketplaceDropdownVisible && 'active'}`} onClick={toggleMarketplaceDropdown}>
                <i className="fas fa-store"></i> Marketplace <i className={`fas ${marketplaceDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
              </button>
              {marketplaceDropdownVisible && (
                <div className="dropdown-content">
                  {Object.entries(marketplaceNames).map(([id, name]) => (
                    <div key={id} className="dropdown-item" onClick={() => handleMarketplaceSelect(id)}>
                      <img src={marketplaceImages[id]} alt={name} />
                      {name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      {activeTab === 'items' && (
        <section className="content-container">
          {filtersVisible && (
            <div className="filters">
              {Object.entries(traits).map(([trait, values]) => (
                <div key={trait} className="filter-group">
                  <label>{trait}</label>
                  <select value={selectedTraits[trait] || ''} onChange={e => handleTraitChange(trait, e.target.value)}>
                    <option value="">All</option>
                    {Array.from(values).map(value => (
                      <option key={value} value={value}>{value}</option>
                    ))}
                  </select>
                </div>
              ))}
              <div className="filter-group">
                <label>Sort By</label>
                <select value={sortOrder} onChange={e => handleSortOrderChange(e.target.value)}>
                  <option value="default">Default</option>
                  <option value="low-high">Price: Low to High</option>
                  <option value="high-low">Price: High to Low</option>
                </select>
              </div>
              <div className="filter-group checkbox-group">
                <label>
                  <input type="checkbox" checked={showActiveOnly} onChange={handleActiveFilterChange} />
                  Show Active Only
                </label>
              </div>
            </div>
          )}
          <div className="nft-cards">
            {loading ? (
              <Spinner />
            ) : filteredNfts.length ? (
              filteredNfts.map((nft, idx) => (
                <Link key={idx} to={`/collections/${collectionId}/${nft.nonce}`} className={`nft-card ${nft.metadata?.expired ? 'expired' : ''}`}>
                  <div className="nft-card-content">
                    <h3>#{nft.nonce}</h3>
                    {nft.loadingMetadata ? (
                      <Spinner />
                    ) : nft.metadata ? (
                      <>
                        <img src={nft.metadata.image} alt={nft.metadata.name} loading="lazy" />
                        <div className="nft-price">
                          {nft.metadata.price ? `${nft.metadata.price} KLV` : 'Price Not Available'}
                        </div>
                        {nft.metadata.expired && <div className="ribbon">Expired</div>}
                      </>
                    ) : (
                      <p>Metadata not available</p>
                    )}
                  </div>
                </Link>
              ))
            ) : (
              <p className="no-items-message">No items for sale</p>
            )}
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage <= 1}>Previous</button>
            <span>Page {currentPage} of {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage >= totalPages}>Next</button>
          </div>
        </section>
      )}

      {activeTab === 'activity' && (
        <section className="activity-section">
          {activeSubTab === 'sales' && (
            <>
              {activity.length === 0 ? (
                <p className="no-data">No sales yet. Go grab some!</p>
              ) : (
                activity.map((item, idx) => {
                  const { contract, sender, marketplaceId, timestamp } = item;
                  const assetId = contract.parameter.assetId;
                  const assetNumber = assetId.split('/')[1];
                  const listedPrice = contract.parameter.price / 1000000;
                  const date = new Date(timestamp * 1000);
                  const marketplaceName = marketplaceNames[marketplaceId] || marketplaceId;
                  return (
                    <Link key={idx} to={`/collections/${collectionId}/${assetNumber}`} className="activity-item-link">
                      <div className="activity-item">
                        <img src={imageUrl && imageUrl.replace(/\/\d+\.png$/, `/${assetNumber}.png`)} alt={`Asset ${assetId}`} className="asset-image" />
                        <p>
                          {date.toLocaleDateString()} {date.toLocaleTimeString()} - {assetNumber} Listed for <span className="listed-price">{listedPrice.toLocaleString('en-US')} KLV</span> by {sender === walletAddress ? 'You' : `klv1...${sender.slice(-4)}`} on <span className={`marketplace ${getMarketplaceClassName(marketplaceId)}`}>{marketplaceName}</span>
                        </p>
                      </div>
                    </Link>
                  );
                })
              )}
              <div className="pagination">
                <button onClick={handleActivityPrevPage} disabled={activityPage <= 1}>Previous</button>
                <span>Page {activityPage} of {totalActivityPages}</span>
                <button onClick={handleActivityNextPage} disabled={activityPage >= totalActivityPages}>Next</button>
              </div>
            </>
          )}
          {activeSubTab === 'listings' && (
            <div className="listing-section">
              {transactions.length === 0 ? (
                <p className="no-data">No sales, or try moving to the next page.</p>
              ) : (
                transactions.map((tx, idx) => {
                  const buyContract = tx.contract.find(c => c.typeString === 'BuyContractType');
                  const listedPrice = buyContract ? buyContract.parameter.amount / 1000000 : 'N/A';
                  const assetReceipt = tx.receipts.find(r => r.assetId && r.assetId.startsWith(`${collectionId}/`));
                  const assetId = assetReceipt ? assetReceipt.assetId : 'N/A';
                  const assetNumber = assetId.split('/')[1] || 'N/A';
                  const date = new Date(tx.timestamp * 1000);
                  const sender = tx.sender || 'N/A';
                  const marketplaceId = tx.marketplaceId || 'defaultMarketplace';
                  const marketplaceName = marketplaceNames[marketplaceId] || 'Unknown Marketplace';
                  return (
                    <Link key={idx} to={`/collections/${collectionId}/${assetNumber}`} className="activity-item-link">
                      <div className="activity-item">
                        <img src={imageUrl && imageUrl.replace(/\/\d+\.png$/, `/${assetNumber}.png`)} alt={`Asset ${assetId}`} className="asset-image" />
                        <p>
                          {date.toLocaleDateString()} {date.toLocaleTimeString()} - {assetNumber} Bought for <span className="listed-price">{listedPrice.toLocaleString('en-US')} KLV</span> by {sender === walletAddress ? 'You' : `klv1...${sender.slice(-4)}`} on <span className={`marketplace ${getMarketplaceClassName(marketplaceId)}`}>{marketplaceName}</span>
                        </p>
                      </div>
                    </Link>
                  );
                })
              )}
              <div className="pagination">
                <button onClick={handleTransactionPrevPage} disabled={transactionPage <= 1}>Previous</button>
                <span>Page {transactionPage} of {totalTransactionPages}</span>
                <button onClick={handleTransactionNextPage} disabled={transactionPage >= totalTransactionPages}>Next</button>
              </div>
            </div>
          )}
        </section>
      )}
    </div>
  );
};

const getMarketplaceClassName = (marketplaceId) => {
  switch (marketplaceId) {
    case '7c353c02770da029':
      return 'marketplace-sky-gallery';
    case 'd2a04fe890a6edda':
      return 'marketplace-nft-art-gallery';
    case 'd4f2bab340c55fde':
      return 'marketplace-klevernft';
    case '2936933ee43db509':
      return 'marketplace-minthree';
    case '417b70c0eb7a33cb':
      return 'marketplace-rare-canvas';
    case 'aa68ad853a09988c':
      return 'marketplace-xport';
    case '116056b257d9f6d5':
      return 'marketplace-kDEX';
    case '81376f526cf47730':
      return 'marketplace-world';
    default:
      return '';
  }
};

export default CollectionDetailPage;
