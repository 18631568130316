import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/HomePage.css';

const HomePage = () => {
  const [royaltiesWallet, setRoyaltiesWallet] = useState(null);
  const [kongPool, setKongPool] = useState(null);
  const [kongToBeBurned, setKongToBeBurned] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [topCollections, setTopCollections] = useState([]);

  const fetchBalances = async () => {
    setIsLoading(true);
    try {
      const [royaltiesResponse, kongPoolResponse, kongBurnResponse] = await Promise.all([
        axios.get('https://api.mainnet.klever.finance/v1.0/address/klv19rllvjlz76eph8vlmldmm5rhdyl0kzuhe5c4hp3s4kesyu4dqths8fzhf4'),
        axios.get('https://api.mainnet.klever.finance/v1.0/address/klv1nmrp37z578qmkx785yadmjw5v0a253z3de5l4sp5ldn964prn28s0jdfmv'),
        axios.get('https://api.mainnet.klever.finance/v1.0/address/klv19eumh6lc27m5sykq3764meqz56kcf85903h8s0pysqgc7xfrpy2sk0yfy4')
      ]);

      setRoyaltiesWallet((royaltiesResponse.data.data.account.balance / 1000000).toFixed(2));
      setKongPool((kongPoolResponse.data.data.account.balance / 1000000).toFixed(2));
      setKongToBeBurned((kongBurnResponse.data.data.account.assets['KONG-LGAJ'].balance / 1000).toFixed(2));
    } catch (error) {
      console.error('Failed to fetch balances:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopCollections = async () => {
    try {
      let allAssets = [];
      const totalPages = 5;

      for (let page = 1; page <= totalPages; page++) {
        const assetsResponse = await axios.get(`https://api.mainnet.klever.finance/v1.0/assets/list?page=${page}&limit=100`);
        const assetsData = assetsResponse.data.data.assets.filter(collection => collection.assetType === "NonFungible");
        allAssets = [...allAssets, ...assetsData];
      }

      const marketplaceIds = [
        'd4f2bab340c55fde',
        '7c353c02770da029',
        '2936933ee43db509',
        '417b70c0eb7a33cb',
        '116056b257d9f6d5',
        'aa68ad853a09988c',
        'd2a04fe890a6edda'
      ];

      const statsPromises = marketplaceIds.map(id =>
        axios.get(`https://api.mainnet.klever.finance/v1.0/marketplaces/${id}/stats`)
      );

      const statsResponses = await Promise.all(statsPromises);
      let combinedStatsData = [];

      statsResponses.forEach(response => {
        combinedStatsData = [...combinedStatsData, ...response.data.data.stats];
      });

      let mergedCollections = allAssets.map(asset => {
        let totalVolume = 0;
        let totalItems = 0;
        let totalOwners = 0;
        let floorPrices = [];
        let royalties = null;

        combinedStatsData.forEach(stat => {
          if (stat.assetId === asset.assetId) {
            totalVolume += stat.volumeTotal || 0;
            totalItems += stat.items || 0;
            totalOwners += stat.owners || 0;
            if (stat.floor && stat.floor > 0) {
              floorPrices.push(stat.floor);
            }
          }
        });

        const combinedFloorPrice = floorPrices.length > 0 ? Math.min(...floorPrices) : 0;

        if (asset.royalties && asset.royalties.marketPercentage) {
          royalties = (asset.royalties.marketPercentage / 100).toString();
          royalties = royalties.includes('.') ? royalties.replace(/\.00$/, '') : royalties;
        }

        return {
          ...asset,
          volumeTotal: totalVolume,
          totalItems,
          totalOwners,
          floorPrice: combinedFloorPrice,
          royalties,
          logo: asset.logo,
          name: asset.name
        };
      });

      mergedCollections = mergedCollections.sort((a, b) => b.volumeTotal - a.volumeTotal).slice(0, 10); // Fetch 10 for background

      setTopCollections(mergedCollections);
    } catch (error) {
      console.error('Error fetching top collections:', error);
    }
  };

  const handleImageError = (event) => {
    const src = event.target.src;
    if (
      src.startsWith("https://klever-mint.mypinata.cloud/ipfs/") &&
      src !== "https://klever-mint.mypinata.cloud/ipfs/QmNwWcvPmfNzPa4JQppj8mXcaeM8BA2yA5VAjVWYLzTT7a"
    ) {
      event.target.src = src.replace(
        "https://klever-mint.mypinata.cloud/ipfs/",
        "https://ipfs.io/ipfs/"
      );
    }
  };

  useEffect(() => {
    fetchBalances();
    fetchTopCollections();
    const interval = setInterval(() => {
      fetchBalances();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-container">
      <div className="hero-section">
        <div className="hero-background">
          {topCollections.map((collection, index) => (
            <img
              key={index}
              src={collection.logo}
              alt={`${collection.name} logo`}
              className={`hero-logo hero-logo-${index}`}
              onError={handleImageError}
            />
          ))}
        </div>
        <div className="hero-content">
          <h1 className="hero-title">
            Discover, Trade, and Collect <span className="highlight">NFTs</span> on Klever Chain
          </h1>
          <p className="hero-subtitle">
            Join the ultimate NFT marketplace powered by Rare Canvas.
          </p>
          <Link to="/collections" className="hero-cta">
            Explore Collections
          </Link>
        </div>
      </div>
      <div className="content-wrapper">
        <section className="top-collections-section">
          <h2 className="section-title">Top Collections</h2>
          <div className="collections-grid">
            {topCollections.length > 0 ? (
              topCollections.slice(0, 5).map((collection, index) => (
                <Link to={`/collections/${collection.assetId}`} key={index} className="collection-card">
                  <img
                    src={collection.logo}
                    alt={collection.name}
                    className="collection-image"
                    onError={handleImageError}
                  />
                  <div className="collection-details">
                    <h3 className="collection-name">{collection.name}</h3>
                    <p className="collection-volume">
                      Volume: {collection.volumeTotal ? `${collection.volumeTotal.toLocaleString()} KLV` : '0 KLV'}
                    </p>
                  </div>
                </Link>
              ))
            ) : (
              <p className="loading-text">Loading top collections...</p>
            )}
          </div>
        </section>
        <section className="stats-section">
          <h2 className="section-title">KONG Stats</h2>
          <div className="stats-grid">
            <div className="stat-card">
              <h3 className="stat-title">Royalties Wallet</h3>
              <p className="stat-value">{isLoading ? 'Loading...' : `${royaltiesWallet} KLV`}</p>
              <p className="stat-description">Distributed to FOMO APE NFT holders at 250,000 KLV.</p>
            </div>
            <div className="stat-card">
              <h3 className="stat-title">$KONG Pool</h3>
              <p className="stat-value">{isLoading ? 'Loading...' : `${kongPool} KLV`}</p>
              <p className="stat-description">Weekly distribution to stakers every Sunday.</p>
            </div>
            <div className="stat-card">
              <h3 className="stat-title">$KONG to be Burned</h3>
              <p className="stat-value">{isLoading ? 'Loading...' : `${kongToBeBurned} KONG`}</p>
              <p className="stat-description">Burned from swap fees every Sunday.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;