// src/components/Footer.js
import '../styles/Footer.css';
import React, { useContext } from 'react';
import { useWallet } from '../components/WalletContext';
import { ThemeContext } from '../components/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  const { useKdaFee, toggleKdaFee, kdaOptions, selectedKda, setSelectedKda } = useWallet();
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  return (
    <footer className="footer">
      <div className="fee-toggle">
        <label className="fee-label">
          <input type="checkbox" checked={useKdaFee} onChange={toggleKdaFee} />
          Pay Fees with KDA
        </label>
        {useKdaFee && (
          <select value={selectedKda} onChange={(e) => setSelectedKda(e.target.value)} className="kda-select">
            <option value="">Select KDA</option>
            {kdaOptions.map(kda => (
              <option key={kda} value={kda}>{kda}</option>
            ))}
          </select>
        )}
        <label className="theme-switch">
          <input type="checkbox" checked={!isDarkMode} onChange={toggleTheme} />
          <span className="slider">
            <FontAwesomeIcon icon="moon" className="icon moon" />
            <FontAwesomeIcon icon="sun" className="icon sun" />
          </span>
        </label>
      </div>
      <p className="copyright">© 2023-2025 Rare Canvas by FOMO Apes Version 2.0.2</p>
    </footer>
  );
};

export default Footer;