import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import { web, TransactionType } from '@klever/sdk-web';
import '../styles/RewardsPage.css';

// Helper functions
const canUnfreeze = (bucket, currentEpoch) => {
  return currentEpoch && bucket.stakedEpoch + 1 <= currentEpoch;
};

const canClaimRewards = (bucket, currentEpoch) => {
  return currentEpoch && bucket.stakedEpoch + 1 <= currentEpoch;
};

const canWithdraw = (bucket, currentEpoch) => {
  return currentEpoch && bucket.unstakedEpoch !== 4294967295 && bucket.unstakedEpoch + 2 <= currentEpoch;
};

const RewardsPage = () => {
  const navigate = useNavigate();
  const { walletAddress, isConnected } = useWallet();
  const [rewardsData, setRewardsData] = useState(null);
  const [validators, setValidators] = useState([]);
  const [currentEpoch, setCurrentEpoch] = useState(null);
  const [walletData, setWalletData] = useState(null);
  const [error, setError] = useState(null);
  const [showValidatorList, setShowValidatorList] = useState(false);
  const [sortOption, setSortOption] = useState('freeDelegations');
  const [statusFilter, setStatusFilter] = useState('all');
  const [bucketFilter, setBucketFilter] = useState('all');
  const [expandedBuckets, setExpandedBuckets] = useState({});
  const [expandedValidators, setExpandedValidators] = useState({});
  const [showBuckets, setShowBuckets] = useState(false);
  const [showRecommendations, setShowRecommendations] = useState(true);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [selectedBucketId, setSelectedBucketId] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayAction, setOverlayAction] = useState(null);
  const [freezeAmount, setFreezeAmount] = useState('');
  const [selectedBuckets, setSelectedBuckets] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 430);
  const [lastActionTime, setLastActionTime] = useState(null);
  const [showEpochDetails, setShowEpochDetails] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 430);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (window.kleverWeb) {
      web.initialize();
    }
  }, []);

  const fetchRewardsData = async (address) => {
    try {
      const response = await fetch(`https://api.mainnet.klever.org/v1.0/address/${address}/allowance/list?asset=KLV`);
      const data = await response.json();
      if (data.code !== 'successful' || !data.data || !data.data.result || !data.data.result.KLV) {
        throw new Error('No staking data available');
      }
      return data.data.result.KLV;
    } catch (err) {
      setError(err.message || 'Failed to load rewards data.');
      return null;
    }
  };

  const fetchValidators = async () => {
    let allValidators = [];
    let page = 1;
    const maxPages = 3; // Explicitly fetch up to 3 pages
    try {
      while (page <= maxPages) {
        const response = await fetch(`https://api.mainnet.klever.org/v1.0/validator/list?limit=100&page=${page}`);
        const data = await response.json();
        console.log(`Page ${page}:`, data); // Debug log to check API response
        if (!data.data || !data.data.validators || data.data.validators.length === 0) break;
        allValidators = [...allValidators, ...data.data.validators];
        page++;
      }
      console.log('Total validators fetched:', allValidators.length); // Debug log
      return allValidators;
    } catch (err) {
      setError('Failed to load validator data.');
      console.error('Validator fetch error:', err);
      return [];
    }
  };

  const fetchCurrentEpoch = async () => {
    try {
      const response = await fetch('https://api.mainnet.klever.org/v1.0/epoch/list?page=1&limit=1');
      const data = await response.json();
      if (data.code === 'successful' && data.pagination && data.pagination.totalRecords) {
        return data.pagination.totalRecords;
      }
    } catch (err) {
      setError('Failed to load current epoch.');
      return null;
    }
  };

  const fetchWalletData = async (address) => {
    try {
      const response = await fetch(`https://api.mainnet.klever.org/v1.0/address/${address}`);
      const data = await response.json();
      if (data.code === 'successful' && data.data && data.data.account) {
        return data.data.account;
      }
    } catch (err) {
      setError('Failed to load wallet data.');
      return null;
    }
  };

  const reloadData = useCallback(async () => {
    const address = walletAddress;
    if (address) {
      const [newRewards, newValidators, newEpoch, newWallet] = await Promise.all([
        fetchRewardsData(address),
        fetchValidators(),
        fetchCurrentEpoch(),
        fetchWalletData(address),
      ]);
      setRewardsData(newRewards);
      setValidators(newValidators);
      setCurrentEpoch(newEpoch);
      setWalletData(newWallet);
      setSelectedBuckets([]);
      setLastActionTime(Date.now());
    }
  }, [walletAddress]);

  const fetchAndUpdateIfChanged = useCallback(async () => {
    const address = walletAddress;
    if (!address || lastActionTime) return;

    const [newRewards, newValidators, newEpoch, newWallet] = await Promise.all([
      fetchRewardsData(address),
      fetchValidators(),
      fetchCurrentEpoch(),
      fetchWalletData(address),
    ]);

    const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

    if (newRewards && !isEqual(newRewards, rewardsData)) setRewardsData(newRewards);
    if (newValidators.length && !isEqual(newValidators, validators)) setValidators(newValidators);
    if (newEpoch && newEpoch !== currentEpoch) setCurrentEpoch(newEpoch);
    if (newWallet && !isEqual(newWallet, walletData)) setWalletData(newWallet);
  }, [walletAddress, rewardsData, validators, currentEpoch, walletData, lastActionTime]);

  useEffect(() => {
    const address = isConnected ? walletAddress : '';
    if (address) {
      reloadData();
      const interval = setInterval(fetchAndUpdateIfChanged, 4000);
      return () => clearInterval(interval);
    } else if (!isConnected) {
      setError('Please connect your wallet.');
    }
  }, [walletAddress, isConnected, reloadData, fetchAndUpdateIfChanged]);

  const adjustValue = (value, precision = 6) => {
    return (value / Math.pow(10, precision)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleString('en-US', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
  };

  const shortenAddress = (address) => {
    return address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setTransactionStatus('Copied to clipboard!');
      setTimeout(() => setTransactionStatus(''), 2000);
    }).catch(() => {
      setTransactionStatus('Failed to copy!');
      setTimeout(() => setTransactionStatus(''), 2000);
    });
  };

  const openOverlay = (action, initialStatus = 'Pending', bucket = null) => {
    setOverlayAction({ action, bucket });
    setTransactionStatus(initialStatus);
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setOverlayAction(null);
    setTransactionStatus('');
    setFreezeAmount('');
    setSelectedBucketId('');
  };

  const handleFreeze = async () => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    const amount = Number(freezeAmount) * Math.pow(10, 6);
    if (!freezeAmount || isNaN(freezeAmount) || amount < 1000 * Math.pow(10, 6)) {
      setTransactionStatus('Error: Minimum freeze amount is 1000 KLV.');
      return;
    }
    setTransactionStatus('Processing...');
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const payload = { amount, kda: 'KLV' };
      const unsignedTx = await window.kleverWeb.buildTransaction([{ payload, type: TransactionType.Freeze }]);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response[0]?.status === 'success' || response[0]?.error === undefined) {
        setTransactionStatus(`Success: Frozen ${adjustValue(amount)} KLV`);
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response[0]?.message || 'Transaction failed');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const handleUnfreeze = async (bucket) => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    openOverlay('Unfreeze', 'Pending', bucket);
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const payload = { bucketId: bucket.id, kda: 'KLV' };
      const unsignedTx = await window.kleverWeb.buildTransaction([{ payload, type: TransactionType.Unfreeze }]);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response[0]?.status === 'success' || response[0]?.error === undefined) {
        setTransactionStatus(`Success: Unfrozen ${adjustValue(bucket.balance)} KLV from Bucket #${filteredBuckets.findIndex(b => b.id === bucket.id) + 1}`);
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response[0]?.message || 'Transaction failed');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const handleMultiUnfreeze = async () => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    if (selectedBuckets.length === 0) {
      setError('Please select at least one bucket to unfreeze.');
      return;
    }
    openOverlay('Multi-Unfreeze', 'Pending');
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const transactions = selectedBuckets.slice(0, 20).map(bucket => ({
        payload: { bucketId: bucket.id, kda: 'KLV' },
        type: TransactionType.Unfreeze,
      }));
      const unsignedTx = await window.kleverWeb.buildTransaction(transactions);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response.every(r => r.status === 'success' || r.error === undefined)) {
        setTransactionStatus(`Success: Unfrozen ${selectedBuckets.length} buckets`);
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response.map(r => r.message || 'Transaction failed').join(', '));
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const handleWithdraw = async () => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    openOverlay('Withdraw', 'Pending');
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const payload = { kda: 'KLV', withdrawType: 0 };
      const unsignedTx = await window.kleverWeb.buildTransaction([{ payload, type: TransactionType.Withdraw }]);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response[0]?.status === 'success' || response[0]?.error === undefined) {
        setTransactionStatus('Success: Withdrawn all available KLV');
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response[0]?.message || 'Transaction failed');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const handleUndelegate = async (bucket) => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    openOverlay('Undelegate', 'Pending', bucket);
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const payload = { bucketId: bucket.id };
      const unsignedTx = await window.kleverWeb.buildTransaction([{ payload, type: TransactionType.Undelegate }]);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response[0]?.status === 'success' || response[0]?.error === undefined) {
        setTransactionStatus(`Success: Undelegated Bucket #${filteredBuckets.findIndex(b => b.id === bucket.id) + 1}`);
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response[0]?.message || 'Transaction failed');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const handleMultiUndelegate = async () => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    if (selectedBuckets.length === 0) {
      setError('Please select at least one bucket to undelegate.');
      return;
    }
    openOverlay('Multi-Undelegate', 'Pending');
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const transactions = selectedBuckets.slice(0, 20).map(bucket => ({
        payload: { bucketId: bucket.id },
        type: TransactionType.Undelegate,
      }));
      const unsignedTx = await window.kleverWeb.buildTransaction(transactions);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response.every(r => r.status === 'success' || r.error === undefined)) {
        setTransactionStatus(`Success: Undelegated ${selectedBuckets.length} buckets`);
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response.map(r => r.message || 'Transaction failed').join(', '));
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const handleDelegate = async (validator, bucketId) => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    if (!bucketId) {
      setError('Please select a bucket to delegate.');
      return;
    }
    openOverlay('Delegate', 'Pending');
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const payload = { receiver: validator.ownerAddress, bucketId };
      const unsignedTx = await window.kleverWeb.buildTransaction([{ payload, type: TransactionType.Delegate }]);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response[0]?.status === 'success' || response[0]?.error === undefined) {
        setTransactionStatus('Success: Delegate completed');
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response[0]?.message || 'Transaction failed');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const handleRecommendDelegate = async (bucket, validator) => {
    await handleDelegate(validator, bucket.id);
  };

  const handleClaim = async (type) => {
    if (!isConnected) {
      setError('Please connect your wallet.');
      return;
    }
    openOverlay(`Claim ${type}`, 'Pending');
    try {
      if (!window.kleverWeb) {
        throw new Error('Klever wallet extension not detected.');
      }
      const claimType = type === 'Allowance' ? 1 : 0;
      const payload = { claimType, id: 'KLV' };
      const unsignedTx = await window.kleverWeb.buildTransaction([{ payload, type: TransactionType.Claim }]);
      const signedTx = await window.kleverWeb.signTransaction(unsignedTx);
      setTransactionStatus('Broadcasting...');
      const response = await window.kleverWeb.broadcastTransactions([signedTx]);
      if (response[0]?.status === 'success' || response[0]?.error === undefined) {
        setTransactionStatus(`Success: ${type} claimed (${adjustValue(type === 'Allowance' ? rewardsData.allowance : rewardsData.stakingRewards)} KLV)`);
        await new Promise(resolve => setTimeout(resolve, 4000));
        await reloadData();
        setTimeout(closeOverlay, 2000);
      } else {
        throw new Error(response[0]?.message || 'Transaction failed');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setTransactionStatus(`Error: ${err.message}`);
      setTimeout(closeOverlay, 2000);
    }
  };

  const toggleBucketSelection = (bucket) => {
    setSelectedBuckets(prev =>
      prev.some(b => b.id === bucket.id)
        ? prev.filter(b => b.id !== bucket.id)
        : [...prev, bucket]
    );
  };

  const getValidatorInfo = (delegationAddress) => {
    return validators.find(v => v.ownerAddress === delegationAddress || v.rewardsAddress === delegationAddress) || null;
  };

  const calculateProfitability = (validator) => {
    const baseRewardPerEpoch = 5000000000; // 5M KLV per epoch
    const commissionRate = validator.commission / 10000;
    const netReward = baseRewardPerEpoch * (1 - commissionRate);
    const totalStake = validator.totalStake || 1;
    const profitPerKLV = netReward / totalStake;
    return (profitPerKLV * 1000).toFixed(5); // Profit per 1000 KLV
  };

  const sortedValidators = [...validators]
    .filter(v => v.canDelegate === true)
    .filter(v => v.selfStake >= 1500000000000)
    .filter(v => ((v.maxDelegation || 0) - (v.totalStake || 0)) >= 1000 * Math.pow(10, 6))
    .filter(v => statusFilter === 'all' || v.list.toLowerCase() === statusFilter.toLowerCase())
    .filter(v => sortOption !== 'profitability' || !['jailed', 'waiting', 'inactive'].includes(v.list.toLowerCase()))
    .sort((a, b) => {
      if (sortOption === 'freeDelegations') return ((b.maxDelegation || 0) - (b.totalStake || 0)) - ((a.maxDelegation || 0) - (a.totalStake || 0));
      if (sortOption === 'profitability') return parseFloat(calculateProfitability(b)) - parseFloat(calculateProfitability(a));
      return 0;
    });

  const filteredBuckets = rewardsData?.buckets?.filter(bucket => {
    const validator = getValidatorInfo(bucket.delegation);
    switch (bucketFilter) {
      case 'undelegated': return !validator;
      case 'inactive': return validator && validator.list.toLowerCase() === 'inactive';
      case 'jailed': return validator && validator.list.toLowerCase() === 'jailed';
      case 'elected': return validator && validator.list.toLowerCase() === 'elected';
      case 'waiting': return validator && validator.list.toLowerCase() === 'waiting';
      case 'eligible': return validator && validator.list.toLowerCase() === 'eligible';
      case 'withdrawable': return canWithdraw(bucket, currentEpoch);
      case 'all':
      default: return true;
    }
  }) || [];

  const undelegatedBuckets = filteredBuckets.filter(bucket => !getValidatorInfo(bucket.delegation));

  const getRecommendations = () => {
    if (!rewardsData || !validators.length) return { delegate: [], switch: [] };
    const delegateRecommendations = [];
    const switchRecommendations = [];
    const profitableValidators = sortedValidators
      .filter(v => ['elected', 'eligible'].includes(v.list.toLowerCase()))
      .sort((a, b) => parseFloat(calculateProfitability(b)) - parseFloat(calculateProfitability(a)));

    filteredBuckets.forEach((bucket, index) => {
      const validator = getValidatorInfo(bucket.delegation);
      const bucketSizeKLV = bucket.balance / Math.pow(10, 6);

      if (!validator) {
        const suitableValidator = profitableValidators.find(v => 
          (v.maxDelegation || 0) - (v.totalStake || 0) >= bucket.balance
        );
        if (suitableValidator) {
          const profitPer1000 = parseFloat(calculateProfitability(suitableValidator));
          const profitGain = (bucketSizeKLV / 1000) * profitPer1000;
          delegateRecommendations.push({ 
            bucket, 
            index, 
            validator: suitableValidator,
            profitGain
          });
        }
      } else if (validator && ['elected', 'eligible'].includes(validator.list.toLowerCase())) {
        const currentProfitPer1000 = parseFloat(calculateProfitability(validator));
        const betterValidator = profitableValidators.find(v => 
          parseFloat(calculateProfitability(v)) > currentProfitPer1000 &&
          (v.maxDelegation || 0) - (v.totalStake || 0) >= bucket.balance &&
          v.ownerAddress !== validator.ownerAddress
        );
        if (betterValidator) {
          const newProfitPer1000 = parseFloat(calculateProfitability(betterValidator));
          const profitDiffPer1000 = newProfitPer1000 - currentProfitPer1000;
          const profitGain = (bucketSizeKLV / 1000) * profitDiffPer1000;
          switchRecommendations.push({ 
            bucket, 
            index, 
            fromValidator: validator, 
            toValidator: betterValidator,
            profitGain
          });
        }
      }
    });

    return { delegate: delegateRecommendations, switch: switchRecommendations };
  };

  const recommendations = getRecommendations();
  const toggleBucket = (index) => setExpandedBuckets(prev => ({ ...prev, [index]: !prev[index] }));
  const toggleValidator = (index) => setExpandedValidators(prev => ({ ...prev, [index]: !prev[index] }));
  const toggleBucketsList = () => setShowBuckets(prev => !prev);
  const toggleRecommendationsList = () => setShowRecommendations(prev => !prev);
  const toggleEpochDetails = () => setShowEpochDetails(prev => !prev);
  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const canClaim = rewardsData && currentEpoch && rewardsData.buckets?.some(b => canClaimRewards(b, currentEpoch));

  const remainingEpochsToUnfreeze = (bucket) => {
    return bucket.stakedEpoch + 1 - currentEpoch;
  };

  const remainingEpochsToWithdraw = (bucket) => {
    return bucket.unstakedEpoch !== 4294967295 ? bucket.unstakedEpoch + 2 - currentEpoch : null;
  };

  const totalStaked = rewardsData?.buckets?.reduce((sum, bucket) => sum + (bucket.balance || 0), 0) || 0;
  const totalBalance = (walletData?.balance || 0) + (walletData?.frozenBalance || 0) + (walletData?.unfrozenBalance || 0);
  const stakedPercentage = totalBalance > 0 ? (totalStaked / totalBalance) * 100 : 0;

  return (
    <div className="rewards-rewards-container">
      <div className="rewards-rewards-header">
        <h1 className="rewards-rewards-title">KLV Rewards</h1>
      </div>
      <div className="rewards-rewards-content">
        {!isConnected && <div className="rewards-error-card">{error || 'Please connect your wallet.'}</div>}
        {isConnected && walletAddress && (
          <div className="rewards-wallet-info">
            <span>{isMobile ? shortenAddress(walletAddress) : walletAddress}</span>
            <button className="rewards-copy-button" onClick={() => copyToClipboard(walletAddress)}>
              <i className="fas fa-copy"></i>
            </button>
          </div>
        )}
        {showOverlay && (
          <div className="rewards-overlay">
            <div className="rewards-overlay-content">
              <button className="rewards-close-button" onClick={closeOverlay}>
                <i className="fas fa-times"></i>
              </button>
              <h3>{overlayAction.action}</h3>
              {overlayAction.action === 'Freeze' && (
                <>
                  <p>Available Balance: {adjustValue(walletData?.balance || 0)} KLV</p>
                  <input
                    type="number"
                    placeholder="Amount to freeze"
                    value={freezeAmount}
                    onChange={(e) => setFreezeAmount(e.target.value)}
                    min="0"
                    step="0.0001"
                    className="rewards-input"
                  />
                  <button className="rewards-button rewards-action-button" onClick={handleFreeze}>
                    <i className="fas fa-snowflake"></i> Confirm Freeze
                  </button>
                </>
              )}
              {['Unfreeze', 'Undelegate'].includes(overlayAction.action) && overlayAction.bucket && (
                <p>
                  Bucket #{filteredBuckets.findIndex(b => b.id === overlayAction.bucket.id) + 1}: {adjustValue(overlayAction.bucket.balance)} KLV<br />
                  Staked Epoch: {overlayAction.bucket.stakedEpoch}, Unstaked Epoch: {overlayAction.bucket.unstakedEpoch === 4294967295 ? 'N/A' : overlayAction.bucket.unstakedEpoch}
                </p>
              )}
              {overlayAction.action === 'Withdraw' && (
                <p>Withdrawing all available unfrozen KLV buckets</p>
              )}
              {overlayAction.action.startsWith('Claim') && (
                <p>Claiming {overlayAction.action.split(' ')[1]}: {adjustValue(overlayAction.action === 'Claim Allowance' ? rewardsData.allowance : rewardsData.stakingRewards)} KLV</p>
              )}
              {overlayAction.action.startsWith('Multi-') && (
                <p>Processing {selectedBuckets.length} buckets</p>
              )}
              <p>Status: {transactionStatus}</p>
            </div>
          </div>
        )}
        {rewardsData ? (
          <>
            <div className="rewards-rewards-section">
              <h2 className="rewards-section-title">Rewards Overview</h2>
              <div className="rewards-reward-card">
                {walletData && (
                  <div className="rewards-reward-row">
                    <span className="rewards-reward-label">Available KLV</span>
                    <span className="rewards-reward-value">{adjustValue(walletData.balance)}</span>
                    <button className="rewards-button rewards-action-button" onClick={() => openOverlay('Freeze')}>
                      <i className="fas fa-snowflake"></i> Freeze
                    </button>
                  </div>
                )}
                {walletData && (
                  <div className="rewards-reward-row">
                    <span className="rewards-reward-label">Frozen KLV</span>
                    <span className="rewards-reward-value">{adjustValue(walletData.frozenBalance)}</span>
                    <div className="rewards-progress-bar">
                      <div className="rewards-progress" style={{ width: `${stakedPercentage}%` }}></div>
                    </div>
                  </div>
                )}
                {walletData && (
                  <div className="rewards-reward-row">
                    <span className="rewards-reward-label">Unfrozen KLV</span>
                    <span className="rewards-reward-value">{adjustValue(walletData.unfrozenBalance)}</span>
                  </div>
                )}
                <div className="rewards-reward-row">
                  <span className="rewards-reward-label">Allowance</span>
                  <span className="rewards-reward-value">{adjustValue(rewardsData.allowance)}</span>
                  <button
                    className="rewards-button rewards-action-button"
                    onClick={() => handleClaim('Allowance')}
                    disabled={!canClaim}
                  >
                    <i className="fas fa-hand-holding-usd"></i> Claim
                  </button>
                </div>
                <div className="rewards-reward-row">
                  <span className="rewards-reward-label">Staking Rewards</span>
                  <span className="rewards-reward-value">{adjustValue(rewardsData.stakingRewards)}</span>
                  <button
                    className="rewards-button rewards-action-button"
                    onClick={() => handleClaim('Staking Rewards')}
                    disabled={!canClaim}
                  >
                    <i className="fas fa-hand-holding-usd"></i> Claim
                  </button>
                </div>
                <div className="rewards-reward-row">
                  <span className="rewards-reward-label" onClick={toggleEpochDetails}>Current Epoch</span>
                  <span className="rewards-reward-value">{currentEpoch || 'Loading...'}</span>
                  {showEpochDetails && (
                    <div className="rewards-epoch-details">
                      <p>Details: Mock Start Time, Mock End Time (API integration needed)</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(recommendations.delegate.length > 0 || recommendations.switch.length > 0) && (
              <div className="rewards-recommendations-section">
                <div className="rewards-card-header" onClick={toggleRecommendationsList}>
                  <h2 className="rewards-section-title">Recommendations ({recommendations.delegate.length + recommendations.switch.length})</h2>
                  <span className="rewards-expand-icon">{showRecommendations ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</span>
                </div>
                {showRecommendations && (
                  <div className="rewards-recommendation-card">
                    {recommendations.delegate.length > 0 && (
                      <div className="rewards-recommendation-group">
                        <h3>Delegate Undelegated Buckets</h3>
                        <div className="rewards-recommendation-table">
                          <div className="rewards-recommendation-table-header">
                            <span>Bucket</span>
                            <span>To</span>
                            <span>Gain</span>
                            <span>Action</span>
                          </div>
                          {recommendations.delegate.map(({ bucket, index, validator, profitGain }, i) => (
                            <div
                              key={index}
                              className={`rewards-recommendation-table-row ${expandedRows[index] ? 'expanded' : ''}`}
                            >
                              <div className="rewards-row-summary" onClick={() => toggleRow(index)}>
                                <span>#{index + 1} ({adjustValue(bucket.balance)} KLV)</span>
                                <span className="rewards-validator-name">
                                  <span className="rewards-validator-name-wrapper">
                                    <span
                                      className="rewards-validator-name-text"
                                      data-full={validator.name || 'Unnamed'}
                                    >
                                      {validator.name || 'Unnamed'}
                                    </span>
                                    <span
                                      className={`rewards-validator-status rewards-status-${validator.list.toLowerCase()}`}
                                    >
                                      {validator.list}
                                    </span>
                                  </span>
                                </span>
                                <span className="rewards-profit-gain">{profitGain.toFixed(2)} KLV/epoch</span>
                                <button
                                  className="rewards-action-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRecommendDelegate(bucket, validator);
                                  }}
                                >
                                  Delegate
                                </button>
                              </div>
                              {expandedRows[index] && (
                                <div className="rewards-row-details">
                                  <p><strong>Bucket:</strong> #{index + 1} ({adjustValue(bucket.balance)} KLV)</p>
                                  <p>
                                    <strong>To:</strong> {validator.name || 'Unnamed'}{' '}
                                    <span
                                      className={`rewards-validator-status rewards-status-${validator.list.toLowerCase()}`}
                                    >
                                      {validator.list}
                                    </span>
                                  </p>
                                  <p><strong>Gain:</strong> {profitGain.toFixed(2)} KLV/epoch</p>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {recommendations.switch.length > 0 && (
                      <div className="rewards-recommendation-group">
                        <h3>Switch for Higher Yield</h3>
                        <div className="rewards-recommendation-table">
                          <div className="rewards-recommendation-table-header">
                            <span>Bucket</span>
                            <span>From</span>
                            <span>To</span>
                            <span>Extra Gain</span>
                            <span>Action</span>
                          </div>
                          {recommendations.switch.map(({ bucket, index, fromValidator, toValidator, profitGain }, i) => (
                            <div
                              key={index}
                              className={`rewards-recommendation-table-row ${expandedRows[index] ? 'expanded' : ''}`}
                            >
                              <div className="rewards-row-summary" onClick={() => toggleRow(index)}>
                                <span>#{index + 1} ({adjustValue(bucket.balance)} KLV)</span>
                                <span className="rewards-validator-name">
                                  <span className="rewards-validator-name-wrapper">
                                    <span
                                      className="rewards-validator-name-text"
                                      data-full={fromValidator.name || 'Unnamed'}
                                    >
                                      {fromValidator.name || 'Unnamed'}
                                    </span>
                                    <span
                                      className={`rewards-validator-status rewards-status-${fromValidator.list.toLowerCase()}`}
                                    >
                                      {fromValidator.list}
                                    </span>
                                  </span>
                                </span>
                                <span className="rewards-validator-name">
                                  <span className="rewards-validator-name-wrapper">
                                    <span
                                      className="rewards-validator-name-text"
                                      data-full={toValidator.name || 'Unnamed'}
                                    >
                                      {toValidator.name || 'Unnamed'}
                                    </span>
                                    <span
                                      className={`rewards-validator-status rewards-status-${toValidator.list.toLowerCase()}`}
                                    >
                                      {toValidator.list}
                                    </span>
                                  </span>
                                </span>
                                <span className="rewards-profit-gain">{profitGain.toFixed(2)} KLV/epoch</span>
                                <button
                                  className="rewards-action-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRecommendDelegate(bucket, toValidator);
                                  }}
                                >
                                  Switch
                                </button>
                              </div>
                              {expandedRows[index] && (
                                <div className="rewards-row-details">
                                  <p><strong>Bucket:</strong> #{index + 1} ({adjustValue(bucket.balance)} KLV)</p>
                                  <p>
                                    <strong>From:</strong> {fromValidator.name || 'Unnamed'}{' '}
                                    <span
                                      className={`rewards-validator-status rewards-status-${fromValidator.list.toLowerCase()}`}
                                    >
                                      {fromValidator.list}
                                    </span>
                                  </p>
                                  <p>
                                    <strong>To:</strong> {toValidator.name || 'Unnamed'}{' '}
                                    <span
                                      className={`rewards-validator-status rewards-status-${toValidator.list.toLowerCase()}`}
                                    >
                                      {toValidator.list}
                                    </span>
                                  </p>
                                  <p><strong>Extra Gain:</strong> {profitGain.toFixed(2)} KLV/epoch</p>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="rewards-buckets-section">
  <div className="rewards-card-header" onClick={toggleBucketsList}>
    <h2 className="rewards-section-title">Buckets ({filteredBuckets.length})</h2>
    <span className="rewards-expand-icon">{showBuckets ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</span>
  </div>
  {showBuckets && (
    <>
      <div className="rewards-bucket-filter">
        <label>Filter:</label>
        <select value={bucketFilter} onChange={(e) => setBucketFilter(e.target.value)}>
          <option value="all">All</option>
          <option value="undelegated">Undelegated</option>
          <option value="inactive">Inactive</option>
          <option value="jailed">Jailed</option>
          <option value="elected">Elected</option>
          <option value="waiting">Waiting</option>
          <option value="eligible">Eligible</option>
          <option value="withdrawable">Withdrawable</option>
        </select>
        {filteredBuckets.length > 0 && (
          <div className="rewards-multi-actions">
            <button
              className="rewards-button rewards-action-button"
              onClick={handleMultiUnfreeze}
              disabled={selectedBuckets.length === 0 || !selectedBuckets.every(b => canUnfreeze(b, currentEpoch))}
            >
              Unfreeze Selected ({selectedBuckets.length})
            </button>
            <button
              className="rewards-button rewards-action-button"
              onClick={handleMultiUndelegate}
              disabled={selectedBuckets.length === 0 || selectedBuckets.every(b => !getValidatorInfo(b.delegation))}
            >
              Undelegate Selected ({selectedBuckets.length})
            </button>
            <button
              className="rewards-button rewards-action-button"
              onClick={handleWithdraw}
              disabled={selectedBuckets.length === 0 || !selectedBuckets.some(b => canWithdraw(b, currentEpoch))}
            >
              Withdraw All
            </button>
          </div>
        )}
      </div>
      {/* New Legend Component */}
      <div className="rewards-bucket-legend">
        <h3>Bucket Status</h3>
        <div className="rewards-legend-items">
          <div className="rewards-legend-item">
            <span className="rewards-legend-color" style={{ backgroundColor: 'var(--wp-warning)' }}></span>
            <span>Undelegated</span>
          </div>
          <div className="rewards-legend-item">
            <span className="rewards-legend-color" style={{ backgroundColor: '#ff5555' }}></span>
            <span>Inactive</span>
          </div>
          <div className="rewards-legend-item">
            <span className="rewards-legend-color" style={{ backgroundColor: '#ff00ff' }}></span>
            <span>Jailed</span>
          </div>
          <div className="rewards-legend-item">
            <span className="rewards-legend-color" style={{ backgroundColor: 'var(--wp-success)' }}></span>
            <span>Elected</span>
          </div>
          <div className="rewards-legend-item">
            <span className="rewards-legend-color" style={{ backgroundColor: '#ffa500' }}></span>
            <span>Waiting</span>
          </div>
          <div className="rewards-legend-item">
            <span className="rewards-legend-color" style={{ backgroundColor: 'var(--wp-warning)' }}></span>
            <span>Eligible</span>
          </div>
          <div className="rewards-legend-item">
            <span className="rewards-legend-color" style={{ backgroundColor: 'var(--wp-progress-bar)' }}></span>
            <span>Withdrawable</span>
          </div>
        </div>
      </div>
      {filteredBuckets.length > 0 ? (
        <div className="rewards-buckets-stack">
          {filteredBuckets.map((bucket, index) => {
            const validator = getValidatorInfo(bucket.delegation);
            const isExpanded = expandedBuckets[index] || false;
            const isUndelegated = !validator;
            const isInactive = validator && validator.list.toLowerCase() === 'inactive';
            const isJailed = validator && validator.list.toLowerCase() === 'jailed';
            return (
              <div key={index} className={`rewards-bucket-card ${isUndelegated ? 'rewards-undelegated' : ''} ${isInactive ? 'rewards-inactive' : ''} ${isJailed ? 'rewards-jailed' : ''}`}>
                <div className="rewards-bucket-header">
                  <input
                    type="checkbox"
                    checked={selectedBuckets.some(b => b.id === bucket.id)}
                    onChange={() => toggleBucketSelection(bucket)}
                  />
                  <div className="rewards-card-header" onClick={() => toggleBucket(index)}>
                    <span className="rewards-card-id">#{index + 1}</span>
                    <span className="rewards-expand-icon">{isExpanded ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</span>
                  </div>
                </div>
                {isExpanded ? (
                  <div className="rewards-bucket-details">
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Amount</span>
                      <span className="rewards-info-value">{adjustValue(bucket.balance)}</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Validator</span>
                      <span className="rewards-info-value">{validator ? `${validator.name || 'Unnamed'} (${validator.list})` : 'Undelegated'}</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Staked At</span>
                      <span className="rewards-info-value">{formatTimestamp(bucket.stakeAt)}</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Staked Epoch</span>
                      <span className="rewards-info-value">{bucket.stakedEpoch}</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Unstaked Epoch</span>
                      <span className="rewards-info-value">{bucket.unstakedEpoch === 4294967295 ? 'N/A' : bucket.unstakedEpoch}</span>
                    </div>
                    {validator && (
                      <div className="rewards-info-row">
                        <span className="rewards-info-label">Commission</span>
                        <span className="rewards-info-value">{(validator.commission / 100).toFixed(2)}%</span>
                      </div>
                    )}
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Unfreeze in</span>
                      <span className="rewards-info-value">{remainingEpochsToUnfreeze(bucket) > 0 ? `${remainingEpochsToUnfreeze(bucket)} epochs` : 'Now'}</span>
                    </div>
                    {bucket.unstakedEpoch !== 4294967295 && (
                      <div className="rewards-info-row">
                        <span className="rewards-info-label">Withdraw in</span>
                        <span className="rewards-info-value">{remainingEpochsToWithdraw(bucket) > 0 ? `${remainingEpochsToWithdraw(bucket)} epochs` : 'Now'}</span>
                      </div>
                    )}
                    <div className="rewards-button-group">
                      <button
                        className="rewards-button rewards-action-button"
                        onClick={() => handleUnfreeze(bucket)}
                        disabled={!canUnfreeze(bucket, currentEpoch)}
                      >
                        Unfreeze
                      </button>
                      {!isUndelegated && (
                        <button className="rewards-button rewards-action-button" onClick={() => handleUndelegate(bucket)}>
                          Undelegate
                        </button>
                      )}
                      {bucket.unstakedEpoch !== 4294967295 && (
                        <button
                          className="rewards-button rewards-action-button"
                          onClick={() => handleWithdraw()}
                          disabled={!canWithdraw(bucket, currentEpoch)}
                        >
                          Withdraw
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="rewards-info-row">
                    <span className="rewards-info-label">Amount</span>
                    <span className="rewards-info-value">{adjustValue(bucket.balance)}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="rewards-bucket-card rewards-no-buckets">No buckets match the filter.</div>
      )}
    </>
  )}
</div>
            <div className="rewards-validators-section">
  <div className="rewards-card-header" onClick={() => setShowValidatorList(!showValidatorList)}>
    <h2 className="rewards-section-title">Validators ({sortedValidators.length})</h2>
    <span className="rewards-expand-icon">{showValidatorList ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</span>
  </div>
  {showValidatorList && (
    <>
      <div className="rewards-validator-toggle">
        <div className="rewards-sort-options">
          <label>Sort:</label>
          <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
            <option value="freeDelegations">Free</option>
            <option value="profitability">Profit</option>
            <option value="commission">Commission</option>
            <option value="totalStake">Total Staked</option>
          </select>
        </div>
        <div className="rewards-filter-options">
          <label>Status:</label>
          <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="all">All</option>
            <option value="eligible">Eligible</option>
            <option value="elected">Elected</option>
            <option value="waiting">Waiting</option>
          </select>
        </div>
        <input
          type="text"
          placeholder="Search by name..."
          onChange={(e) => {
            const searchTerm = e.target.value.toLowerCase();
            setValidators(prev => prev.filter(v => v.name?.toLowerCase().includes(searchTerm)));
          }}
          className="rewards-search-input"
        />
      </div>
      {sortedValidators.length > 0 ? (
        <div className="rewards-validators-stack">
          {sortedValidators.map((validator, index) => {
            const isExpanded = expandedValidators[index] || false;
            const profitPer1000KLV = calculateProfitability(validator);
            const profitPercentile = sortedValidators.length > 0
              ? Math.floor((sortedValidators.filter(v => parseFloat(calculateProfitability(v)) > profitPer1000KLV).length / sortedValidators.length) * 100)
              : 50;
            const profitColor = profitPercentile <= 25 ? 'var(--wp-success)' : profitPercentile <= 75 ? 'var(--wp-warning)' : '#ff5555';
            const bestBucket = undelegatedBuckets.reduce((max, b) => (b.balance > max.balance ? b : max), undelegatedBuckets[0] || { balance: 0 });

            return (
              <div key={index} className="rewards-validator-card">
                <div className="rewards-card-header" onClick={() => toggleValidator(index)}>
                  <span className="rewards-card-id">#{index + 1}</span>
                  <span className="rewards-expand-icon">{isExpanded ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</span>
                </div>
                {isExpanded ? (
                  <div className="rewards-validator-details">
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Name</span>
                      <span className="rewards-info-value">{validator.name || 'Unnamed'}</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Status</span>
                      <span className={`rewards-info-value rewards-validator-status rewards-status-${validator.list.toLowerCase()}`}>
                        {validator.list} <i className={`fas fa-circle ${validator.list.toLowerCase()}`}></i>
                      </span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Total Staked</span>
                      <span className="rewards-info-value">{adjustValue(validator.totalStake || 0)}</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Free</span>
                      <span className="rewards-info-value">{adjustValue((validator.maxDelegation || 0) - (validator.totalStake || 0))}</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Commission</span>
                      <span className="rewards-info-value">{(validator.commission / 100).toFixed(2)}%</span>
                    </div>
                    <div className="rewards-info-row">
                      <span className="rewards-info-label">Yield/1000 KLV</span>
                      <span className="rewards-info-value" style={{ color: profitColor }}>
                        {profitPer1000KLV} <span className="rewards-profit-indicator" style={{ background: profitColor }}></span>
                      </span>
                    </div>
                    {validator.logo && (
                      <div className="rewards-info-row">
                        <span className="rewards-info-label">Logo</span>
                        <img src={validator.logo} alt={validator.name} className="rewards-validator-logo" />
                      </div>
                    )}
                    <div className="rewards-button-group">
                      <select
                        value={selectedBucketId}
                        onChange={(e) => setSelectedBucketId(e.target.value)}
                        className="rewards-select"
                      >
                        <option value="">Select a bucket</option>
                        {undelegatedBuckets.map((bucket, idx) => (
                          <option key={idx} value={bucket.id}>
                            Bucket #{idx + 1} ({adjustValue(bucket.balance)} KLV)
                          </option>
                        ))}
                        {bestBucket && bestBucket.balance > 0 && (
                          <option value={bestBucket.id}>Best: #{undelegatedBuckets.findIndex(b => b.id === bestBucket.id) + 1} ({adjustValue(bestBucket.balance)} KLV)</option>
                        )}
                      </select>
                      <button
                        className="rewards-button rewards-action-button"
                        onClick={() => handleDelegate(validator, selectedBucketId)}
                        disabled={!selectedBucketId}
                      >
                        Delegate
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="rewards-info-row">
                    <span className="rewards-info-label">Name</span>
                    <span className="rewards-info-value">{validator.name || 'Unnamed'}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="rewards-validator-card rewards-no-validators">No validators available.</div>
      )}
    </>
  )}
</div>
          </>
        ) : (
          <div className="rewards-loading-card">Loading...</div>
        )}
      </div>
    </div>
  );
};

export default RewardsPage;