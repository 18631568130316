import React, { useState, useEffect, useRef } from 'react';
import { web, TransactionType } from '@klever/sdk-web';
import { useWallet } from '../components/WalletContext';
import { useNavigate } from 'react-router-dom';
import '../styles/ChatComponent.css';

const ChatComponent = () => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const chatHistoryRef = useRef(null);
  const { walletAddress, useKdaFee, selectedKda } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    fetchChatHistory();
    const fetchInterval = setInterval(fetchChatHistory, 4000);
    return () => clearInterval(fetchInterval);
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleMessageClick = (event) => {
    if (event.target.tagName === 'A' && event.target.getAttribute('href').startsWith('/')) {
      event.preventDefault();
      const path = event.target.getAttribute('href');
      navigate(path);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const fetchChatHistory = async () => {
    const url = "https://api.mainnet.klever.finance/v1.0/transaction/list?limit=100&page=1&toAddress=klv1mdj88jywthkuu6qn3ckg75t096lgqsyshajdlh2t5nvqeskswk6qpulvql";
    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.data && data.data.transactions) {
        const history = data.data.transactions.map((tx) => {
          let message = "";
          if (tx.data && tx.data.length > 0) {
            if (Array.isArray(tx.data) && typeof tx.data[0] === "string") {
              message = decodeMessage(tx.data[0]);
            }
          }
          return {
            sender: tx.sender,
            message,
            timestamp: tx.timestamp,
          };
        });
        setChatHistory(history.reverse());
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const encodeMessage = (message) => {
    const encodedMessage = btoa(unescape(encodeURIComponent(message)));
    return encodedMessage;
  };

  const decodeMessage = (encodedMessage) => {
    try {
      const decodedMessage = decodeURIComponent(escape(atob(encodedMessage)));
      return linkify(decodedMessage);
    } catch (e) {
      const hexDecodedMessage = hexToString(encodedMessage);
      return linkify(hexDecodedMessage);
    }
  };

  const linkify = (text) => {
    const allowedDomain = "https://rarecanvas.art/collections";
    const urlRegex = /(\bhttps?:\/\/\S*rarecanvas\.art\/collections\/[^\s]+[\w\/])/gi;
    return text.replace(urlRegex, (url) => {
      if (url.startsWith(allowedDomain)) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      }
      return url;
    });
  };

  const hexToString = (hex) => {
    let string = '';
    for (let i = 0; i < hex.length; i += 2) {
      string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return string;
  };

  const handleSendMessage = async () => {
    const payload = {
      amount: 1,
      receiver: "klv1mdj88jywthkuu6qn3ckg75t096lgqsyshajdlh2t5nvqeskswk6qpulvql",
      kda: "KLV",
    };

    const encodedMessage = encodeMessage(message);

    let txOptions = undefined;
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }

    try {
      const unsignedTx = await web.buildTransaction([
        {
          payload,
          type: TransactionType.Transfer,
        },
      ], [encodedMessage], txOptions);

      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setMessage('');
    } catch (error) {
      console.error(error);
    }
  };

  const shortenAddress = (address) => {
    if (!address || address.length < 10) return address;
    return `${address.substring(0, 5)}...${address.substring(address.length - 4)}`;
  };

  const toggleChat = () => setIsChatOpen(!isChatOpen);

  return (
    <div className={`chat-component ${isChatOpen ? 'open' : 'minimized'}`} onClick={isChatOpen ? undefined : toggleChat}>
      {isChatOpen ? (
        <>
          <div className="chat-header">
            <h2 className="chat-title">Chat</h2>
            <button onClick={toggleChat} className="chat-close">×</button>
          </div>
          <div className="chat-history" ref={chatHistoryRef} onClick={handleMessageClick}>
            {chatHistory.map((chat, index) => (
              <div key={index} className={`chat-message ${chat.sender === walletAddress ? 'my-message' : ''}`}>
                <div className="chat-message-header">
                  <strong className="chat-sender">{shortenAddress(chat.sender)}</strong>
                  <span className="chat-timestamp">{formatDate(chat.timestamp)}</span>
                </div>
                <span className="chat-message-content" dangerouslySetInnerHTML={{ __html: chat.message }} />
              </div>
            ))}
          </div>
          <div className="message-input-container">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message..."
              className="message-input"
            />
            <button onClick={handleSendMessage} className="send-button">Send</button>
          </div>
        </>
      ) : (
        <div className="chat-label">Chat</div>
      )}
    </div>
  );
};

export default ChatComponent;