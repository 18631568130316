import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LaunchpadsOverview.css';

const LaunchpadsOverview = () => {
  const initialLaunchpads = [
    {
      id: 1,
      name: 'DRAGON',
      ticker: 'DRG-17KE',
      logo: 'https://i.ibb.co/1ZWb4kX/dragon.png',
      targetAmount: 600000000,
      currentAmount: 0,
      price: 0.05,
      address: 'klv1scc9g9zkaqpcqyd36t9x79epdyeqwerqhhf6qsykeza96y99uwcsq303w6',
      precision: 6,
      startTime: '2024-10-27T19:00:00Z',
      endTime: '2025-05-31T12:00:00Z'
    },
    // ... You can add more launchpads here
  ];

  const [launchpads, setLaunchpads] = useState(initialLaunchpads);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetchBalance = async (launchpad) => {
    const url = `https://api.mainnet.klever.finance/v1.0/address/${launchpad.address}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const assetData = data.data.account.assets[launchpad.ticker];
      if (assetData) {
        const balance = parseInt(
          (assetData.balance / Math.pow(10, launchpad.precision)).toFixed(0),
          10
        );
        setLaunchpads((prev) =>
          prev.map((lp) =>
            lp.id === launchpad.id ? { ...lp, currentAmount: balance } : lp
          )
        );
      }
    } catch (error) {
      console.error(`Error fetching balance for ${launchpad.ticker}:`, error);
    }
  };

  useEffect(() => {
    const fetchBalances = () => {
      initialLaunchpads.forEach((launchpad) => {
        fetchBalance(launchpad);
      });
    };

    fetchBalances();
    const interval = setInterval(fetchBalances, 10000); // Refresh every 10 seconds
    setIsLoading(false);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="launchpads-overview-container">
      <div className="launchpads-header">
        <h1 className="overview-title">Active Launchpads</h1>
        <div className="wallet-stats">
          <span>Total Launchpads: {launchpads.length}</span>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-spinner"><p>Loading...</p></div>
      ) : (
        <>
          {launchpads.length === 0 ? (
            <p className="no-launchpads">No active launchpads at the moment. Please check back later!</p>
          ) : (
            <div className="launchpads-grid">
              {launchpads.map((launchpad) => {
                const now = new Date();
                const startTime = new Date(launchpad.startTime);
                const isUpcoming = now < startTime;
                const progressPercentage = (
                  100 * (1 - launchpad.currentAmount / launchpad.targetAmount)
                ).toFixed(2);
                return (
                  <div
                    key={launchpad.id}
                    className={`launchpad-card ${isUpcoming ? 'upcoming' : 'live'}`}
                    onClick={() => navigate(`/launchpad/${launchpad.id}-${launchpad.ticker.toLowerCase()}`)}
                  >
                    <div className="launchpad-header">
                      <img
                        src={launchpad.logo}
                        alt={`${launchpad.name} logo`}
                        className="launchpad-logo"
                      />
                      <span className="launchpad-status">{isUpcoming ? 'UPCOMING' : 'LIVE'}</span>
                    </div>
                    <div className="launchpad-content">
                      <h2 className="launchpad-name">{launchpad.name}</h2>
                      <p className="launchpad-ticker">{launchpad.ticker}</p>
                      <p className="launchpad-price">{`${launchpad.price} KLV`}</p>
                      <div className="launchpad-dates">
                        <p className="launch-date">Start: {new Date(launchpad.startTime).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}</p>
                        <p className="launch-date">End: {new Date(launchpad.endTime).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}</p>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress-bar-fill"
                          style={{ width: `${progressPercentage}%` }}
                        ></div>
                        <span className="progress-text">{`${(launchpad.targetAmount - launchpad.currentAmount).toLocaleString()} / ${launchpad.targetAmount.toLocaleString()} (${progressPercentage}%)`}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
      <p className="disclaimer">
        We do not promote any projects listed. Please make responsible and informed decisions when investing.
      </p>
    </div>
  );
};

export default LaunchpadsOverview;