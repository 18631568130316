import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { useWallet } from '../components/WalletContext';
import { web } from '@klever/sdk-web';
import { useNavigate } from 'react-router-dom';
import '../styles/MintPage.css';
import Spinner from '../components/Spinner';

const MintPage = () => {
  const { isConnected, walletAddress, connectWallet } = useWallet();
  const [displayItos, setDisplayItos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIto, setSelectedIto] = useState(null);
  const [selectedPackIndex, setSelectedPackIndex] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [transactionStatus, setTransactionStatus] = useState('');

  const mintWindowRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itoResponse = await axios.get('https://api.mainnet.klever.finance/v1.0/ito/list?page=1&limit=100');
        const itos = itoResponse.data.data.itos;

        const filteredItos = itos.filter(ito => 
          ito.isActive && ito.mintedAmount < ito.maxAmount
        );

        if (filteredItos.length === 0) {
          setDisplayItos([]);
          setLoading(false);
          return;
        }

        const assetIds = filteredItos.map(ito => ito.assetId);
        const keys = filteredItos.flatMap(ito => ito.packData.map(pack => pack.key));

        const allAssets = await fetchAssetsByIdsAndKeys(assetIds, keys);

        const matchedItos = filteredItos.map(ito => {
          const asset = allAssets.find(asset => asset.assetId === ito.assetId && asset.assetType === "NonFungible");
          const keyAsset = allAssets.find(asset => asset.assetId === ito.packData[0].key);
          
          const precision = keyAsset ? keyAsset.precision : 0;

          if (asset) {
            const updatedLogoUrl = replaceLogoUri(asset.assetId, asset.logo);
            return { 
              ...ito, 
              logoUrl: updatedLogoUrl, 
              name: asset.name, 
              precision, 
              pricePerPack: ito.packData[0].packs[0].price,
              key: ito.packData[0].key,
            };
          }
          return null;
        }).filter(ito => ito !== null);

        setDisplayItos(matchedItos);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    const fetchAssetsByIdsAndKeys = async (assetIds, keys) => {
      let page = 1;
      let fetchedAssets = [];
      let response;

      do {
        response = await axios.get(`https://api.mainnet.klever.finance/v1.0/assets/list?page=${page}&limit=100`);
        const assets = response.data.data.assets;

        const matchingAssets = assets.filter(asset => assetIds.includes(asset.assetId) || keys.includes(asset.assetId));
        fetchedAssets = fetchedAssets.concat(matchingAssets);
        page += 1;
      } while (response.data.data.assets.length === 100);

      return fetchedAssets;
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchBalance = async () => {
      if (isConnected && selectedIto) {
        try {
          const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`);
          const assets = response.data.data.account.assets || {};
          const assetBalance = assets[selectedIto.key]?.balance || 0;
          setUserBalance(assetBalance / Math.pow(10, selectedIto.precision));
        } catch (error) {
          console.error('Error fetching balance:', error);
          setUserBalance(0);
        }
      }
    };

    fetchBalance();
  }, [isConnected, selectedIto, walletAddress]);

  const replaceLogoUri = (assetId, logoUrl) => {
    if (assetId === 'WCNFT-25HY') return null;
    if (!logoUrl) return logoUrl;
    if (logoUrl.includes("https://klever-mint.mypinata.cloud")) {
      return logoUrl.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
    }
    return logoUrl;
  };

  const formatPrice = (price, precision) => {
    if (precision === 0) return price.toString();
    return parseFloat(price.toFixed(precision)).toString();
  };

  const handleLogoError = (e) => {
    e.target.style.display = 'none';
    e.target.nextSibling.style.display = 'block'; // Show placeholder
  };

  const handleMintClick = (ito) => {
    setSelectedIto(ito);
    setSelectedPackIndex(0);
    const firstPack = ito.packData[0].packs[0];
    setTotalPrice(calculateTotalPrice(firstPack, ito.precision));
    document.body.classList.add('modal-open');
    document.addEventListener('mousedown', handleClickOutside);
  };

  const calculateTotalPrice = (pack, precision) => {
    const totalPrice = (pack.amount * pack.price) / Math.pow(10, precision);
    return formatPrice(totalPrice, precision);
  };

  const closeMintWindow = () => {
    setSelectedIto(null);
    setSelectedPackIndex(0);
    setTotalPrice(0);
    setTransactionStatus('');
    document.body.classList.remove('modal-open');
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const mintNft = useCallback(async () => {
    setTransactionStatus('Processing transaction...');

    const payload = {
      buyType: 0,
      id: selectedIto.assetId,
      amount: selectedIto.packData[0].packs[selectedPackIndex].amount,
      currencyId: selectedIto.packData[0].key,
    };

    const contract = {
      type: 17,
      payload: payload
    };

    try {
      const unsignedTx = await web.buildTransaction([contract]);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);

      console.log('Mint NFT transaction response:', response);
      setTransactionStatus('Mint transaction completed!');
      setTimeout(closeMintWindow, 2000);
    } catch (error) {
      console.error('Error in mint NFT transaction:', error);
      setTransactionStatus('Mint transaction failed!');
      setTimeout(closeMintWindow, 2000);
    }
  }, [selectedIto, selectedPackIndex]);

  const handleNextPack = () => {
    if (selectedIto && selectedPackIndex < selectedIto.packData[0].packs.length - 1) {
      const nextIndex = selectedPackIndex + 1;
      setSelectedPackIndex(nextIndex);
      setTotalPrice(calculateTotalPrice(selectedIto.packData[0].packs[nextIndex], selectedIto.precision));
    }
  };

  const handlePrevPack = () => {
    if (selectedIto && selectedPackIndex > 0) {
      const prevIndex = selectedPackIndex - 1;
      setSelectedPackIndex(prevIndex);
      setTotalPrice(calculateTotalPrice(selectedIto.packData[0].packs[prevIndex], selectedIto.precision));
    }
  };

  const handleClickOutside = (event) => {
    if (mintWindowRef.current && !mintWindowRef.current.contains(event.target)) {
      closeMintWindow();
    }
  };

  if (loading) {
    return (
      <div className="mint-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mint-container">
      <h1 className="mint-title">Mint Your NFTs</h1>
      <div className="mint-grid">
        {displayItos.length > 0 ? (
          displayItos.map((ito, index) => {
            const { logoUrl, name, precision } = ito;
            const pack = ito.packData[0];
            const price = precision ? pack.packs[0].price / Math.pow(10, precision) : pack.packs[0].price;

            return (
              <div key={ito.assetId} className="mint-card">
                <div className="mint-card-image">
                  {logoUrl ? (
                    <>
                      <img src={logoUrl} alt={name} className="mint-logo" onError={handleLogoError} />
                      <div className="mint-logo-placeholder" style={{ display: 'none' }} />
                    </>
                  ) : (
                    <div className="mint-logo-placeholder" />
                  )}
                </div>
                <div className="mint-card-content">
                  <h3 className="mint-card-title">{name || 'N/A'}</h3>
                  <p className="mint-card-info">Minted: {ito.mintedAmount} / {ito.maxAmount}</p>
                  <p className="mint-card-price">Price: {formatPrice(price, precision)} {pack.key}</p>
                  <button
                    className="mint-button"
                    onClick={() => handleMintClick(ito)}
                  >
                    Mint Now
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p className="no-itos">No active ITOs available for minting.</p>
        )}
      </div>

      {selectedIto && (
        <div className="mint-modal">
          <div className="mint-modal-content" ref={mintWindowRef}>
            <h2 className="mint-modal-title">{selectedIto.name}</h2>
            <div className="mint-modal-image">
              {selectedIto.logoUrl ? (
                <img src={selectedIto.logoUrl} alt={selectedIto.name} className="mint-logo" onError={handleLogoError} />
              ) : (
                <div className="mint-logo-placeholder" />
              )}
            </div>
            <p className="mint-modal-info">Collection: {selectedIto.assetId}</p>
            <p className="mint-modal-info">Minted: {selectedIto.mintedAmount} / {selectedIto.maxAmount}</p>
            <div className="pack-selector">
              <button
                className="pack-nav-button"
                onClick={handlePrevPack}
                disabled={selectedPackIndex === 0}
              >
                &#8249;
              </button>
              <div className="pack-amount">
                {selectedIto.packData[0].packs[selectedPackIndex].amount} NFTs
              </div>
              <button
                className="pack-nav-button"
                onClick={handleNextPack}
                disabled={selectedPackIndex === selectedIto.packData[0].packs.length - 1}
              >
                &#8250;
              </button>
            </div>
            <p className="mint-modal-price">Total: {totalPrice} {selectedIto.packData[0].key}</p>
            <p className="mint-modal-balance">Balance: {userBalance} {selectedIto.packData[0].key}</p>
            <button
              className="mint-confirm-button"
              onClick={isConnected ? mintNft : connectWallet}
              disabled={isConnected && userBalance < totalPrice}
            >
              {isConnected ? (userBalance < totalPrice ? 'Insufficient Balance' : 'Mint Now') : 'Connect Wallet'}
            </button>
            <button className="mint-cancel-button" onClick={closeMintWindow}>Cancel</button>
            {transactionStatus && <p className="transaction-status">{transactionStatus}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default MintPage;