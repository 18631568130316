import { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { web } from '@klever/sdk-web'; // Ensure you have @klever/sdk-web installed

const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [useKdaFee, setUseKdaFee] = useState(false);
  const [kdaOptions, setKdaOptions] = useState([]); 
  const [selectedKda, setSelectedKda] = useState(''); 

  const toggleKdaFee = () => {
    setUseKdaFee(!useKdaFee);
  };

  // Function to connect to the wallet
  const connectWallet = async () => {
    try {
      await web.initialize();
      const address = await web.getWalletAddress();
      setWalletAddress(address);
      setIsConnected(true);
      console.log('Wallet connected:', address);
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  // Function to fetch available KDA options
  const fetchKdaOptions = async () => {
    try {
      const response = await axios.get('https://api.mainnet.klever.finance/v1.0/assets/pool/list?page=1&limit=100');
      const pools = response.data.data.pools.map(pool => pool.kda);
      setKdaOptions(pools);
    } catch (error) {
      console.error('Error fetching KDA options:', error);
    }
  };

  // Fetch KDA options when the component mounts
  useEffect(() => {
    fetchKdaOptions();
  }, []);

  return (
    <WalletContext.Provider value={{ walletAddress, isConnected, connectWallet, useKdaFee, toggleKdaFee, kdaOptions, selectedKda, setSelectedKda }}>
      {children}
    </WalletContext.Provider>
  );
};
