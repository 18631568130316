import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/CollectionsPage.css';
import Spinner from '../components/Spinner';

const CollectionsPage = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('volumeTotal');
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    const fetchCollectionData = async () => {
      try {
        let allAssets = [];
        const totalPages = 5;

        for (let page = 1; page <= totalPages; page++) {
          const assetsResponse = await axios.get(`https://api.mainnet.klever.finance/v1.0/assets/list?page=${page}&limit=100`);
          const assetsData = assetsResponse.data.data.assets.filter(asset => asset.assetType === "NonFungible");
          allAssets = [...allAssets, ...assetsData];
        }

        const marketplaceIds = [
          'd4f2bab340c55fde', '7c353c02770da029', '2936933ee43db509',
          '417b70c0eb7a33cb', '116056b257d9f6d5', 'aa68ad853a09988c', 'd2a04fe890a6edda'
        ];
        const statsPromises = marketplaceIds.map(id => axios.get(`https://api.mainnet.klever.finance/v1.0/marketplaces/${id}/stats`));
        const statsResponses = await Promise.all(statsPromises);
        let combinedStatsData = [];
        statsResponses.forEach(response => combinedStatsData = [...combinedStatsData, ...response.data.data.stats]);

        let mergedCollections = allAssets.map(asset => {
          let totalVolume = 0, totalItems = 0, totalOwners = 0;
          let floorPrices = [];
          let royalties = null;

          combinedStatsData.forEach(stat => {
            if (stat.assetId === asset.assetId) {
              totalVolume += stat.volumeTotal || 0;
              totalItems += stat.items || 0;
              totalOwners += stat.owners || 0;
              if (stat.floor && stat.floor > 0) floorPrices.push(stat.floor);
            }
          });

          const combinedFloorPrice = floorPrices.length > 0 ? Math.min(...floorPrices) : 0;

          if (asset.royalties && asset.royalties.marketPercentage) {
            royalties = (asset.royalties.marketPercentage / 100).toString().replace(/\.00$/, '');
          }

          const metadataObject = asset.uris ? asset.uris.find(uri => uri.key === 'metadata') : null;
          const metadataUri = metadataObject ? metadataObject.value : null;

          return {
            ...asset,
            volumeTotal: totalVolume,
            items: totalItems,
            owners: totalOwners,
            floor: combinedFloorPrice,
            royalties,
            circulatingSupply: asset.circulatingSupply || 0,
            metadataUri
          };
        });

        mergedCollections.sort((a, b) => b.volumeTotal - a.volumeTotal);
        setCollections(mergedCollections);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCollectionData();
  }, []);

  const handleImageError = (event) => {
    const src = event.target.src;
    if (src.startsWith("https://klever-mint.mypinata.cloud/ipfs/") && src !== "https://klever-mint.mypinata.cloud/ipfs/QmNwWcvPmfNzPa4JQppj8mXcaeM8BA2yA5VAjVWYLzTT7a") {
      event.target.src = src.replace("https://klever-mint.mypinata.cloud/ipfs/", "https://ipfs.io/ipfs/");
    }
  };

  const handleSort = (field) => {
    if (sortField === field) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedCollections = useMemo(() => {
    const filtered = collections.filter(collection =>
      collection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      collection.assetId.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filtered.sort((a, b) => {
      let aVal = a[sortField] || 0;
      let bVal = b[sortField] || 0;
      if (sortField === 'name') {
        aVal = aVal.toLowerCase();
        bVal = bVal.toLowerCase();
        return sortDirection === 'asc' ? (aVal < bVal ? -1 : aVal > bVal ? 1 : 0) : (aVal < bVal ? 1 : aVal > bVal ? -1 : 0);
      }
      return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
    });
  }, [collections, searchTerm, sortField, sortDirection]);

  const getSortIndicator = (field) => sortField === field ? (sortDirection === 'asc' ? ' ↑' : ' ↓') : '';

  return (
    <div className="collections-container">
      <div className="controls">
        <input
          type="text"
          placeholder="Search collections by name or ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      <div className="table-wrapper">
        {loading ? (
          <Spinner />
        ) : (
          <table className="collections-table">
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => handleSort('name')}>
                  Collection {getSortIndicator('name')}
                </th>
                <th onClick={() => handleSort('volumeTotal')}>
                  Volume {getSortIndicator('volumeTotal')}
                </th>
                <th className="desktop-only" onClick={() => handleSort('floor')}>
                  Floor Price {getSortIndicator('floor')}
                </th>
                <th className="desktop-only" onClick={() => handleSort('royalties')}>
                  Royalties {getSortIndicator('royalties')}
                </th>
                <th onClick={() => handleSort('circulatingSupply')}>
                  Supply {getSortIndicator('circulatingSupply')}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedCollections.map((collection, index) => (
                <tr key={collection.assetId}>
                  <td>{index + 1}</td>
                  <td className="collection-name-cell">
                    {collection.logo && (
                      <img
                        src={collection.logo}
                        alt={`${collection.name} Logo`}
                        className="collection-logo"
                        onError={handleImageError}
                      />
                    )}
                    <div className="collection-info">
                      <Link
                        to={{
                          pathname: `/collections/${collection.assetId}`,
                          state: { metadataUri: collection.metadataUri }
                        }}
                        className="collection-name"
                      >
                        {collection.name}
                      </Link>
                      <span className="collection-ticker">{collection.assetId}</span>
                    </div>
                  </td>
                  <td>{collection.volumeTotal ? `${collection.volumeTotal.toLocaleString()} KLV` : '0 KLV'}</td>
                  <td className="desktop-only">{collection.floor ? `${collection.floor.toLocaleString()} KLV` : '0 KLV'}</td>
                  <td className="desktop-only">{collection.royalties !== null ? `${collection.royalties}%` : '0%'}</td>
                  <td>{collection.circulatingSupply.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CollectionsPage;