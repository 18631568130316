import React, { useState, useEffect } from 'react';
import '../styles/SwapKIDKLV.css';
import BABYDGKOLogo from '../assets/babydgkoLogo.png';
import klvLogo from '../assets/klever.png';
import { useWallet } from '../components/WalletContext';
import { web, TransactionType } from '@klever/sdk-web';
import Spinner from '../components/Spinner';

const swapWalletAddress =
  'klv1ktz420t0uteyuthdeypw4ewq9jp8p6qne294zcekxjgezuf73dmqam3qcr';

async function fetchData(url, retries = 2, delay = 1000) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error fetching data from ${url}: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay));
      return fetchData(url, retries - 1, delay);
    }
    console.error(`Failed to fetch data: ${error}`);
    throw error;
  }
}

async function getAssetBalances(address, primaryAssetId, secondaryAssetId) {
  const nodeApiUrl = `https://node.mainnet.klever.finance/address/${address}`;
  const proxyApiUrl = `https://api.mainnet.klever.finance/address/${address}`;
  try {
    const nodeData = await fetchData(nodeApiUrl);
    const proxyData = await fetchData(proxyApiUrl);
    const nodeBalance = nodeData?.data?.account?.Balance;
    const nodeNonce = nodeData?.data?.account?.Nonce;
    const proxyBalance = proxyData?.data?.account?.balance;
    const proxyNonce = proxyData?.data?.account?.nonce;
    if (
      nodeBalance === undefined ||
      nodeNonce === undefined ||
      proxyBalance === undefined ||
      proxyNonce === undefined
    ) {
      throw new Error('Could not retrieve necessary data.');
    }
    if (nodeBalance !== proxyBalance || nodeNonce !== proxyNonce) {
      const retryProxyData = await fetchData(proxyApiUrl);
      const retryProxyBalance = retryProxyData?.data?.account?.balance;
      const retryProxyNonce = retryProxyData?.data?.account?.nonce;
      if (retryProxyBalance !== nodeBalance || retryProxyNonce !== nodeNonce) {
        return { primary: 0, secondary: 0 };
      }
    }
    const assets = proxyData?.data?.account?.assets;
    if (!assets) {
      throw new Error('No assets found.');
    }
    const primaryBalance = assets[primaryAssetId]
      ? assets[primaryAssetId].balance / Math.pow(10, 8)
      : 0;
    const secondaryBalance = assets[secondaryAssetId]
      ? assets[secondaryAssetId].balance / Math.pow(10, 6)
      : 0;
    return { primary: primaryBalance, secondary: secondaryBalance };
  } catch (error) {
    console.error(`An error occurred: ${error.message}`);
    return { primary: 0, secondary: 0 };
  }
}

async function fetchBalances(walletAddress, swapWalletAddress, primaryAssetId, secondaryAssetId) {
  try {
    const swapWalletBalances = await getAssetBalances(
      swapWalletAddress,
      primaryAssetId,
      secondaryAssetId
    );
    let userBalances = { primary: 0, secondary: 0 };
    if (walletAddress) {
      userBalances = await getAssetBalances(
        walletAddress,
        primaryAssetId,
        secondaryAssetId
      );
    }
    return { user: userBalances, swapWallet: swapWalletBalances };
  } catch (error) {
    console.error("Failed to fetch balances", error);
    return { user: { primary: 0, secondary: 0 }, swapWallet: { primary: 0, secondary: 0 } };
  }
}

function startBalanceFetch(
  walletAddress,
  swapWalletAddress,
  setBalances,
  setExchangeRate,
  setDynamicRate,
  primaryAssetId,
  secondaryAssetId
) {
  const fetchAndSetBalances = async () => {
    const newBalances = await fetchBalances(walletAddress, swapWalletAddress, primaryAssetId, secondaryAssetId);
    setBalances(newBalances);
    if (newBalances.swapWallet.primary > 0 && newBalances.swapWallet.secondary > 0) {
      const rate = newBalances.swapWallet.primary / newBalances.swapWallet.secondary;
      setExchangeRate(rate);
      setDynamicRate(rate);
    }
  };
  fetchAndSetBalances();
  const intervalId = setInterval(fetchAndSetBalances, 2000);
  return () => clearInterval(intervalId);
}

async function signMetadata(metadata) {
  try {
    const response = await fetch('https://klever-radar.de/rares/encrypt', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: metadata }),
    });
    if (!response.ok) {
      throw new Error(`Error signing metadata: ${response.status} ${response.statusText}`);
    }
    const result = await response.json();
    return result.encryptedData;
  } catch (error) {
    console.error('Error during metadata signing:', error);
    throw error;
  }
}

const SwapBABYDGKOKLVPage = () => {
  const [activeTab, setActiveTab] = useState("swap");
  const [balances, setBalances] = useState({
    user: { primary: 0, secondary: 0 },
    swapWallet: { primary: 0, secondary: 0 },
  });
  const [fromToken, setFromToken] = useState('BABYDGKO-3S67');
  const [toToken, setToToken] = useState('KLV');
  const [amount, setAmount] = useState('');
  const [feeBreakdown, setFeeBreakdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionStatusMessage, setTransactionStatusMessage] = useState('');
  const { walletAddress } = useWallet();
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [dynamicRate, setDynamicRate] = useState(1);
  const [lockedRate, setLockedRate] = useState(null);
  const [lockedRateTimeout, setLockedRateTimeout] = useState(null);
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
  const [isBalancesLoading, setIsBalancesLoading] = useState(true);
  const [balanceDots, setBalanceDots] = useState("");

  useEffect(() => {
    const dots = ["", ".", "..", "..."];
    let index = 0;
    const intervalId = setInterval(() => {
      index = (index + 1) % dots.length;
      setBalanceDots(dots[index]);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (balances.swapWallet.primary > 0 || balances.swapWallet.secondary > 0) {
      setIsBalancesLoading(false);
    } else {
      setIsBalancesLoading(true);
    }
  }, [balances]);

  const fetchAndSetBalances = async () => {
    const newBalances = await fetchBalances(walletAddress, swapWalletAddress, "BABYDGKO-3S67", "KLV");
    setBalances(newBalances);
    if (newBalances.swapWallet.primary > 0 && newBalances.swapWallet.secondary > 0) {
      const rate = newBalances.swapWallet.primary / newBalances.swapWallet.secondary;
      setExchangeRate(rate);
      setDynamicRate(rate);
    }
  };

  useEffect(() => {
    if (!isTransactionInProgress && walletAddress) {
      const cleanup = startBalanceFetch(
        walletAddress,
        swapWalletAddress,
        setBalances,
        setExchangeRate,
        setDynamicRate,
        "BABYDGKO-3S67",
        "KLV"
      );
      return cleanup;
    }
  }, [walletAddress, isTransactionInProgress]);

  useEffect(() => {
    if (amount !== '') {
      validateAndCalculateFees(amount);
    }
  }, [balances.swapWallet.primary, balances.swapWallet.secondary]);

  const calculateDynamicRateBasedOnLiquidity = (fromTokenAmount, tokenType) => {
    const babydgkoAvailable = balances.swapWallet.primary;
    const klvAvailable = balances.swapWallet.secondary;
    let adjustedRate = exchangeRate;
    if (!exchangeRate || exchangeRate <= 0) return adjustedRate;
    if (tokenType === 'KLV') {
      const totalKlvValueInBabydgko = fromTokenAmount * exchangeRate;
      const consumptionRate = totalKlvValueInBabydgko / babydgkoAvailable;
      adjustedRate /= (1 + consumptionRate);
    } else if (tokenType === 'BABYDGKO-3S67') {
      const totalBabydgkoValueInKlv = fromTokenAmount / exchangeRate;
      const consumptionRate = totalBabydgkoValueInKlv / klvAvailable;
      adjustedRate *= (1 + consumptionRate);
    }
    setDynamicRate(adjustedRate);
    return adjustedRate;
  };

  const validateAndCalculateFees = (inputAmount) => {
    setTransactionStatusMessage('');
    const parsedAmount = parseFloat(inputAmount);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      setReceiveAmount(0);
      setTransactionStatusMessage('Please enter a valid amount.');
      return;
    }
    const minAmount = fromToken === 'KLV' ? 100 : 100000;
    const maxAmount = fromToken === 'KLV' ? 200000 : 10000000;
    if (parsedAmount < minAmount || parsedAmount > maxAmount) {
      setReceiveAmount(0);
      setTransactionStatusMessage(
        `Amount must be between ${minAmount} and ${maxAmount} ${fromToken}.`
      );
      return;
    }
    const userBalance = fromToken === 'KLV' ? balances.user.secondary : balances.user.primary;
    if (parsedAmount > userBalance) {
      setReceiveAmount(0);
      setTransactionStatusMessage("Insufficient balance!");
      return;
    }
    const fixedFee = 20;
    let rate = calculateDynamicRateBasedOnLiquidity(parsedAmount, fromToken);

    setLockedRate(rate);

    if (lockedRateTimeout) {
      clearTimeout(lockedRateTimeout);
    }

    const timeoutId = setTimeout(() => {
      setLockedRate(null);
      setFeeBreakdown([]);
      setReceiveAmount(0);
      setTransactionStatusMessage("Signing window expired. Please re-enter the amount.");
    }, 3000);
    setLockedRateTimeout(timeoutId);

    const swapFee = parsedAmount * 0.01;
    let netAmount = parsedAmount - swapFee;
    let calculatedReceiveAmount;
    if (fromToken === 'KLV') {
      calculatedReceiveAmount = (netAmount - fixedFee) * rate;
      calculatedReceiveAmount = Math.floor(calculatedReceiveAmount * 1000) / 1000;
    } else {
      calculatedReceiveAmount = (netAmount / rate) - fixedFee;
      calculatedReceiveAmount = Math.floor(calculatedReceiveAmount * 1000) / 1000;
    }
    calculatedReceiveAmount = Math.max(0, calculatedReceiveAmount);
    if (
      (fromToken === 'KLV' && calculatedReceiveAmount > balances.swapWallet.primary) ||
      (fromToken === 'BABYDGKO-3S67' && calculatedReceiveAmount > balances.swapWallet.secondary)
    ) {
      setTransactionStatusMessage(
        'Swap amount exceeds swap wallet liquidity. Please reduce the swap amount.'
      );
      setReceiveAmount(0);
      return;
    }
    setReceiveAmount(calculatedReceiveAmount);
    setTransactionStatusMessage("");
    setFeeBreakdown([
      <div key="swapFee">Swap Fee: {swapFee.toFixed(2)} {fromToken}</div>,
      <div key="fixedFee">Fixed Fee: {fixedFee} KLV</div>,
      <div key="rate">
        Rate: {fromToken === 'KLV'
          ? `${rate.toFixed(6)} BABYDGKO per KLV`
          : `${(1 / rate).toFixed(6)} KLV per BABYDGKO`}
      </div>,
      <div key="receiveAmount" className="receive-amount">
        Will receive: <span>{calculatedReceiveAmount.toFixed(3)} {fromToken === 'KLV' ? 'BABYDGKO' : 'KLV'}</span>
      </div>
    ]);
  };

  const evaluateButtonVisibility = () => {
    const userBalance = fromToken === 'KLV' ? balances.user.secondary : balances.user.primary;
    const swapWalletToTokenBalance = toToken === 'KLV' ? balances.swapWallet.secondary : balances.swapWallet.primary;
    const parsedAmount = parseFloat(amount) || 0;
    const isAmountValid =
      (fromToken === 'KLV' && parsedAmount >= 100 && parsedAmount <= 200000) ||
      (fromToken === 'BABYDGKO-3S67' && parsedAmount >= 100000 && parsedAmount <= 10000000);
    const hasSufficientUserBalance = parsedAmount <= userBalance;
    const canSwapWalletCoverTransaction = parseFloat(receiveAmount) <= swapWalletToTokenBalance;
    return isAmountValid && hasSufficientUserBalance && canSwapWalletCoverTransaction;
  };

  async function sendTransaction() {
    if (!lockedRate) {
      setTransactionStatusMessage("Signing window expired. Please re-enter the amount.");
      return;
    }

    if (lockedRateTimeout) {
      clearTimeout(lockedRateTimeout);
      setLockedRateTimeout(null);
    }

    setIsLoading(true);
    setTransactionStatusMessage('Please sign the transaction within 10 seconds...');
    setIsTransactionInProgress(true);

    const rate = lockedRate;
    const precision = fromToken === 'KLV' ? 6 : 8;
    const actualAmount = Number(amount) * Math.pow(10, precision);
    const payload = {
      amount: actualAmount,
      receiver: swapWalletAddress,
      kda: fromToken,
    };

    try {
      const metadata = fromToken === 'KLV' ? rate.toFixed(6) : (1 / rate).toFixed(6);
      const signedMetadata = await signMetadata(metadata);
      const unsignedTx = await web.buildTransaction(
        [{ payload, type: TransactionType.Transfer }],
        [signedMetadata]
      );

      // Create a timeout promise
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Signing window expired')), 10000); // 10-second timeout
      });

      // Race the signing against the timeout
      const signedTx = await Promise.race([
        web.signTransaction(unsignedTx),
        timeoutPromise
      ]);

      // Check if signing was cancelled or timed out
      if (!signedTx) {
        throw new Error('Transaction cancelled by user');
      }

      await web.broadcastTransactions([signedTx]);
      setTransactionStatusMessage('Transaction successful!');
      setTimeout(() => {
        setAmount('');
        setTransactionStatusMessage('');
        setIsLoading(false);
        setFeeBreakdown([]);
        fetchAndSetBalances();
        setIsTransactionInProgress(false);
      }, 8000);
    } catch (error) {
      console.error('Transaction error:', error);
      setTransactionStatusMessage(
        error.message.includes('cancelled') 
          ? 'Transaction cancelled.' 
          : error.message.includes('expired') 
            ? 'Signing window expired. Please try again.'
            : 'Transaction failed. Please try again.'
      );
      setIsLoading(false);
      setIsTransactionInProgress(false);
    }
  }

  const switchTokens = () => {
    setFromToken(toToken);
    setToToken(fromToken);
    setAmount('');
    setFeeBreakdown([]);
    setReceiveAmount(0);
    fetchAndSetBalances();
  };

  const handlePercentageClick = (percentage) => {
    const balance = fromToken === 'KLV' ? balances.user.secondary : balances.user.primary;
    const percentageAmount = balance * (percentage / 100);
    const roundedAmount = Math.floor(percentageAmount * 100) / 100;
    setAmount(roundedAmount.toString());
    validateAndCalculateFees(roundedAmount);
    setTransactionStatusMessage('');
  };

  const calculateReceiveAmount = (inputValue) => {
    setAmount(inputValue);
    validateAndCalculateFees(inputValue);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  return (
    <div className="swap-container">
      <div className="swap-header">
        <div className="tab-header">
          <button
            className={`tab-button ${activeTab === "swap" ? "active" : ""}`}
            onClick={() => setActiveTab("swap")}
          >
            Swap (BETA)
          </button>
          <button
            className={`tab-button ${activeTab === "trade" ? "active" : ""}`}
            onClick={() => setActiveTab("trade")}
          >
            Trade History
          </button>
        </div>
      </div>

      {activeTab === "swap" && (
        <div className="swap-content">
          <h2 className="swap-title">Swap (BETA)</h2>

          <div className="swap-balance-info">
            <h4>
              Liquidity {isBalancesLoading && <span className="loading-dots">{balanceDots}</span>}
            </h4>
            <div className="token-balances">
              <div className="token-balance">
                <img src={BABYDGKOLogo} alt="BABYDGKO Token" className="token-logo" />
                <span>{balances.swapWallet.primary.toFixed(2)}</span>
              </div>
              <div className="token-balance">
                <img src={klvLogo} alt="KLV Token" className="token-logo" />
                <span>{balances.swapWallet.secondary.toFixed(2)}</span>
              </div>
            </div>
          </div>

          <div className="swap-form">
            <div className="swap-row">
              <label>From</label>
              <div className="token-selection">
                <div className="token-info">
                  <img
                    src={fromToken === 'KLV' ? klvLogo : BABYDGKOLogo}
                    alt="From Token"
                    className="token-logo"
                  />
                  <span className="token-label">{fromToken}</span>
                </div>
                <span className="balance-info">
                  Balance: {fromToken === 'KLV'
                    ? balances.user.secondary.toFixed(4)
                    : balances.user.primary.toFixed(3)}
                </span>
              </div>
            </div>

            <button className="switch-button" onClick={switchTokens}>
              Switch Tokens
            </button>

            <div className="swap-row">
              <label>To</label>
              <div className="token-selection">
                <div className="token-info">
                  <img
                    src={toToken === 'KLV' ? klvLogo : BABYDGKOLogo}
                    alt="To Token"
                    className="token-logo"
                  />
                  <span className="token-label">{toToken}</span>
                </div>
                <span className="balance-info">
                  Balance: {toToken === 'KLV'
                    ? balances.user.secondary.toFixed(4)
                    : balances.user.primary.toFixed(3)}
                </span>
              </div>
            </div>

            <div className="exchange-rate">
              <p>
                Current Rate: {fromToken === 'BABYDGKO-3S67'
                  ? `1 BABYDGKO = ${(1 / dynamicRate).toFixed(6)} KLV`
                  : `1 KLV = ${dynamicRate.toFixed(6)} BABYDGKO`}
              </p>
            </div>

            <div className="swap-row">
              <label>Amount</label>
              <input
                type="number"
                className="swap-input"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => calculateReceiveAmount(e.target.value)}
              />
              <div className="percentage-buttons">
                {[10, 25, 50, 100].map((percent) => (
                  <button
                    key={percent}
                    className="percent-button"
                    onClick={() => handlePercentageClick(percent)}
                  >
                    {percent}%
                  </button>
                ))}
              </div>
            </div>

            <div className="fee-details">
              {feeBreakdown.map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>

            {evaluateButtonVisibility() && (
              <button
                onClick={sendTransaction}
                className="swap-button-swap"
                disabled={isLoading}
              >
                Swap
              </button>
            )}

            {transactionStatusMessage && (
              <div className="transaction-status-swap">{transactionStatusMessage}</div>
            )}

            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === "trade" && (
        <div className="trade-history-content">
          <TradeHistory formatTimestamp={formatTimestamp} />
        </div>
      )}
    </div>
  );
};

const TradeHistory = ({ formatTimestamp }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const walletAddr = swapWalletAddress;

  const fetchTradeHistory = async () => {
    setLoading(true);
    const url = `https://api.mainnet.klever.finance/v1.0/transaction/list?limit=25&page=1&status=success&toAddress=${walletAddr}`;
    try {
      const data = await fetchData(url);
      const txs = data.data?.transactions || [];
      setTransactions(txs);
    } catch (error) {
      console.error("Error fetching trade history:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTradeHistory();
  }, []);

  return (
    <div className="trade-history-content">
      <h3>Trade History</h3>
      {loading ? (
        <p className="loading-spinner">Loading...</p>
      ) : transactions.length === 0 ? (
        <p className="no-history">No trade history available.</p>
      ) : (
        <ul className="transaction-list">
          {transactions.map((tx) => {
            const transferReceipt = tx.receipts.find((r) => r.typeString === "Transfer");
            if (!transferReceipt) return null;
            const assetId = transferReceipt.assetId;
            const isBuy = assetId === "KLV";
            const precision = assetId === "KLV" ? 6 : assetId === "BABYDGKO-3S67" ? 8 : 0;
            const amount = transferReceipt.value / Math.pow(10, precision);
            const displayAsset = assetId === "BABYDGKO-3S67" ? "BABYDGKO" : "KLV";
            return (
              <li key={tx.hash} className="transaction-item">
                <span className="transaction-time">{formatTimestamp(tx.timestamp)}</span>
                <span className={`transaction-type ${isBuy ? "buy" : "sell"}`}>
                  {amount} {displayAsset}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SwapBABYDGKOKLVPage;