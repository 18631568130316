import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import { web } from '@klever/sdk-web';
import axios from 'axios';
import '../styles/AssetDetailsPage.css';

const AssetDetailsPage = () => {
  const { assetId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { walletAddress, useKdaFee, selectedKda } = useWallet();

  const [assets, setAssets] = useState([]);
  const [assetDetails, setAssetDetails] = useState({});
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) || 1
  );
  const [totalPages, setTotalPages] = useState(0);
  const [listingPrice, setListingPrice] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');
  const [isSellModalOpen, setIsSellModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [sendToAddress, setSendToAddress] = useState('');
  const [isTopChoice, setIsTopChoice] = useState(false);

  const toggleSelectAsset = (asset) => {
    setSelectedAssets((prev) =>
      prev.includes(asset) ? prev.filter((a) => a !== asset) : [...prev, asset]
    );
  };

  const toggleSelectAll = () => {
    setSelectedAssets(selectedAssets.length === assets.length ? [] : assets);
  };

  const openSellModal = () => {
    if (selectedAssets.length > 0) setIsSellModalOpen(true);
  };

  const closeSellModal = () => {
    setIsSellModalOpen(false);
    setTransactionStatus('');
  };

  const openSendModal = () => {
    if (selectedAssets.length > 0) setIsSendModalOpen(true);
  };

  const closeSendModal = () => {
    setIsSendModalOpen(false);
    setTransactionStatus('');
    setSendToAddress('');
  };

  const fetchMetadata = async (collectionId, nftNonce) => {
    try {
      let matchingAsset = null;
      const limit = 100;
      const maxPages = 5;
      for (let p = 1; p <= maxPages; p++) {
        const url = `https://api.mainnet.klever.finance/v1.0/assets/list?page=${p}&limit=${limit}`;
        const response = await axios.get(url);
        const assetList = response.data.data.assets;
        matchingAsset = assetList.find((asset) => asset.assetId === collectionId);
        if (matchingAsset) break;
      }
      if (!matchingAsset) return null;

      const metadataObject = matchingAsset.uris.find(
        (uri) => uri.key.toLowerCase() === 'metadata'
      );
      let metadataUri = metadataObject ? metadataObject.value : null;
      if (metadataUri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") {
        metadataUri = `https://ipfs.io/ipfs/${metadataUri}/${nftNonce}.json`;
      } else if (metadataUri) {
        if (metadataUri.includes("klever-mint.mypinata.cloud")) {
          metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        metadataUri = `${metadataUri}/${nftNonce}`;
      }
      if (!metadataUri) return null;

      try {
        const { data: metadata } = await axios.get(metadataUri);
        if (metadata.image && metadata.image.includes("www.lovemonsternft.com")) {
          const imageName = metadata.image.split('/').pop();
          metadata.image = `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
        } else if (metadata.image && metadata.image.includes("klever-mint.mypinata.cloud")) {
          metadata.image = metadata.image.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        return metadata;
      } catch (error) {
        console.error('Primary fetch failed for', nftNonce, ', trying fallback.');
        const fallbackUri = `https://${metadataUri}.ipfs.w3s.link/${nftNonce}`;
        const { data: fallbackData } = await axios.get(fallbackUri);
        return fallbackData;
      }
    } catch (error) {
      console.error(`Error fetching metadata for asset ${nftNonce}:`, error);
      return null;
    }
  };

  const renderMetadataAttributes = (metadata) => {
    if (metadata?.attributes) {
      return (
        <div className="metadata-attributes-container">
          {metadata.attributes.map((attr, idx) => (
            <div key={idx} className="metadata-attribute">
              <span className="metadata-trait-type">{attr.trait_type}:</span>
              <span className="metadata-value">{attr.value}</span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const fetchAssets = async () => {
    try {
      const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}/collection/${assetId}?limit=20&page=${page}`;
      const response = await axios.get(url);
      const mapped = response.data.data.collection.map((asset) => ({
        collectionNonceId: asset.assetId,
        collection: asset.collection,
        nftNonce: asset.nftNonce,
      }));
      setAssets(mapped);
      setTotalPages(response.data.pagination.totalPages);
      setPage(response.data.pagination.self);
      mapped.forEach(async (asset) => {
        const metadata = await fetchMetadata(asset.collection, asset.nftNonce);
        if (metadata) {
          setAssetDetails((prev) => ({
            ...prev,
            [asset.collectionNonceId]: metadata,
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching asset details:', error);
    }
  };

  const listAssetsForSale = useCallback(async () => {
    const listingPayloads = selectedAssets.map((asset) => {
      const assetIdentifier = `${asset.collection}/${asset.nftNonce}`;
      return {
        marketType: 0,
        marketplaceId: "417b70c0eb7a33cb",
        assetId: assetIdentifier,
        currencyId: "KLV",
        price: Number(listingPrice) * 10 ** 6,
        endTime: Math.floor(new Date(endDate).getTime() / 1000),
      };
    });

    let feePayload = null;
    let txOptions = {};
    if (useKdaFee && selectedKda) txOptions = { kdaFee: selectedKda };
    if (isTopChoice) {
      feePayload = {
        amount: 50 * 10 ** 6,
        receiver: "klv1355u5mgxl5h8gmxzz9tjt3jpp8h6frxykvvs8zya7xrd3ghhc06qxa9kyq",
        kda: selectedKda || "KLV",
      };
    }
    try {
      if (feePayload) {
        const feeTx = await web.buildTransaction([{ payload: feePayload, type: 0 }], undefined, txOptions);
        const signedFeeTx = await web.signTransaction(feeTx);
        const feeResponse = await web.broadcastTransactions([signedFeeTx]);
        if (feeResponse.error) throw new Error('Fee transaction failed');
        const feeTxHash = feeResponse.data.txsHashes[0];
        const confirmed = await waitForTransactionConfirmation(feeTxHash);
        if (!confirmed) throw new Error("Fee transaction not confirmed");
      }
      const listingTx = await web.buildTransaction(
        listingPayloads.map((payload) => ({ payload, type: 18 })),
        undefined,
        txOptions
      );
      const signedListingTx = await web.signTransaction(listingTx);
      const listingResponse = await web.broadcastTransactions([signedListingTx]);
      setTransactionStatus("Listing transaction completed!");
      setAssets((prev) => prev.filter((asset) => !selectedAssets.includes(asset)));
      setSelectedAssets([]);
    } catch (error) {
      console.error('Error in listing transactions:', error);
      setTransactionStatus("Transactions failed!");
    } finally {
      setTimeout(closeSellModal, 2000);
    }
  }, [selectedAssets, listingPrice, endDate, isTopChoice, useKdaFee, selectedKda]);

  async function waitForTransactionConfirmation(txHash) {
    const maxAttempts = 10;
    const delay = 4000;
    for (let i = 0; i < maxAttempts; i++) {
      const isConfirmed = await checkTransactionStatus(txHash);
      if (isConfirmed) return true;
      await new Promise((res) => setTimeout(res, delay));
    }
    return false;
  }

  async function checkTransactionStatus(txHash) {
    try {
      const response = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/${txHash}`);
      const data = await response.json();
      return (
        response.ok &&
        data.data &&
        data.data.transaction &&
        data.data.transaction.status === "success"
      );
    } catch (error) {
      console.error('Error checking transaction status:', error);
      return false;
    }
  }

  const sendAssets = async () => {
    const sendPayloads = selectedAssets.map((asset) => {
      const assetIdentifier = `${asset.collection}/${asset.nftNonce}`;
      return {
        receiver: sendToAddress,
        kda: assetIdentifier,
        amount: 1,
      };
    });
    let txOptions = {};
    if (useKdaFee && selectedKda) txOptions = { kdaFee: selectedKda };
    try {
      const sendTx = await web.buildTransaction(
        sendPayloads.map((payload) => ({ payload, type: 0 })),
        undefined,
        txOptions
      );
      const signedTx = await web.signTransaction(sendTx);
      const response = await web.broadcastTransactions([signedTx]);
      setTransactionStatus("Transaction sent successfully");
      setAssets((prev) => prev.filter((asset) => !selectedAssets.includes(asset)));
      setSelectedAssets([]);
    } catch (error) {
      console.error('Error sending assets:', error);
      setTransactionStatus("Transaction failed!");
    } finally {
      setTimeout(closeSendModal, 2000);
    }
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const yyyy = tomorrow.getFullYear();
    let mm = tomorrow.getMonth() + 1;
    let dd = tomorrow.getDate();
    mm = mm < 10 ? `0${mm}` : mm;
    dd = dd < 10 ? `0${dd}` : dd;
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleFixedEndDateChange = (e) => {
    const days = Number(e.target.value);
    if (days) {
      const end = new Date();
      end.setDate(end.getDate() + days);
      const yyyy = end.getFullYear();
      let mm = end.getMonth() + 1;
      let dd = end.getDate();
      mm = mm < 10 ? `0${mm}` : mm;
      dd = dd < 10 ? `0${dd}` : dd;
      setEndDate(`${yyyy}-${mm}-${dd}`);
    } else {
      setEndDate('');
    }
  };

  useEffect(() => {
    if (walletAddress) fetchAssets();
  }, [assetId, walletAddress, page]);

  const handlePaginationClick = (newPage) => {
    navigate(`/wallet/${assetId}?page=${newPage}`);
    setPage(newPage);
  };

  const PaginationButtons = ({ currentPage, totalPages }) => (
    <div className="pagination-controls">
      <button
        className="pagination-btn"
        onClick={() => handlePaginationClick(Math.max(1, currentPage - 1))}
        disabled={currentPage <= 1}
      >
        Previous
      </button>
      <span className="page-counter">
        Page {currentPage} of {totalPages}
      </span>
      <button
        className="pagination-btn"
        onClick={() => handlePaginationClick(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage >= totalPages}
      >
        Next
      </button>
    </div>
  );

  return (
    <div className="asset-details-container">
      <div className="asset-header">
        <h1 className="asset-title">{assetId}</h1>
        <div className="wallet-stats">
          <span>Total NFTs: {assets.length}</span>
        </div>
      </div>

      <PaginationButtons currentPage={page} totalPages={totalPages} />

      <div className="bulk-action-tabs">
        <button
          onClick={toggleSelectAll}
          className={`bulk-action-button-tab ${selectedAssets.length === assets.length ? 'active' : ''}`}
        >
          <i className={`fas ${selectedAssets.length === assets.length ? 'fa-times-circle' : 'fa-check-circle'}`}></i>
          {selectedAssets.length === assets.length ? 'Deselect All' : 'Select All'}
        </button>
        {selectedAssets.length > 0 && (
          <>
            <button onClick={openSellModal} className="bulk-action-button-tab active">
              <i className="fas fa-tag"></i> Sell Selected ({selectedAssets.length})
            </button>
            <button onClick={openSendModal} className="bulk-action-button-tab active">
              <i className="fas fa-paper-plane"></i> Send Selected ({selectedAssets.length})
            </button>
          </>
        )}
      </div>

      <div className="asset-grid">
        {assets.map((asset) => (
          <div
            key={asset.collectionNonceId}
            className={`asset-card ${selectedAssets.includes(asset) ? 'selected' : ''}`}
            onClick={() => toggleSelectAsset(asset)}
          >
            <p className="nft-id">#{asset.nftNonce}</p>
            {assetDetails[asset.collectionNonceId] && (
              <div className="asset-image-container">
                <img
                  src={assetDetails[asset.collectionNonceId].image}
                  alt={`${assetId} NFT #${asset.nftNonce}`}
                  className="nft-image"
                />
                {renderMetadataAttributes(assetDetails[asset.collectionNonceId])}
              </div>
            )}
          </div>
        ))}
      </div>

      <PaginationButtons currentPage={page} totalPages={totalPages} />

      {isSellModalOpen && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <h2>List Selected Assets for Sale</h2>
            <div className="modal-inputs">
              <div className="input-group">
                <label className="modal-label">Price (KLV)</label>
                <input
                  type="text"
                  placeholder="Enter price in KLV"
                  value={listingPrice}
                  onChange={(e) => setListingPrice(e.target.value)}
                  className="modal-input"
                />
              </div>
              <div className="input-group">
                <label className="modal-label">Expires on</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={getTomorrowDate()}
                  className="modal-input"
                />
              </div>
              <div className="input-group">
                <label className="modal-label">Or choose duration</label>
                <select onChange={handleFixedEndDateChange} className="modal-input modal-select">
                  <option value="">Select duration</option>
                  <option value="1">1 Day</option>
                  <option value="7">7 Days</option>
                  <option value="30">30 Days</option>
                </select>
              </div>
              <div className="input-group checkbox-group">
                <input
                  type="checkbox"
                  id="topChoice"
                  checked={isTopChoice}
                  onChange={(e) => setIsTopChoice(e.target.checked)}
                  className="modal-checkbox"
                />
                <label htmlFor="topChoice" className="checkbox-label">
                  Top Choice? (Additional 50 KLV will be charged)
                </label>
              </div>
            </div>
            <div className="modal-actions">
              <button onClick={listAssetsForSale} className="action-button">List for Sale</button>
              <button onClick={closeSellModal} className="action-button cancel">Cancel</button>
            </div>
            {transactionStatus && <p className="transaction-status">{transactionStatus}</p>}
          </div>
        </div>
      )}

      {isSendModalOpen && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <h2>Send Selected Assets</h2>
            <div className="modal-inputs">
              <div className="input-group">
                <label className="modal-label">Recipient Address</label>
                <input
                  type="text"
                  placeholder="Recipient Address"
                  value={sendToAddress}
                  onChange={(e) => setSendToAddress(e.target.value)}
                  className="modal-input"
                />
              </div>
            </div>
            <div className="modal-actions">
              <button onClick={sendAssets} className="action-button">Send</button>
              <button onClick={closeSendModal} className="action-button cancel">Cancel</button>
            </div>
            {transactionStatus && <p className="transaction-status">{transactionStatus}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetDetailsPage;