// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import CollectionsPage from './pages/CollectionsPage';
import CollectionDetailPage from './pages/CollectionDetailPage';
import NftDetailsPage from './pages/NftDetailsPage';
import WalletPage from './pages/WalletPage';
import WalletPageTokens from './pages/WalletPageTokens';
import WalletHistoryPage from './pages/WalletHistoryPage';
import ScrollToTop from './components/ScrollToTop';
import { WalletProvider } from './components/WalletContext';
import WalletSalesPage from './pages/WalletSalesPage';
import WalletListingsPage from './pages/WalletListingsPage';
import WalletPurchasesPage from './pages/WalletPurchasesPage';
import AssetDetailsPage from './pages/AssetDetailsPage';
import TokenDetailsPage from './pages/TokenDetailsPage';
import RewardsPage from './pages/RewardsPage';
import Swap from './pages/swap';
import SwapKIDKLV from './pages/SwapKIDKLV';
import SwapKBLOCKLV from './pages/SwapKBLOCKLV';
import SwapKONGKLV from './pages/SwapKONGKLV';
import SwapMOTOKLV from './pages/SwapMOTOKLV';
import SwapGOATKLV from './pages/SwapGOATKLV';
import SwapKPEPEKLV from './pages/SwapKPEPEKLV';
import SwapTCTKLV from './pages/SwapTCTKLV';
import SwapSAVOKLV from './pages/SwapSAVOKLV';
import SwapDGKOKLV from './pages/SwapDGKOKLV';
import SwapBABYDGKOKLV from './pages/SwapBABYDGKOKLV';
import LaunchpadsOverview from './pages/LaunchpadsOverview';
import PrivateSaleDRG from './pages/PrivateSaleDRG';
import SwapKAKAFLUSH from './pages/SwapKakaFlush';
import ChatComponent from './components/ChatComponent';
import MintPage from './pages/MintPage';
import VotingPage from './pages/VotingPage';
import './App.css';
import { ThemeProvider } from './components/ThemeContext'; // Ensure this import exists
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
library.add(faMoon, faSun);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <WalletProvider>
        <ThemeProvider>
          <div className="App">
            <Header />
            <Routes>
              <Route path="/" element={<LayoutWithFooter />}>
                <Route index element={<HomePage />} />
                <Route path="/collections" element={<CollectionsPage />} />
                <Route path="/collections/:collectionId" element={<CollectionDetailPage />} />
                <Route path="/collections/:collectionId/:nftId" element={<NftDetailsPage />} />
                <Route path="/wallet/tokens/:assetId" element={<TokenDetailsPage />} />
                <Route path="/rewards" element={<RewardsPage />} />
                <Route path="/voting" element={<VotingPage />} />
                <Route path="/launchpad" element={<LaunchpadsOverview />} />
                <Route path="/launchpad/1-drg-17ke" element={<PrivateSaleDRG />} />
                <Route path="/swap" element={<Swap />} />
                <Route path="/mint" element={<MintPage />} />
                <Route path="/swapDGKOKLV" element={<SwapDGKOKLV />} />
                <Route path="/swapBABYDGKOKLV" element={<SwapBABYDGKOKLV />} />
                <Route path="/swapKONGKLV" element={<SwapKONGKLV />} />
                <Route path="/swapKAKAFLUSH" element={<SwapKAKAFLUSH />} />
                <Route path="/wallet/:assetId" element={<AssetDetailsPage />} />
                <Route path="/wallet" element={<Outlet />}>
                  <Route index element={<WalletPage />} />
                  <Route path="tokens" element={<WalletPageTokens />} />
                  <Route path="history" element={<WalletHistoryPage />}>
                    <Route index element={<WalletHistoryPage />} />
                    <Route path="sales" element={<WalletSalesPage />} />
                    <Route path="listings" element={<WalletListingsPage />} />
                    <Route path="purchases" element={<WalletPurchasesPage />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
            <ChatComponent />
          </div>
        </ThemeProvider>
      </WalletProvider>
    </Router>
  );
}

function LayoutWithFooter() {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

export default App;