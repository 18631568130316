import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import Spinner from '../components/Spinner';
import { web, TransactionType } from '@klever/sdk-web';

import skyGalleryImage from '../assets/skygallery.png';
import klevernftImage from '../assets/klever.png';
import minthreeImage from '../assets/minthree.png';
import xportImage from '../assets/xport.png';
import RareCanvasImage from '../assets/logoicon2.png';
import world from '../assets/world.png';
import artGallery from '../assets/artgallery.png';
import '../styles/WalletListingsPage.css';

const marketplaceMapping = {
  '7c353c02770da029': 'Sky Gallery',
  'd4f2bab340c55fde': 'KleverNFT',
  '293693ee43db509': 'Minthree',
  'd2a04fe890a6edda': 'NFT Art Gallery',
  'aa68ad853a09988c': 'Xport',
  '417b70c0eb7a33cb': 'Rare Canvas',
  '81376f526cf47730': 'World dex'
};

const marketplaceImages = {
  'Sky Gallery': skyGalleryImage,
  'KleverNFT': klevernftImage,
  'Minthree': minthreeImage,
  'Xport': xportImage,
  'Rare Canvas': RareCanvasImage,
  'World dex': world,
  'NFT Art Gallery': artGallery,
};

const statusIcons = {
  'Active': 'fas fa-check-circle',
  'Cancelled': 'fas fa-times-circle',
  'Claimed': 'fas fa-clipboard-check',
  'Sold': 'fas fa-dollar-sign',
  'Expired': 'fas fa-hourglass-end'
};

const WalletListingsPage = () => {
  const { walletAddress, useKdaFee, selectedKda } = useWallet();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [expiredOrderCount, setExpiredOrderCount] = useState(0);
  const [activeOrderCount, setActiveOrderCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [modalCount, setModalCount] = useState(0);
  const [modalCost, setModalCost] = useState(0);
  const [ordersToClaim, setOrdersToClaim] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState('');

  const fetchOrderStatus = async (orderId, endTime) => {
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=20&page=1&orderid=${orderId}`);
      const txs = response.data.data.transactions;
      let status = 'Active';
      const currentTime = Math.floor(Date.now() / 1000);
      txs.sort((a, b) => a.blockNum - b.blockNum);
      for (const tx of txs) {
        if (tx.contract.some(c => c.typeString === "CancelMarketOrderContractType" && c.parameter.orderID === orderId)) {
          status = 'Cancelled';
          break;
        } else if (tx.contract.some(c => c.typeString === "ClaimContractType" && c.parameter.id === orderId)) {
          status = 'Claimed';
          break;
        } else if (tx.contract.some(c => c.typeString === "BuyContractType" && c.parameter.id === orderId)) {
          status = 'Sold';
          break;
        }
      }
      if (currentTime > endTime && status === 'Active') status = 'Expired';
      return status;
    } catch (error) {
      console.error('Error fetching order status for order ID:', orderId, error);
      return 'Error';
    }
  };

  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=20&page=${page}&type=18&status=success&fromAddress=${walletAddress}`);
      const transformed = await Promise.all(response.data.data.transactions.flatMap(async (tx) => {
        const sellContracts = tx.contract.filter(c => c.typeString === "SellContractType");
        if (sellContracts.length > 0) {
          const contracts = await Promise.all(sellContracts.map(async (sellContract, index) => {
            const [collectionId, nftNonce] = sellContract.parameter.assetId.split('/');
            const price = sellContract.parameter.price / 1000000;
            const marketplaceName = marketplaceMapping[sellContract.parameter.marketplaceID] || 'Unknown Marketplace';
            const sellReceipt = tx.receipts.find(r => r.typeString === "Sell" && r.cID === index);
            const orderId = sellReceipt ? sellReceipt.orderId : 'No Order ID';
            const status = await fetchOrderStatus(orderId, sellContract.parameter.endTime);
            const metadata = await fetchMetadata(collectionId, nftNonce);
            const imageUrl = metadata?.image || null;
            return { ...tx, collectionId, nftNonce, price, marketplaceName, imageUrl, orderId, status };
          }));
          return contracts;
        }
        return [];
      }));
      const validTxs = transformed.flat().filter(tx => tx !== null);
      setTransactions(validTxs);
      setTotalPages(response.data.pagination.totalPages);
      setExpiredOrderCount(validTxs.filter(tx => tx.status === 'Expired').length);
      setActiveOrderCount(validTxs.filter(tx => tx.status === 'Active').length);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMetadata = async (collectionId, nftId) => {
    try {
      const assetResponse = await axios.get('https://api.mainnet.klever.finance/v1.0/assets/list?limit=100');
      const assets = assetResponse.data.data.assets;
      const matchingAsset = assets.find(a => a.assetId === collectionId);
      if (!matchingAsset) {
        console.error('Matching asset not found for collection ID:', collectionId);
        return null;
      }
      const metadataObject = matchingAsset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
      let metadataUri = metadataObject ? metadataObject.value : null;
      if (metadataUri && metadataUri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") {
        metadataUri = `https://ipfs.io/ipfs/${metadataUri}/${nftId}.json`;
      } else if (metadataUri) {
        if (metadataUri.includes("klever-mint.mypinata.cloud")) {
          metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        metadataUri = `${metadataUri}/${nftId}`;
      }
      if (!metadataUri) {
        console.error('Metadata URI not found for', nftId);
        return null;
      }
      try {
        const { data: metadata } = await axios.get(metadataUri);
        if (metadata.image && metadata.image.includes("www.lovemonsternft.com")) {
          const imageName = metadata.image.split('/').pop();
          metadata.image = `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
        } else if (metadata.image && metadata.image.includes("klever-mint.mypinata.cloud")) {
          metadata.image = metadata.image.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        return metadata;
      } catch (error) {
        console.error('Primary fetch failed, trying fallback for', nftId);
        const fallbackUri = `https://${metadataUri}.ipfs.w3s.link/${nftId}`;
        const { data: fallbackData } = await axios.get(fallbackUri);
        return fallbackData;
      }
    } catch (error) {
      console.error(`Error fetching metadata for ${collectionId} and ${nftId}:`, error);
      return null;
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const openModal = (action, count, cost, orders = []) => {
    setModalAction(action);
    setModalCount(count);
    setModalCost(cost);
    setOrdersToClaim(orders);
    setModalVisible(true);
  };

  const handleClaimAll = () => {
    const expiredOrders = transactions.filter(tx => tx.status === 'Expired').slice(0, 20).map(tx => tx.orderId);
    const cost = expiredOrders.length * 3;
    openModal('claim', expiredOrders.length, cost, expiredOrders);
  };

  const handleDelistAll = () => {
    const activeOrders = transactions.filter(tx => tx.status === 'Active').slice(0, 20).map(tx => tx.orderId);
    const cost = activeOrders.length * 52;
    openModal('delist', activeOrders.length, cost, activeOrders);
  };

  const handleModalConfirm = async () => {
    if (modalAction === 'claim') {
      await claimAllNfts(ordersToClaim);
    } else if (modalAction === 'delist') {
      await delistAllOrders(ordersToClaim);
    }
    setTimeout(() => setModalVisible(false), 2000);
  };

  const claimAllNfts = useCallback(async (orderIds) => {
    const contracts = orderIds.map(orderId => ({
      type: 9,
      payload: { claimType: 2, id: orderId }
    }));
    let txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;
    try {
      const unsignedTx = await web.buildTransaction(contracts, undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Claim NFT transaction response:', response);
      setTransactionStatus("Claim transaction completed!");
    } catch (error) {
      console.error('Error in claim NFT transaction:', error);
      setTransactionStatus("Claim transaction failed!");
    }
  }, [useKdaFee, selectedKda]);

  const delistAllOrders = useCallback(async (orderIds) => {
    const contracts = orderIds.map(orderId => ({
      type: 19,
      payload: { claimType: 2, orderId: orderId }
    }));
    let txOptions = useKdaFee && selectedKda ? { kdaFee: selectedKda } : undefined;
    try {
      const unsignedTx = await web.buildTransaction(contracts, undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Cancel order transaction response:', response);
      setTransactionStatus("Order cancellation completed!");
    } catch (error) {
      console.error('Error in cancel order transaction:', error);
      setTransactionStatus("Order cancellation failed!");
    }
  }, [useKdaFee, selectedKda]);

  return (
    <div className="wallet-listings-page">
      {loading ? <Spinner /> : (
        <>
          <div className="wallet-button-group">
  {expiredOrderCount > 0 && (
    <button onClick={handleClaimAll} className="wallet-tab-button active">
      <i className="fas fa-clipboard-check"></i> Claim all ({expiredOrderCount})
    </button>
  )}
  {activeOrderCount > 0 && (
    <button onClick={handleDelistAll} className="wallet-tab-button active">
      <i className="fas fa-times-circle"></i> Delist all ({activeOrderCount})
    </button>
  )}
</div>


          {transactions.map((tx, index) => (
            <Link to={`/collections/${tx.collectionId}/${tx.nftNonce}`} key={index} className="activity-item-link">
              <div className="activity-item">
                {tx.imageUrl && (
                  <img src={tx.imageUrl} alt={`NFT ${tx.collectionId}/${tx.nftNonce}`} className="listings-nft-image" />
                )}
                <div className="activity-details">
                  <p>
                    <span className="date">{formatDate(tx.timestamp)}</span> — You listed&nbsp;
                    <span className="asset-id">{tx.collectionId}/{tx.nftNonce}</span> for&nbsp;
                    <span className="listed-price">{tx.price.toLocaleString()} KLV</span> on&nbsp;
                    <span className="marketplace-info">
                      <span className="marketplace">{tx.marketplaceName}</span>
                      {marketplaceImages[tx.marketplaceName] && (
                        <img src={marketplaceImages[tx.marketplaceName]} alt={tx.marketplaceName} className="marketplace-image" />
                      )}
                    </span>
                  </p>
                  <span className={`order-status status-${tx.status.toLowerCase()}`}>
                    <i className={statusIcons[tx.status]}></i> {tx.status}
                  </span>
                </div>
              </div>
            </Link>
          ))}

          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage <= 1}>Previous</button>
            <span>Page {currentPage} of {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage >= totalPages}>Next</button>
          </div>
        </>
      )}
      {modalVisible && (
        <DelistingConfirmationModal
          action={modalAction}
          count={modalCount}
          cost={modalCost}
          onCancel={() => setModalVisible(false)}
          onConfirm={handleModalConfirm}
        />
      )}
    </div>
  );
};

const DelistingConfirmationModal = ({ action, count, cost, onCancel, onConfirm }) => (
  <div className="modal-overlay">
    <div className="modal-content">
      <h2>Confirm {action} of {count} NFT{count > 1 ? 's' : ''}</h2>
      <p>Cost: {cost} KLV</p>
      <div className="modal-buttons">
        <button onClick={onCancel} className="cancel-btn">Cancel</button>
        <button onClick={onConfirm} className="confirm-btn">Confirm</button>
      </div>
    </div>
  </div>
);

export default WalletListingsPage;
