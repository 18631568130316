// src/components/Header.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import { useWallet } from './WalletContext';
import WalletModal from '../components/WalletModal';
import { ThemeContext } from '../components/ThemeContext';

const Header = () => {
  const { walletAddress, isConnected, connectWallet } = useWallet();
  const { isDarkMode } = useContext(ThemeContext); // Access theme context
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const shortenAddress = (address) => address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';

  const onWalletClick = () => {
    if (isConnected) {
      setIsModalOpen(true);
    } else {
      connectWallet();
    }
  };

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/" className="brand-link">
          <img src="/logoicon2.png" alt="Logo" className="logo-icon" />
          <span className="brand-name">
            <span className="rare">Rare</span>
            <span className="canvas">Canvas</span>
          </span>
        </Link>
      </div>

      <nav className="header-nav">
        <Link to="/voting" className="nav-link" title="Voting">
          <i className="fas fa-vote-yea"></i>
          <span className="nav-label">Voting</span>
        </Link>
        <Link to="/launchpad" className="nav-link" title="Launchpad">
          <i className="fas fa-rocket"></i>
          <span className="nav-label">Launchpad</span>
        </Link>
        <Link to="/collections" className="nav-link" title="Collections">
          <i className="fas fa-th"></i>
          <span className="nav-label">Collections</span>
        </Link>
        <Link to="/swap" className="nav-link" title="Swap">
          <i className="fas fa-exchange-alt"></i>
          <span className="nav-label">Swap</span>
        </Link>
        <Link to="/mint" className="nav-link" title="Mint">
          <i className="fas fa-image"></i>
          <span className="nav-label">Mint</span>
        </Link>
        <Link to="/rewards" className="nav-link" title="Rewards">
          <i className="fas fa-trophy"></i>
          <span className="nav-label">Rewards</span>
        </Link>
        <button
          className={`nav-link wallet-button ${isConnected ? 'connected' : ''}`}
          onClick={onWalletClick}
          title={isConnected ? shortenAddress(walletAddress) : "Connect Wallet"}
        >
          <i className="fas fa-wallet"></i>
          <span className="nav-label">{isConnected ? shortenAddress(walletAddress) : 'Connect'}</span>
        </button>
      </nav>

      <WalletModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} walletAddress={walletAddress} />
    </header>
  );
};

export default Header;