import React, { useState, useEffect } from 'react';
import '../styles/SwapKakaFlush.css';
import { web, TransactionType } from '@klever/sdk-web';
import { useWallet } from '../components/WalletContext';
import KAKAlogo from '../assets/kakaLogo.png';
import FLUSHLogo from '../assets/flushLogo.png';

const SwapKakaFlush = () => {
  const [kakaBalance, setKakaBalance] = useState('0');
  const [flushBalance, setFlushBalance] = useState('0');
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [transactionStatusMessage, setTransactionStatusMessage] = useState('');
  const [transactionResultMessage, setTransactionResultMessage] = useState('');
  const { walletAddress } = useWallet();
  const [isKakaToFlush, setIsKakaToFlush] = useState(true); // Toggle between KAKA to FLUSH and FLUSH to KAKA

  // Conversion rates
  const kakaToFlushRate = 5000000; // 5,000,000 KAKA = 1 FLUSH
  const flushToKakaRate = 4000000; // 1 FLUSH = 4,000,000 KAKA

  const fetchBalances = async () => {
    if (!walletAddress) return;

    const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      const kakaData = data.data.account.assets['KAKA-3DRY'];
      const flushData = data.data.account.assets['FLUSH-2H6Z'];

      if (kakaData) setKakaBalance(adjustBalance(kakaData.balance, kakaData.precision || 0));
      if (flushData) setFlushBalance(adjustBalance(flushData.balance, flushData.precision || 0));
    } catch (error) {
      console.error('Error fetching balances:', error);
    }
  };

  useEffect(() => {
    fetchBalances();
    const interval = setInterval(fetchBalances, 4000); // Update balances every 4 seconds
    return () => clearInterval(interval);
  }, [walletAddress]);

  const adjustBalance = (balance, precision) => (balance / Math.pow(10, precision)).toFixed(precision);

  const handleAmountClick = (amount) => setAmount(amount.toString());

  useEffect(() => setAmount(''), [isKakaToFlush]); // Reset amount when swap direction changes

  const sendTransaction = async () => {
    setIsLoading(true);
    const actualAmount = parseInt(amount, 10) * (isKakaToFlush ? 1 : 1000000); // Adjust for KAKA/FLUSH precision

    const payload = {
      amount: actualAmount,
      receiver: 'klv1j5ugwuq6qak0uqdtxy566aauktdr7mhmnx97kthz4da8qmgn9n8qqumkf4',
      kda: isKakaToFlush ? 'KAKA-3DRY' : 'FLUSH-2H6Z',
    };

    try {
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }]);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setTransactionResultMessage('Transaction successful. Please wait for balance to update.');
      setTimeout(() => {
        setTransactionResultMessage('');
        fetchBalances();
      }, 7000);
    } catch (error) {
      console.error('Transaction error:', error);
      setTransactionResultMessage('Transaction failed. Please try again.');
      setTimeout(() => setTransactionResultMessage(''), 7000);
    } finally {
      setIsLoading(false);
      setAmount('');
    }
  };

  useEffect(() => {
    let message = '';
    if (!walletAddress) message = 'Connect your wallet first.';
    else if (transactionResultMessage) return;
    else if (isLoading) message = 'Processing...';
    else if (!amount) message = 'Select an amount.';
    else if (parseFloat(amount) > parseFloat(isKakaToFlush ? kakaBalance : flushBalance)) message = `Insufficient ${isKakaToFlush ? 'KAKA' : 'FLUSH'} balance.`;
    else message = 'Ready to swap';
    setTransactionStatusMessage(message);
  }, [isLoading, amount, kakaBalance, flushBalance, transactionResultMessage, walletAddress, isKakaToFlush]);

  const formatAmount = (amount) => (isKakaToFlush ? (amount / kakaToFlushRate).toFixed(0) : (amount * flushToKakaRate / 1000000).toFixed(0));

  const swapDirection = isKakaToFlush ? 'KAKA to FLUSH' : 'FLUSH to KAKA';

  return (
    <div className="swap-container">
      <div className="swap-header">
        <h1 className="swap-title">Swap {swapDirection}</h1>
      </div>
      <div className="swap-balance-info">
        <div className="token-balance">
          <img src={KAKAlogo} alt="KAKA" className="token-logo" />
          <span>KAKA Balance: {kakaBalance}</span>
        </div>
        <div className="token-balance">
          <img src={FLUSHLogo} alt="FLUSH" className="token-logo" />
          <span>FLUSH Balance: {flushBalance}</span>
        </div>
      </div>
      <div className="swap-toggle">
        <button onClick={() => setIsKakaToFlush(true)} className={isKakaToFlush ? 'toggle-button active' : 'toggle-button'}>
          KAKA to FLUSH
        </button>
        <button onClick={() => setIsKakaToFlush(false)} className={!isKakaToFlush ? 'toggle-button active' : 'toggle-button'}>
          FLUSH to KAKA
        </button>
      </div>
      <div className="amount-buttons">
        {isKakaToFlush
          ? [5, 10, 20, 50, 100].map((value) => (
            <button
              key={value}
              onClick={() => handleAmountClick(value * 1000000)}
              className={`amount-button ${amount === (value * 1000000).toString() ? 'active' : ''}`}
            >
              {value}M KAKA
            </button>
          ))
          : [1, 5, 10, 50].map((value) => (
            <button
              key={value}
              onClick={() => handleAmountClick(value)}
              className={`amount-button ${amount === value.toString() ? 'active' : ''}`}
            >
              {value} FLUSH
            </button>
          ))}
      </div>
      {amount && (
        <div className="selected-amount">
          <p>Swapping: {amount} {isKakaToFlush ? 'KAKA' : 'FLUSH'}</p>
          <p>Will Receive: {formatAmount(amount)} {isKakaToFlush ? 'FLUSH' : 'KAKA'}</p>
        </div>
      )}
      <div className="swap-actions">
        <button
          onClick={sendTransaction}
          className={`swap-button ${isLoading || !amount || !walletAddress || parseFloat(amount) > parseFloat(isKakaToFlush ? kakaBalance : flushBalance) ? 'disabled' : ''}`}
          disabled={isLoading || !amount || !walletAddress || parseFloat(amount) > parseFloat(isKakaToFlush ? kakaBalance : flushBalance)}
        >
          {isLoading ? 'Processing...' : 'Swap'}
        </button>
      </div>
      <div className="transaction-status">
        <p>{transactionStatusMessage}</p>
        {transactionResultMessage && <p>{transactionResultMessage}</p>}
      </div>
    </div>
  );
};

export default SwapKakaFlush;