import React from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../styles/Swap.css';
import klvLogo from '../assets/klever.png';
import kblocLogo from '../assets/kbloc.png';
import kongLogo from '../assets/KONG.png';
import motoLogo from '../assets/motokoin.png';
import goatLogo from '../assets/goatLogo.png';
import kpepeLogo from '../assets/kpepeLogo.png';
import kakaLogo from '../assets/kakaLogo.png';
import flushLogo from '../assets/flushLogo.png';
import tctLogo from '../assets/tctLogo.png';
import savoLogo from '../assets/savoLogo.png';
import dgkoLogo from '../assets/dgkoLogo.png';
import babydgkoLogo from '../assets/babydgkoLogo.png';

const tokenData = [
  { value: 'KONG', label: 'KONG', logo: kongLogo },
  { value: 'DGKO', label: 'DGKO', logo: dgkoLogo },
  { value: 'BABYDGKO', label: 'BABYDGKO', logo: babydgkoLogo },
];

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--sw-card-bg)', // Use theme variable
    color: 'var(--sw-primary-text)', // Use theme variable
    border: `1px solid var(--sw-border-color)`,
    boxShadow: 'var(--sw-shadow)', // Use theme shadow
    borderRadius: '12px',
    padding: '8px',
    '&:hover': {
      borderColor: 'var(--sw-accent-color)', // Dynamic hover border
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.1)' : 'var(--sw-card-bg)', // Dynamic background
    color: state.data.value === 'KONG' ? '#f0b90b' : 'var(--sw-primary-text)', // Keep KONG yellow, use theme for others
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'var(--sw-primary-text)', // Use theme variable
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '12px',
    backgroundColor: 'var(--sw-card-bg)', // Use theme variable
    boxShadow: 'var(--sw-shadow)', // Use theme shadow
    marginTop: '4px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--sw-secondary-text)', // Use theme variable
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--sw-primary-text)', // Use theme variable
  }),
};

const Swap = () => {
  const navigate = useNavigate();

  const handleChange = (option) => navigate(`/Swap${option.value}KLV`);
  const handleKakaFlushClick = () => navigate('/SwapKAKAFLUSH');

  const formatOptionLabel = ({ label, logo }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <img src={logo} alt={label} style={{ width: '24px', height: '24px', borderRadius: '50%' }} />
      {label}
    </div>
  );

  return (
    <div className="token-swap-container">
      <div className="token-selector">
        <img src={klvLogo} alt="KLV Token" className="choose-token-logo" />
        <Select
          defaultValue={tokenData[0]}
          options={tokenData}
          styles={customStyles}
          onChange={handleChange}
          formatOptionLabel={formatOptionLabel}
          isSearchable={false}
          placeholder="Select a token to swap with KLV"
        />
      </div>
      <p className="disclaimer-swap">All swap pairs have a fixed 20 KLV fee and 1% swap fee. Fees are deposited to the FPR pool for $KONG stakers.</p>

      <div className="kaka-flush-selector clickable" onClick={handleKakaFlushClick}>
        <img src={kakaLogo} alt="KAKA Token" className="choose-token-logo" />
        <span className="token-label">KAKA</span>
        <span className="arrow">→</span>
        <img src={flushLogo} alt="FLUSH Token" className="choose-token-logo" />
        <span className="token-label">FLUSH</span>
      </div>
      <p className="flush-your-kaka">FLUSH your KAKA tokens!</p>
    </div>
  );
};

export default Swap;