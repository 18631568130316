import React, { useEffect, useState, useContext } from 'react';
import '../styles/WalletModal.css';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../components/ThemeContext'; // Ensure ThemeContext is imported
import kleverImage from '../assets/klever.png';

const WalletModal = ({ isOpen, onClose, walletAddress }) => {
  const [balance, setBalance] = useState('...');
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const { isDarkMode } = useContext(ThemeContext); // Access theme context

  useEffect(() => {
    if (isOpen && walletAddress) {
      const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const fetchedBalance = data.data.account.balance / 1000000;
          setBalance(fetchedBalance.toFixed(2));
        })
        .catch((error) => {
          console.error('Error fetching balance:', error);
          setBalance('Error');
        });
    }
  }, [isOpen, walletAddress]);

  const copyAddress = () => {
    navigator.clipboard.writeText(walletAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleAccountClick = () => {
    navigate('/wallet');
    onClose();
  };

  const handleTransactionHistoryClick = () => {
    navigate('/wallet/history/listings');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="wallet-modal-overlay">
      <div className="wallet-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="wallet-modal-header">
          <h2>My Wallet</h2>
          <button className="wallet-close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="wallet-balance">
          <div className="balance-header">
            <img src={kleverImage} alt="Klever" className="klever-logo" />
            <span className="balance-amount">{balance} KLV</span>
          </div>
          <div className="wallet-address" onClick={copyAddress}>
            <span>{`${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`}</span>
            <span className="copy-icon">{copied ? '✓' : '📋'}</span>
          </div>
        </div>

        <div className="wallet-modal-menu">
          <div className="menu-item" onClick={handleAccountClick}>
            <span>Account</span>
            <span className="arrow">➜</span>
          </div>
          <div className="menu-item" onClick={handleTransactionHistoryClick}>
            <span>Transaction History</span>
            <span className="arrow">➜</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletModal;