import React, { useState, useEffect } from 'react';
import { useWallet } from '../components/WalletContext';
import { web, TransactionType } from '@klever/sdk-web';
import Spinner from '../components/Spinner';
import '../styles/Voting.css';

const proposalWalletAddress = 'klv1d46pxwsrnjhges63wjy2t632d3qw80845ggf73th277cwewmnj6sdyng7u';

const VotingPage = () => {
  const { walletAddress } = useWallet();
  const [proposalTitle, setProposalTitle] = useState('');
  const [proposalDescription, setProposalDescription] = useState('');
  const [proposalEndDate, setProposalEndDate] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('');
  const [option1, setOption1] = useState('Yes');
  const [option2, setOption2] = useState('No');
  const [ownedAssets, setOwnedAssets] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [notification, setNotification] = useState('');
  const [votingWallet, setVotingWallet] = useState('');
  const [votingWalletError, setVotingWalletError] = useState('');
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (votingWallet && !votingWallet.startsWith('klv1')) setVotingWalletError("Voting wallet must start with 'klv1'.");
    else setVotingWalletError('');
  }, [votingWallet]);

  useEffect(() => {
    if (!walletAddress) return;
    const fetchAssets = async () => {
      try {
        const res = await fetch(`https://api.mainnet.klever.finance/v1.0/assets/list?owner=${walletAddress}`);
        const data = await res.json();
        const fungibleAssets = (data.data?.assets || []).filter(a => a.assetType?.toLowerCase() === 'fungible');
        setOwnedAssets(fungibleAssets);
        if (fungibleAssets.length) setSelectedAsset(fungibleAssets[0].assetId);
      } catch (err) {
        console.error('Error fetching assets:', err);
      }
    };
    fetchAssets();
  }, [walletAddress]);

  const canCreateProposal = ownedAssets.length > 0;

  useEffect(() => {
    if (!proposalEndDate) return;
    const end = Date.parse(proposalEndDate);
    const now = Date.now();
    const max = 14 * 24 * 60 * 60 * 1000;
    if (isNaN(end)) setEndDateError('Invalid date.');
    else if (end < now) setEndDateError('Date cannot be in the past.');
    else if (end > now + max) setEndDateError('Max 14 days from now.');
    else setEndDateError('');
  }, [proposalEndDate]);

  const fetchProposals = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=100&page=1&type=0&status=success&asset=KLV&toAddress=${proposalWalletAddress}`);
      const data = await res.json();
      if (!data.data?.transactions) throw new Error('No transactions');
      const txs = data.data.transactions;
      const fetched = txs.map(tx => {
        if (!tx.data?.[0]) return null;
        const str = Buffer.from(tx.data[0], 'hex').toString();
        return JSON.parse(str);
      }).filter(Boolean).map(proposal => ({
        ...proposal,
        options: proposal.options || { option1: 'Yes', option2: 'No' },
        votes: proposal.votes || { option1: 0, option2: 0 },
      }));

      setProposals(prev => {
        const merged = [...fetched];
        prev.forEach(localProposal => {
          if (!merged.some(p => p.title === localProposal.title && p.createdAt === localProposal.createdAt)) merged.unshift(localProposal);
        });
        return merged;
      });

      await Promise.all(fetched.map(proposal => proposal.votingWallet ? fetchVotesForProposal(proposal) : Promise.resolve()));
      setIsDataFetched(true);
    } catch (err) {
      console.error('Error fetching proposals:', err);
      setProposals(prev => prev);
      setIsDataFetched(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAddress) {
      setIsDataFetched(false);
      fetchProposals();
    }
  }, [walletAddress]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
      if (isDataFetched) proposals.forEach(proposal => {
        if (proposal.endDate && proposal.endDate < Date.now() && !proposal.finalized) finalizeVotes(proposal);
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [proposals, isDataFetched]);

  const fetchVotesForProposal = async (proposal) => {
    let allVotes = [];
    let page = 1;
    let totalPages = 1;

    try {
      while (page <= totalPages) {
        const res = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=100&page=${page}&type=0&status=success&asset=KLV&toAddress=${proposal.votingWallet}`);
        const data = await res.json();
        if (data.error || !data.data?.transactions) break;

        const txs = data.data.transactions;
        allVotes = allVotes.concat(txs.map(tx => {
          if (!tx.data?.[0]) return null;
          const str = Buffer.from(tx.data[0], 'hex').toString();
          try {
            const parsed = JSON.parse(str);
            if (parsed.proposalTitle === proposal.title) return {
              sender: tx.sender,
              vote: parsed.vote,
              voteAmount: parsed.voteAmount || 0,
            };
            return null;
          } catch (err) {
            console.error('Error parsing vote metadata:', err);
            return null;
          }
        }).filter(Boolean));

        totalPages = data.pagination.totalPages || 1;
        page++;
      }

      const option1Votes = allVotes.reduce((sum, vote) => (vote.vote === proposal.options.option1 ? sum + vote.voteAmount : sum), 0);
      const option2Votes = allVotes.reduce((sum, vote) => (vote.vote === proposal.options.option2 ? sum + vote.voteAmount : sum), 0);
      const hasVoted = walletAddress ? allVotes.some(vote => vote.sender === walletAddress) : false;
      const userVote = walletAddress ? allVotes.find(vote => vote.sender === walletAddress) : null;

      setProposals(prev => prev.map(p => p.title === proposal.title && p.createdAt === proposal.createdAt
        ? { ...p, votes: { option1: option1Votes, option2: option2Votes }, hasVoted, voters: allVotes, userVote }
        : p));
    } catch (err) {
      console.error(`Failed to fetch votes for ${proposal.title}:`, err);
    }
  };

  const finalizeVotes = async (proposal) => {
    if (proposal.finalized) return;

    setIsLoading(true);
    try {
      const validVotes = [];
      for (const vote of proposal.voters || []) {
        const res = await fetch(`https://api.mainnet.klever.finance/v1.0/address/${vote.sender}`);
        const data = await res.json();
        if (data.error || !data.data?.account?.assets) continue;

        const assets = data.data.account.assets;
        const assetData = assets[proposal.assetId];
        if (assetData && assetData.frozenBalance >= vote.voteAmount) validVotes.push(vote);
      }

      const option1Votes = validVotes.reduce((sum, vote) => (vote.vote === proposal.options.option1 ? sum + vote.voteAmount : sum), 0);
      const option2Votes = validVotes.reduce((sum, vote) => (vote.vote === proposal.options.option2 ? sum + vote.voteAmount : sum), 0);

      setProposals(prev => prev.map(p => p.title === proposal.title && p.createdAt === proposal.createdAt
        ? { ...p, votes: { option1: option1Votes, option2: option2Votes }, finalized: true }
        : p));
    } catch (err) {
      console.error(`Error finalizing votes for ${proposal.title}:`, err);
    } finally {
      setIsLoading(false);
    }
  };

  const createProposal = async () => {
    if (!proposalTitle || !proposalDescription || !proposalEndDate || !selectedAsset || !votingWallet || !option1 || !option2 || endDateError || votingWalletError) {
      setNotification("Please fill all fields correctly.");
      return;
    }
    if (option1 === option2) {
      setNotification("Voting options must be different.");
      return;
    }

    const endTimestamp = Date.parse(proposalEndDate);
    const metadata = { title: proposalTitle, description: proposalDescription, assetId: selectedAsset, votingWallet, createdAt: Date.now(), endDate: endTimestamp, options: { option1, option2 }, votes: { option1: 0, option2: 0 } };
    const metadataString = JSON.stringify(metadata);
    const metadataBase64 = btoa(metadataString);
    const payload = { amount: 250 * 10 ** 6, receiver: proposalWalletAddress, kda: 'KLV' };

    try {
      setIsLoading(true);
      setNotification("Building proposal transaction...");
      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }], [metadataBase64]);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);
      setNotification("Proposal created successfully!");
      setProposals([metadata, ...proposals]);
      setProposalTitle(''); setProposalDescription(''); setProposalEndDate(''); setVotingWallet(''); setOption1('Yes'); setOption2('No');
      await fetchProposals();
    } catch (error) {
      console.error('Proposal Transaction error:', error);
      setNotification(error.message?.includes('cancel') ? "Transaction cancelled." : "Proposal creation failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const castVote = async (proposal, voteType) => {
    if (!walletAddress) {
      setNotification("Please connect your wallet to vote.");
      return;
    }
    if (!isDataFetched) {
      setNotification("Please wait until data is loaded.");
      return;
    }
    if (proposal.hasVoted) {
      setNotification(`You already voted with ${proposal.userVote.voteAmount} votes for ${proposal.userVote.vote}.`);
      return;
    }
    if (proposal.endDate < Date.now()) {
      setNotification("Voting is closed for this proposal.");
      return;
    }

    setIsLoading(true);
    setNotification(`Checking eligibility to vote ${voteType}...`);

    try {
      const res = await fetch(`https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`);
      if (!res.ok) throw new Error(`API request failed with status ${res.status}`);
      const data = await res.json();
      if (data.error || !data.data?.account?.assets) throw new Error("Failed to fetch account data.");

      const assets = data.data.account.assets;
      const assetData = assets[proposal.assetId];
      if (!assetData || !assetData.frozenBalance || assetData.frozenBalance <= 0) {
        setNotification(`You cannot vote: No staked ${proposal.assetId} found.`);
        setIsLoading(false);
        return;
      }

      const voteAmount = assetData.frozenBalance;
      setNotification(`Eligible to cast ${voteAmount} votes with ${proposal.assetId}. Building vote transaction...`);

      const voteMetadata = { proposalTitle: proposal.title, assetId: proposal.assetId, voteAmount, vote: voteType };
      const metadataString = JSON.stringify(voteMetadata);
      const metadataBase64 = btoa(metadataString);
      const payload = { amount: 1 * 10 ** 6, receiver: proposal.votingWallet, kda: 'KLV' };

      const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }], [metadataBase64]);
      const signedTx = await web.signTransaction(unsignedTx);
      await web.broadcastTransactions([signedTx]);

      setProposals(prev => prev.map(p => p.title === proposal.title && p.createdAt === proposal.createdAt
        ? { ...p, votes: { ...p.votes, [voteType === proposal.options.option1 ? 'option1' : 'option2']: (p.votes[voteType === proposal.options.option1 ? 'option1' : 'option2'] || 0) + voteAmount }, hasVoted: true, userVote: { vote: voteType, voteAmount }, voters: [...(p.voters || []), { sender: walletAddress, vote: voteType, voteAmount }] }
        : p));

      setNotification(`Successfully cast ${voteAmount} votes for "${voteType}" on "${proposal.title}"!`);
      await fetchVotesForProposal(proposal);
    } catch (error) {
      console.error('Voting error:', error);
      setNotification(error.message?.includes('cancel') ? "Vote cancelled." : `Voting failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getVotePercentages = (proposal) => {
    const option1Votes = proposal.votes?.option1 || 0;
    const option2Votes = proposal.votes?.option2 || 0;
    const total = option1Votes + option2Votes || 1;
    return { option1Percent: Math.round((option1Votes / total) * 100), option2Percent: Math.round((option2Votes / total) * 100) };
  };

  const formatTimeLeft = (endDate) => {
    const timeLeft = endDate - currentTime;
    if (timeLeft <= 0) return "Expired";
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="voting-container">
      {notification && <div className="notification">{notification}</div>}
      <div className="voting-header">
        <h1 className="title">Voting</h1>
        <p className="subtitle">Shape our future—cast your vote!</p>
      </div>

      <div className={`proposal-create ${!canCreateProposal ? 'disabled' : ''}`}>
        <h2>Create a Proposal</h2>
        <p className="rules">Asset owners only. Use a klv1 voting wallet.</p>
        {!canCreateProposal && <p className="warning">No fungible assets owned—you cannot create proposals.</p>}
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="input"
            placeholder="Enter proposal title"
            value={proposalTitle}
            onChange={e => setProposalTitle(e.target.value)}
            disabled={!canCreateProposal}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="textarea"
            placeholder="Enter proposal description"
            value={proposalDescription}
            onChange={e => setProposalDescription(e.target.value)}
            disabled={!canCreateProposal}
          />
        </div>
        <div className="form-group">
          <label>End Date (max 14 days)</label>
          <input
            type="datetime-local"
            className="input"
            value={proposalEndDate}
            onChange={e => setProposalEndDate(e.target.value)}
            disabled={!canCreateProposal}
          />
          {endDateError && <p className="error">{endDateError}</p>}
        </div>
        <div className="form-group">
          <label>Voting Wallet (klv1...)</label>
          <input
            type="text"
            className="input"
            placeholder="Enter voting wallet"
            value={votingWallet}
            onChange={e => setVotingWallet(e.target.value)}
            disabled={!canCreateProposal}
          />
          {votingWalletError && <p className="error">{votingWalletError}</p>}
        </div>
        <div className="form-group">
          <label>Option 1 (default: Yes)</label>
          <input
            type="text"
            className="input"
            placeholder="Enter first voting option"
            value={option1}
            onChange={e => setOption1(e.target.value)}
            disabled={!canCreateProposal}
          />
        </div>
        <div className="form-group">
          <label>Option 2 (default: No)</label>
          <input
            type="text"
            className="input"
            placeholder="Enter second voting option"
            value={option2}
            onChange={e => setOption2(e.target.value)}
            disabled={!canCreateProposal}
          />
        </div>
        <div className="form-group">
          <label>Asset</label>
          <select
            className="select"
            value={selectedAsset}
            onChange={e => setSelectedAsset(e.target.value)}
            disabled={!canCreateProposal}
          >
            {ownedAssets.map(a => <option key={a.assetId} value={a.assetId}>{a.assetId}</option>)}
          </select>
        </div>
        <button
          className="button primary"
          onClick={createProposal}
          disabled={!canCreateProposal || isLoading || endDateError || votingWalletError || !votingWallet}
        >
          {isLoading ? 'Creating...' : 'Create Proposal'}
        </button>
      </div>

      <div className="active-proposals">
        <h2>Active Proposals</h2>
        {isLoading ? (
          <p className="text-center">Loading...</p>
        ) : proposals.length === 0 ? (
          <p className="text-center">No active proposals.</p>
        ) : (
          proposals.map((proposal, index) => {
            const { option1Percent, option2Percent } = getVotePercentages(proposal);
            const isExpired = proposal.endDate < currentTime;
            const userVoteMessage = proposal.hasVoted && proposal.userVote
              ? `Voted with ${proposal.userVote.voteAmount} votes for ${proposal.userVote.vote}. You cannot vote anymore.`
              : null;

            return (
              <div key={`${proposal.title}-${proposal.createdAt}`} className="proposal-item">
                <h3>{proposal.title}</h3>
                <p>{proposal.description}</p>
                <p className="asset-info">{proposal.assetId}</p>
                <p className={`end-date ${isExpired ? 'expired' : ''}`}>
                  {isExpired ? "Voting Closed" : `Time Left: ${formatTimeLeft(proposal.endDate)}`}
                </p>
                <div className="chart-container">
                  <div className="chart-bar">
                    <div className="chart-yes" style={{ width: `${option1Percent}%` }}>
                      {option1Percent > 20 && `${option1Percent}%`}
                    </div>
                    <div className="chart-no" style={{ width: `${option2Percent}%` }}>
                      {option2Percent > 20 && `${option2Percent}%`}
                    </div>
                  </div>
                </div>
                <div className="vote-counts">
                  <span>{proposal.options.option1}: {proposal.votes?.option1 || 0}</span>
                  <span>{proposal.options.option2}: {proposal.votes?.option2 || 0}</span>
                </div>
                {userVoteMessage && (
                  <p className="user-vote-message">{userVoteMessage}</p>
                )}
                <div className="proposal-buttons">
                  <button
                    className="button primary"
                    onClick={() => castVote(proposal, proposal.options.option1)}
                    disabled={!isDataFetched || isLoading || proposal.hasVoted || isExpired}
                  >
                    {proposal.options.option1}
                  </button>
                  <button
                    className="button secondary"
                    onClick={() => castVote(proposal, proposal.options.option2)}
                    disabled={!isDataFetched || isLoading || proposal.hasVoted || isExpired}
                  >
                    {proposal.options.option2}
                  </button>
                </div>
              </div>
            );
          })
        )}
      </div>

      {isLoading && (
        <div className="loading-overlay">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default VotingPage;