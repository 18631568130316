import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import { web, TransactionType } from '@klever/sdk-web';
import axios from 'axios';
import { FaTag, FaShoppingCart, FaExchangeAlt, FaGift } from 'react-icons/fa';
import '../styles/NftDetailsPage.css';

const NftDetailsPage = () => {
  const { isConnected, walletAddress, useKdaFee, selectedKda } = useWallet();
  const navigate = useNavigate();
  const { collectionId, nftId } = useParams();
  const [nft, setNft] = useState(null);
  const [history, setHistory] = useState(null);
  const [nftOwner, setNftOwner] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [listingPrice, setListingPrice] = useState("");
  const [isPriceConfirmed, setIsPriceConfirmed] = useState(false);
  const [latestPrice, setLatestPrice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [endDate, setEndDate] = useState(new Date().toISOString().substring(0, 10));
  const [endTimeUnix, setEndTimeUnix] = useState(null);

  const ExchangeAddress = "klv1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqylllsymmgky";
  const priceInputRef = useRef();

  // Transaction functions
  const listNftForSale = useCallback(async () => {
    const payload = {
      marketType: 0,
      marketplaceId: "417b70c0eb7a33cb",
      assetId: `${collectionId}/${nftId}`,
      currencyId: "KLV",
      price: listingPrice * 10 ** 6,
      endTime: endTimeUnix,
    };

    let txOptions = undefined;
    if (useKdaFee && selectedKda) txOptions = { kdaFee: selectedKda };

    try {
      const unsignedTx = await web.buildTransaction([{ payload, type: 18 }], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Transaction response:', response);
      setTransactionStatus("Transaction completed!");
    } catch (error) {
      console.error('Error sending transaction:', error);
      setTransactionStatus("Transaction failed!");
    }
  }, [collectionId, nftId, listingPrice, endTimeUnix, useKdaFee, selectedKda]);

  const claimNft = useCallback(async () => {
    const payload = { claimType: 2, id: orderId };
    const contract = { type: 9, payload };

    let txOptions = undefined;
    if (useKdaFee && selectedKda) txOptions = { kdaFee: selectedKda };

    try {
      const unsignedTx = await web.buildTransaction([contract], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Claim NFT transaction response:', response);
      setTransactionStatus("Claim transaction completed!");
    } catch (error) {
      console.error('Error in claim NFT transaction:', error);
      setTransactionStatus("Claim transaction failed!");
    }
  }, [orderId, useKdaFee, selectedKda]);

  const buyNft = useCallback(async (orderId, price) => {
    const payload = { buyType: 1, id: orderId, currencyId: "KLV", amount: price * 10 ** 6 };
    const contract = { type: 17, payload };

    let txOptions = undefined;
    if (useKdaFee && selectedKda) txOptions = { kdaFee: selectedKda };

    try {
      const unsignedTx = await web.buildTransaction([contract], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Buy NFT transaction response:', response);
      setTransactionStatus("Buy transaction completed!");
    } catch (error) {
      console.error('Error in buy NFT transaction:', error);
      setTransactionStatus("Buy transaction failed!");
    }
  }, [orderId, useKdaFee, selectedKda]);

  const cancelOrder = useCallback(async () => {
    const payload = { claimType: 2, orderId };
    const contract = { type: 19, payload };

    let txOptions = undefined;
    if (useKdaFee && selectedKda) txOptions = { kdaFee: selectedKda };

    try {
      const unsignedTx = await web.buildTransaction([contract], undefined, txOptions);
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Cancel order transaction response:', response);
      setTransactionStatus("Order cancellation completed!");
    } catch (error) {
      console.error('Error in cancel order transaction:', error);
      setTransactionStatus("Order cancellation failed!");
    }
  }, [orderId, useKdaFee, selectedKda]);

  // Modal component
  const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, children }) => {
    if (!isOpen) return null;
    return (
      <div className="confirmation-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{title}</h2>
            <button className="modal-close" onClick={onClose}>×</button>
          </div>
          <div className="modal-body">
            {children}
            {transactionStatus && <p className="transaction-status">{transactionStatus}</p>}
          </div>
          <div className="modal-actions">
            <button onClick={onConfirm} className="action-button confirm">Confirm</button>
            <button onClick={onClose} className="action-button cancel">Cancel</button>
          </div>
        </div>
      </div>
    );
  };

  // Data fetching functions
  const checkNftOwnership = async () => {
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/assets/nft/holder/${encodeURIComponent(collectionId)}/${encodeURIComponent(nftId)}`);
      setNftOwner(response.data.data.account.address);
    } catch (error) {
      console.error('Error checking NFT ownership:', error);
    }
  };

  const fetchMetadata = async () => {
    try {
      const [page1, page2] = await Promise.all([
        axios.get('https://api.mainnet.klever.finance/v1.0/assets/list?page=1&limit=100'),
        axios.get('https://api.mainnet.klever.finance/v1.0/assets/list?page=2&limit=100')
      ]);
      const assets = [...page1.data.data.assets, ...page2.data.data.assets];
      const matchingAsset = assets.find(asset => asset.assetId === collectionId);
      if (!matchingAsset) return;
      const metadataObject = matchingAsset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
      let metadataUri = metadataObject?.value || null;
      if (metadataUri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") metadataUri = `https://ipfs.io/ipfs/${metadataUri}/${nftId}.json`;
      else if (metadataUri) {
        if (metadataUri.includes("klever-mint.mypinata.cloud")) metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        metadataUri = `${metadataUri}/${nftId}`;
      }
      if (!metadataUri) return;
      try {
        const metadataResponse = await axios.get(metadataUri);
        const metadata = metadataResponse.data;
        if (metadata.image && metadata.image.includes("www.lovemonsternft.com")) {
          const imageName = metadata.image.split('/').pop();
          metadata.image = `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
        } else if (metadata.image && metadata.image.includes("klever-mint.mypinata.cloud")) {
          metadata.image = metadata.image.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        setNft(metadata);
      } catch (error) {
        console.error('Primary fetch failed, trying fallback for', nftId);
        const fallbackUri = `https://${metadataUri}.ipfs.w3s.link/${nftId}`;
        const fallbackResponse = await axios.get(fallbackUri);
        setNft(fallbackResponse.data);
      }
    } catch (error) {
      console.error(`Error fetching metadata for nonce ${nftId}:`, error);
    }
  };

  const fetchNftData = async () => {
    try {
      await fetchMetadata();
      let allHistory = [];
      const totalPages = 3;
      for (let page = 1; page <= totalPages; page++) {
        const url = `https://api.mainnet.klever.finance/marketplaces/nfts/activity?nftId=${collectionId}/${nftId}&sort=desc&limit=10&page=${page}`;
        const response = await axios.get(url);
        allHistory = allHistory.concat(response.data.data.history);
      }
      setHistory(allHistory);
      if (allHistory.length > 0) {
        const latestTransaction = allHistory[0];
        const assetId = `${collectionId}/${nftId}`;
        const sellContract = latestTransaction.contract.find(c => c.typeString === "SellContractType" && c.parameter.assetId === assetId);
        if (sellContract) {
          setEndTime(sellContract.parameter.endTime);
          latestTransaction.receipts.forEach(receipt => {
            if (receipt.assetId === assetId && receipt.typeString === "KAppTransfer") setOrderId(receipt.orderId);
          });
        }
      }
    } catch (error) {
      console.error('Error fetching NFT data:', error);
    }
  };

  useEffect(() => {
    if (history?.length) {
      const { isSale, price } = checkLatestTransaction();
      if (isSale) setLatestPrice(price);
    }
  }, [history]);

  useEffect(() => {
    fetchNftData();
    checkNftOwnership();
  }, [collectionId, nftId]);

  const goBack = () => navigate(-1);

  const checkLatestTransaction = () => {
    const latest = history?.[0];
    if (latest) {
      const isSale = latest.contract.some(c => c.typeString === "SellContractType");
      if (isSale) {
        const sale = latest.contract.find(c => c.typeString === "SellContractType");
        const price = sale.parameter.price / 1000000;
        const sender = latest.sender;
        return { isSale, price, sender };
      }
    }
    return { isSale: false, price: null, sender: null };
  };

  const openModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle("");
    setModalContent(null);
    setTransactionStatus("");
    setIsPriceConfirmed(false);
    setListingPrice("");
  };

  const { isSale, sender } = checkLatestTransaction();

  const handleBuy = () => openModal("Buy NFT", <p>Are you sure you want to buy {collectionId}/{nftId} for {latestPrice} KLV?</p>);
  const handleCancel = () => openModal("Cancel Listing", <p>Are you sure you want to cancel {collectionId}/{nftId} from sale?</p>);
  const handleSell = () => openModal(`Sell ${collectionId}/${nftId}`);
  const handleClaim = () => openModal("Claim NFT", <p>Are you sure you want to claim {collectionId}/{nftId}?</p>);

  const handleTransactionConfirmation = useCallback(async () => {
    setTransactionStatus("Processing transaction...");
    if (modalTitle.includes('Sell')) await listNftForSale();
    else if (modalTitle.includes('Claim')) await claimNft();
    else if (modalTitle.includes('Buy')) await buyNft(orderId, latestPrice);
    else if (modalTitle.includes('Cancel Listing')) await cancelOrder();
    setTimeout(closeModal, 2000);
  }, [modalTitle, listNftForSale, claimNft, buyNft, cancelOrder, orderId, latestPrice]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setEndDate(newDate);
    setEndTimeUnix(Math.floor(new Date(newDate).getTime() / 1000));
  };

  const confirmPrice = () => {
    const newPrice = priceInputRef.current.value;
    if (!isNaN(newPrice) && newPrice.trim() !== '') {
      setListingPrice(newPrice);
      setIsPriceConfirmed(true);
    }
  };

  const isClaimable = () => endTime && Date.now() / 1000 > endTime;

  if (!nft) return <div className="loading">Loading NFT data...</div>;

  return (
    <div className="nft-details-container">
      <button onClick={goBack} className="back-button">← Back</button>

      <div className="nft-overview">
        <div className="nft-image-container">
          <img src={nft.image} alt={`${collectionId} NFT`} className="nft-image" />
        </div>
        <div className="nft-info">
          <h1 className="nft-title">{nft.name || `${collectionId} #${nftId}`}</h1>
          {nft.description && <p className="nft-description">{nft.description}</p>}
          {nft.attributes && (
            <ul className="nft-traits">
              {nft.attributes.map((trait, index) => (
                <li key={index} className="nft-trait">
                  <strong>{trait.trait_type}:</strong> {trait.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="nft-actions">
        {!isClaimable() && latestPrice && <p className="latest-price">{latestPrice.toLocaleString('en-US')} KLV</p>}
        {isConnected ? (
          <>
            {isSale && nftOwner === ExchangeAddress && sender !== walletAddress && !isClaimable() && (
              <button className="action-button buy" onClick={handleBuy}><FaShoppingCart /> Buy</button>
            )}
            {isSale && nftOwner === ExchangeAddress && sender === walletAddress && !isClaimable() && (
              <button className="action-button cancel" onClick={handleCancel}><FaExchangeAlt /> Cancel</button>
            )}
            {!isSale && nftOwner === walletAddress && (
              <button className="action-button sell" onClick={handleSell}><FaTag /> Sell</button>
            )}
            {isSale && nftOwner === ExchangeAddress && sender === walletAddress && isClaimable() && (
              <button className="action-button claim" onClick={handleClaim}><FaGift /> Claim</button>
            )}
            {isClaimable() && nftOwner !== walletAddress && (
              <p className="expired-text">EXPIRED</p>
            )}
          </>
        ) : (
          <p className="connect-wallet">Connect your wallet first!</p>
        )}
      </div>

      {history && (
        <div className="nft-history">
          <h2>Transaction History</h2>
          <div className="history-list">
            {history.map((item, index) => {
              const sellContract = item.contract.find(c => c.typeString === "SellContractType");
              const marketBuyContract = item.contract.find(c => c.typeString === "BuyContractType" && c.parameter.buyType === "MarketBuy");
              const cancelContract = item.contract.find(c => c.typeString === "CancelMarketOrderContractType");
              const itoBuyContract = item.contract.find(c => c.typeString === "BuyContractType" && c.parameter.buyType === "ITOBuy");
              const assetTriggerContract = item.contract.find(c => c.typeString === "AssetTriggerContractType" && c.parameter.triggerType === "Mint");
              const claimContract = item.contract.find(c => c.typeString === "ClaimContractType");
              const transferContract = item.contract.find(c => c.typeString === "TransferContractType");

              // Use timestamp directly as milliseconds
              const formattedDate = new Date(item.timestamp).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' });

              return (
                <div key={index} className="history-item">
                  <p className="history-date">{formattedDate}</p>
                  <div className="history-actions">
                    {sellContract && (
                      <div className="history-action">
                        <FaTag className="history-icon" />
                        <span>{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} listed for {(sellContract.parameter.price / 1000000).toLocaleString('en-US')} KLV</span>
                      </div>
                    )}
                    {marketBuyContract && (
                      <div className="history-action">
                        <FaShoppingCart className="history-icon" />
                        <span>{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} purchased for {(marketBuyContract.parameter.amount / 1000000).toLocaleString('en-US')} KLV</span>
                      </div>
                    )}
                    {cancelContract && (
                      <div className="history-action">
                        <FaExchangeAlt className="history-icon" />
                        <span>{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} unlisted the NFT</span>
                      </div>
                    )}
                    {(itoBuyContract || assetTriggerContract) && (
                      <div className="history-action">
                        <FaGift className="history-icon" />
                        <span>{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} minted</span>
                      </div>
                    )}
                    {claimContract && (
                      <div className="history-action">
                        <FaGift className="history-icon" />
                        <span>{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} claimed the NFT</span>
                      </div>
                    )}
                    {transferContract && (
                      <div className="history-action">
                        <FaExchangeAlt className="history-icon" />
                        <span>{item.sender === walletAddress ? "You" : `klv1...${item.sender.slice(-4)}`} transferred to klv1...{transferContract.parameter.toAddress.slice(-4)}</span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <ConfirmationModal isOpen={isModalOpen} onClose={closeModal} onConfirm={handleTransactionConfirmation} title={modalTitle}>
        {modalTitle.includes('Sell') && (
          <>
            {!isPriceConfirmed ? (
              <>
                <p>Set your selling price:</p>
                <input
                  ref={priceInputRef}
                  type="number"
                  className="price-input"
                  placeholder="Enter Price in KLV"
                  onBlur={confirmPrice}
                  min="0"
                  step="0.01"
                />
              </>
            ) : (
              <p className="confirmed-price">Price: {listingPrice} KLV</p>
            )}
            <p>Set end date for your listing:</p>
            <input
              type="date"
              className="date-input"
              value={endDate}
              onChange={handleDateChange}
              min={new Date().toISOString().substring(0, 10)}
            />
          </>
        )}
        {modalTitle.includes('Claim') && <p>Are you sure you want to claim {collectionId}/{nftId}?</p>}
        {modalTitle.includes('Buy') && <p>Are you sure you want to buy {collectionId}/{nftId} for {latestPrice} KLV?</p>}
        {modalTitle.includes('Cancel Listing') && <p>Are you sure you want to cancel the listing of {collectionId}/{nftId}?</p>}
      </ConfirmationModal>
    </div>
  );
};
export default NftDetailsPage;